import React, { useEffect, useState } from 'react';
import {
    IonContent, IonButton, IonHeader, IonFooter, IonNote, IonToolbar, IonModal, IonItem, IonText, IonLabel, IonInput,
    IonSelect, IonSelectOption, useIonToast, IonCheckbox
} from '@ionic/react';
import { ToastMessage } from '../utils/ToastMessage';

import { Order, TrackingNumber } from '../models/Order';
import { CartItem } from '../models/ShoppingCart';
import { Constants } from '../utils/Constants';
import { OrderData } from '../data/OrderData';

import './OrderDetailsModal.css';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

interface OrderDetailsModalProps {
    isOpen: boolean;
    toggleIsOpen: Function;
    order: Order
}
export const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ order, isOpen, toggleIsOpen }) => {

    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    const [niOpen, setNiOpen] = useState(false);
    const [placedDobaOrder, setPlacedDobaOrder] = useState<boolean>(order.dobaOrderPlacedTime ? true : false);
    const [trackingNumbers, setTrackingNumbers] = useState(order.trackingNumbers);

    useEffect(() => {
        if (!trackingNumbers || trackingNumbers.length === 0) {
            setTrackingNumbers([{ number: '', carrier: '', trackingUrl: ''}]);
        } else {
            console.log(`Got tracking number already: `, trackingNumbers);
        }
        setNiOpen(isOpen);
    }, []);

    const updateOrder = (evt: any) => {
        const validTrackingNumber = new Array<TrackingNumber>();
        trackingNumbers.forEach(trackin => {
            if (trackin.number && trackin.carrier) {
                if (!trackin.trackingUrl) {
                    // @ts-ignore
                    trackin.trackingUrl = Constants.SHIPPING_CARRIERS[trackin.carrier].TRACKING_URL?.replace('TRACKING_PLACEHOLDER', trackin.number);
                }
                validTrackingNumber.push(trackin);
            }
        });
        order.trackingNumbers = validTrackingNumber;
        OrderData.updateOrder(order.orderNumber, { dobaOrderPlacedTime: order.dobaOrderPlacedTime, trackingNumbers: JSON.stringify(order.trackingNumbers)})
            .then(resp => {
                if (resp) {
                    setNiOpen(false);
                } else {
                    // TODO display the error message
                    EventData.logEvent(`Failed to save the order.`, EventType.ERROR, 'OrderDetailsModal', 1);
                }
            })
    }

    const cancelClicked = () => {
        setNiOpen(false);
        setTimeout(() => {
            toggleIsOpen();
        }, 1000);
    }

    const dobaOrderPlaceChanged = (evt: any) => {
        if (evt.target.checked === true) {
            order.dobaOrderPlacedTime = new Date().getTime();
        } else {
            order.dobaOrderPlacedTime = 0;
        }
    }

    const addTrackingNumber = (evt: any) => {
        const index = evt.target.id;
        if (trackingNumbers[index].carrier && trackingNumbers[index].number) {
            // @ts-ignore
            trackingNumbers[index].trackingUrl = Constants.SHIPPING_CARRIERS[trackingNumbers[index].carrier].TRACKING_URL?.replace('TRACKING_PLACEHOLDER', trackingNumbers[index].number);
            trackingNumbers.push({ number: '', carrier: '', trackingUrl: '' });
            setTrackingNumbers([...trackingNumbers]);
            evt.target.style.display = 'none';
        } else {
            toastMessage.showErrorMessage('Both tracking number and carriers are required.');
        }
    }

    const removeTrackingNumber = (evt: any) => {
        const index = evt.target.id;
        setTrackingNumbers([...trackingNumbers.slice(0, index).concat(trackingNumbers.slice(index + 1))])
    }

    const trackingNumberChanged = (evt: any) => {
        const trackingNumber = evt.target.value;
        const index = evt.target.id;
        if (trackingNumber) {
            trackingNumbers[index].number = trackingNumber;
        }
    }

    const carrierChanged = (evt: any) => {
        const carrier = evt.target.value;
        const index = evt.target.id;
        if (carrier) {
            trackingNumbers[index].carrier = carrier;
        }
    }

    return (
        <IonModal isOpen={niOpen} onWillDismiss={cancelClicked}>
            <IonContent className='ion-padding'>
                <IonHeader>
                    <IonToolbar className='ion-text-center'>
                        <h4>Order details</h4>
                    </IonToolbar>
                </IonHeader>
                <div className='ion-justify-content-center ion-text-start'>
                    <div className='order-details-div'>
                        <b>Order number:</b> {order.orderNumber}
                    </div>
                    <div className='order-details-div'>
                        <IonText><b>Shop name:</b> {order.shopName}</IonText>
                    </div>
                    <div className='order-details-div'>
                        <IonText><b>Order total:</b> ${order.orderTotal}</IonText>
                    </div>
                    <div className='order-details-div'>
                        <>
                            <IonText><b>Items:</b></IonText>
                            {order.items.map((item: CartItem, idx: number) => {
                                return (
                                    <div className='order-details-div' key={idx}>
                                        <a href={'/product/' + item.product.itemNumber + '/' + item.product.spuNumber} target='_blank'>{item.itemNumber}</a>
                                        | {item.quantity} | ${item.sellwichPrice} | ${item.salePrice}
                                    </div>
                                )
                            })}
                        </>
                    </div>
                    <div className='order-details-div'>
                        <IonText><strong>Address:</strong></IonText>
                    </div>
                    <div className='order-details-div'>

                        <span>{order.shippingAddress.fullName}</span>
                        <br></br>
                        <span>{order.shippingAddress.address1}</span>
                        <br></br>
                        { order.shippingAddress.address2 &&
                            <>
                            <span>{order.shippingAddress.address2}</span>
                            <br></br>
                            </>
                        }
                        <span>{order.shippingAddress.city}</span>
                        <br></br>
                        <span>{order.shippingAddress.state}, {order.shippingAddress.zipCode}</span>
                        <br></br><br></br>
                    </div>
                    <div className='order-details-div'>
                        <IonText>Email: {order.shippingAddress.email}</IonText>
                    </div>
                    <div className='order-details-div'>
                        <IonText>Phone:{order.shippingAddress.phone}</IonText>
                    </div>
                    <div className='order-details-div'>
                        <IonLabel><b>Placed doba order:</b> </IonLabel>
                        <IonCheckbox onIonChange={dobaOrderPlaceChanged}></IonCheckbox>
                    </div>
                    <div className='order-details-div'>
                        <IonLabel><b>Tracking numbers:</b></IonLabel>
                        { trackingNumbers &&
                            trackingNumbers.map((trak, idx) => {
                                return (
                                    <IonItem key={idx}>
                                        <IonButton slot='start' id={idx.toString()} onClick={addTrackingNumber}>Add</IonButton>
                                        <IonButton slot='start' id={idx.toString()} onClick={removeTrackingNumber}>Remove</IonButton>
                                        <IonInput slot='start' id={idx.toString()} onIonChange={trackingNumberChanged}  type='text' value={trak.number} placeholder='Enter a tracking number'></IonInput>
                                        <IonSelect slot='end' id={idx.toString()} onIonChange={carrierChanged} value={trak.carrier} placeholder='Carrier'>
                                            <IonSelectOption value='USPS'>USPS</IonSelectOption>
                                            <IonSelectOption value='FEDEX'>FedEx</IonSelectOption>
                                            <IonSelectOption value='UPS'>UPS</IonSelectOption>
                                            <IonSelectOption value='DHL'>DHL</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                )
                            })
                        }
                    </div>
                    <IonNote id='shopErrorMessage' className='error-message'>Select a shop to add the product to</IonNote>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar className='ion-padding' id='model-footer-toolbar'>
                    <IonButton fill='outline' color='medium' onClick={cancelClicked}>Cancel</IonButton>
                    <IonButton onClick={updateOrder} slot='end' fill='solid' color='primary'>Save</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    );
};
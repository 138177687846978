import { EventData } from "../data/EventData";
import { UserData } from "../data/UserData";
import { EventType } from "../models/Event";
import { LocalStorageData } from './../data/LocalStorageData';

export const Facebook = {

    InitFacebook: () => {

        return new Promise(resolve => {

            // @ts-ignore
            window.fbAsyncInit = function () {
                // @ts-ignore
                window.FB.init({
                    appId: process.env.REACT_APP_FB_APP_ID,
                    cookie: true,
                    xfbml: true,
                    version: process.env.REACT_APP_FB_VERSION
                });

                Facebook.checkLoginStatus(resolve);
            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                // @ts-ignore
                js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0&appId=494721359483584&autoLogAppEvents=1";
                fjs.parentNode?.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        })
    },

    checkLoginStatus: async (resolve: any = null, cb=null) => {
        // @ts-ignore
        window.FB.getLoginStatus((response: any) => {
            //console.log('Login status is: ', response);
            const userId = response.authResponse?.userID;
            const accessToken = response.authResponse?.accessToken;
            const expiresIn = response.authResponse?.expiresIn;
            const expiryTime = response.authResponse?.data_access_expiration_time;
            //console.log(`AccessToken: ${accessToken}, userId: ${userId}, expiresIn: ${expiresIn}`);
            if (response.status === 'connected') {
                //console.log('The user is connected..');
                // @ts-ignore
                window.FB.api('/me?fields=email,first_name,last_name,middle_name,picture', (resp: any) => {
                    //console.log(`Got the user object: `, resp);
                    const email = resp.email;
                    const firstname = resp.first_name;
                    const lastname = resp.last_name;
                    const middlename = resp.middle_name;
                    const picture = resp.picture;
                    UserData.updateOrCreateUser(userId, accessToken, expiresIn, expiryTime, email, firstname, lastname, middlename, picture)
                        .then(() => {
                            LocalStorageData.setLoggedInUserId(userId)
                                .then(() => {
                                    if (cb) {
                                        // @ts-ignore
                                        cb();
                                    } else if (resolve && typeof resolve === 'function') {
                                        resolve(true);
                                    }
                                });
                        }).catch(err => {
                            // TODO display login error
                            EventData.logEvent(`Error logging in. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'FacebookUtil', 1);
                            return null;
                        })
                })
            } else {
                // @ts-ignore
                window.FB.AppEvents.logPageView();
                if (resolve && typeof resolve === 'function') {

                    return resolve(false);
                } else {
                    //console.log('Resolve is not a function: ', resolve);
                    return null;
                }
            }
        });
    },

    launchFBE: async () => {
        // @ts-ignore
        window.FB.login(function (response: any) {
            if (response.authResponse) {
                // returns a User Access Token with scopes requested
                const accessToken = response.authResponse.accessToken;
                const message = {
                    'success': true,
                    'access_token': accessToken,
                };
                // store access token for later
            } else {
                EventData.logEvent(`User cancelled login or did not fully authorize.`, EventType.ERROR, 'FacebookUtil', 1);
            }
        }, {
            //display: 'dialog',
            redirect_uri: "https://localhost:3000/testshop",
            response_type: 'token',
            scope: 'catalog_management,manage_business_extension',
            // refer to the extras object table for details
            extras: {
                "setup": {
                    "external_business_id": "sellwich-123",
                    "timezone": "America/Los_Angeles",
                    "currency": "USD",
                    "business_vertical": "ECOMMERCE",
                    "channel": "COMMERCE_OFFSITE",
                    "domain": "https://dev.sellwich.com"
                },
                "business_config": {
                    "business": {
                        "name": "Sellwich User Shop"
                    },
                    "catalog_feed_scheduled": {
                        "enabled": true,
                        "feed_url": "https://localhost:3000/feed/testshop.csv"
                    }
                },
                "repeat": false
            }
        });
    },

    getCatalogProductFeed: () => {

        // Get product feeds
        // @ts-ignore
        /*window.FB.api("/518580000227242/product_feeds", "POST",
            function (response: any) {

                console.log('Got repose from catalog call: ', response);
                if (response && !response.error) {
                    // handle the result
                }
            }
        );*/

        // Find authorized pages and commerce accounts
        // @ts-ignore
        /*window.FB.api("/me/accounts?fields=access_token,name,id,tasks",
            function (response: any) {
                console.log('Got response from authorized pages: ', response);
                if (response && !response.error) {
                    // handle the result
                }
            }
        );*/

        const accessToken = "EAAHB8krlEsABAMZCNA8wgYqW7KqlnFJQRaxsXQqRScHZAMZCo5Acn0tc1EDm0ACjZA1Hh3px4c6jsL6OHgqQzQIPFmhALQcmOepXrH0ZCErRWB5om1DooHaU8XQ9ZBEvIwMNf7WKpv7PsrYlssjT3GDXESspIt9MB5Ei4Bv5swekMQLHK45cJTQUx7M2NONUYmCZAjZAjpuc9gZDZD";
        // get catalogs et al. for a given `108464072115380` page.
        // @ts-ignore
        window.FB.api(
            `/108464072115380/?fields=business,commerce_merchant_settings{id,display_name,product_catalogs}&access_token=${accessToken}`,
            function (response: any) {
                console.log('Got response from pages info: ', response);
                if (response && !response.error) {
                    // handle the result
                }
            }
        );
    },

    isLoggedIn: async () => {
        return LocalStorageData.getLoggedInUserId()
                    .then(userId  => {
                        return userId;
                    }).catch((err: any) => {
                        EventData.logEvent(`Error checking login. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'FacebookUtil', 1);
                        return false;
                    })
    },

    logout: async () => {
        return LocalStorageData.removeUserId()
            .then(resp => {
                //console.log('UserId removed/loggedOut.');
                // TODO call FB api to logout the user in case they are still logged in facebook
                return true;
            }).catch((err: any) => {
                EventData.logEvent(`Error logging out. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'FacebookUtil', 1);
                return false;
            })
    }
}
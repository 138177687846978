/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSellwichInventoryCategory = /* GraphQL */ `
  mutation CreateSellwichInventoryCategory(
    $input: CreateSellwichInventoryCategoryInput!
  ) {
    createSellwichInventoryCategory(input: $input) {
      name
      parentCategory
      displayName
      subCategories
      updatedAt
    }
  }
`;
export const updateSellwichInventoryCategory = /* GraphQL */ `
  mutation UpdateSellwichInventoryCategory(
    $input: UpdateSellwichInventoryCategoryInput!
  ) {
    updateSellwichInventoryCategory(input: $input) {
      name
      parentCategory
      displayName
      subCategories
      updatedAt
    }
  }
`;
export const deleteSellwichInventoryCategory = /* GraphQL */ `
  mutation DeleteSellwichInventoryCategory(
    $input: DeleteSellwichInventoryCategoryInput!
  ) {
    deleteSellwichInventoryCategory(input: $input) {
      name
      parentCategory
      displayName
      subCategories
      updatedAt
    }
  }
`;
export const createSellwichInventoryProduct = /* GraphQL */ `
  mutation CreateSellwichInventoryProduct(
    $input: CreateSellwichInventoryProductInput!
  ) {
    createSellwichInventoryProduct(input: $input) {
      itemNumber
      category
      parentCategory
      skuCode
      spuNumber
      isDiscontinued
      updatedAt
      url
      categoryString
      topCategory
      productName
      supplier
      brand
      variationTheme1
      variationValue1
      variationTheme2
      variationValue2
      msrp
      map
      dropshippingPrice
      inventoryQuantity
      inventoryLocation
      shippingMethod
      shipTo
      shipNotTo
      shippingEstimateCost
      pickupPriceWithPrepaidShippingLabel
      promotionFlag
      salePrice
      salePriceForPickup
      promotionStartDatePST
      promotionEndDatePST
      productVideo
      additionalResources
      productImages
      description
      descriptionHTML
      upc
      asin
      shippingLimitations
      prohibitedMarketplace
      processingBusinessDays
      returnAndRefundPolicy
      packagingSizeLength
      packagingSizeWidth
      packagingSizeHeight
      packagingSizeUnit
      packagingWeight
      packagingWeightUnit
      productSizeLength
      productSizeWidth
      productSizeHeight
      productSizeUnit
      productWeight
      productWeightUnit
      parameters
    }
  }
`;
export const updateSellwichInventoryProduct = /* GraphQL */ `
  mutation UpdateSellwichInventoryProduct(
    $input: UpdateSellwichInventoryProductInput!
  ) {
    updateSellwichInventoryProduct(input: $input) {
      itemNumber
      category
      parentCategory
      skuCode
      spuNumber
      isDiscontinued
      updatedAt
      url
      categoryString
      topCategory
      productName
      supplier
      brand
      variationTheme1
      variationValue1
      variationTheme2
      variationValue2
      msrp
      map
      dropshippingPrice
      inventoryQuantity
      inventoryLocation
      shippingMethod
      shipTo
      shipNotTo
      shippingEstimateCost
      pickupPriceWithPrepaidShippingLabel
      promotionFlag
      salePrice
      salePriceForPickup
      promotionStartDatePST
      promotionEndDatePST
      productVideo
      additionalResources
      productImages
      description
      descriptionHTML
      upc
      asin
      shippingLimitations
      prohibitedMarketplace
      processingBusinessDays
      returnAndRefundPolicy
      packagingSizeLength
      packagingSizeWidth
      packagingSizeHeight
      packagingSizeUnit
      packagingWeight
      packagingWeightUnit
      productSizeLength
      productSizeWidth
      productSizeHeight
      productSizeUnit
      productWeight
      productWeightUnit
      parameters
    }
  }
`;
export const deleteSellwichInventoryProduct = /* GraphQL */ `
  mutation DeleteSellwichInventoryProduct(
    $input: DeleteSellwichInventoryProductInput!
  ) {
    deleteSellwichInventoryProduct(input: $input) {
      itemNumber
      category
      parentCategory
      skuCode
      spuNumber
      isDiscontinued
      updatedAt
      url
      categoryString
      topCategory
      productName
      supplier
      brand
      variationTheme1
      variationValue1
      variationTheme2
      variationValue2
      msrp
      map
      dropshippingPrice
      inventoryQuantity
      inventoryLocation
      shippingMethod
      shipTo
      shipNotTo
      shippingEstimateCost
      pickupPriceWithPrepaidShippingLabel
      promotionFlag
      salePrice
      salePriceForPickup
      promotionStartDatePST
      promotionEndDatePST
      productVideo
      additionalResources
      productImages
      description
      descriptionHTML
      upc
      asin
      shippingLimitations
      prohibitedMarketplace
      processingBusinessDays
      returnAndRefundPolicy
      packagingSizeLength
      packagingSizeWidth
      packagingSizeHeight
      packagingSizeUnit
      packagingWeight
      packagingWeightUnit
      productSizeLength
      productSizeWidth
      productSizeHeight
      productSizeUnit
      productWeight
      productWeightUnit
      parameters
    }
  }
`;
export const createSellwichShop = /* GraphQL */ `
  mutation CreateSellwichShop($input: CreateSellwichShopInput!) {
    createSellwichShop(input: $input) {
      name
      userId
      timestamp
      displayName
      domainName
      subDomainName
      templateName
      templateParameters
      status
    }
  }
`;
export const updateSellwichShop = /* GraphQL */ `
  mutation UpdateSellwichShop($input: UpdateSellwichShopInput!) {
    updateSellwichShop(input: $input) {
      name
      userId
      timestamp
      displayName
      domainName
      subDomainName
      templateName
      templateParameters
      status
    }
  }
`;
export const deleteSellwichShop = /* GraphQL */ `
  mutation DeleteSellwichShop($input: DeleteSellwichShopInput!) {
    deleteSellwichShop(input: $input) {
      name
      userId
      timestamp
      displayName
      domainName
      subDomainName
      templateName
      templateParameters
      status
    }
  }
`;
export const createSellwichShopProduct = /* GraphQL */ `
  mutation CreateSellwichShopProduct($input: CreateSellwichShopProductInput!) {
    createSellwichShopProduct(input: $input) {
      productId
      shopName
      spuNumber
      salePrice
      sellwichPrice
      customDescription
      customImages
      customVideos
      defaultProductVariant
      productVariants
      shippingCost
    }
  }
`;
export const updateSellwichShopProduct = /* GraphQL */ `
  mutation UpdateSellwichShopProduct($input: UpdateSellwichShopProductInput!) {
    updateSellwichShopProduct(input: $input) {
      productId
      shopName
      spuNumber
      salePrice
      sellwichPrice
      customDescription
      customImages
      customVideos
      defaultProductVariant
      productVariants
      shippingCost
    }
  }
`;
export const deleteSellwichShopProduct = /* GraphQL */ `
  mutation DeleteSellwichShopProduct($input: DeleteSellwichShopProductInput!) {
    deleteSellwichShopProduct(input: $input) {
      productId
      shopName
      spuNumber
      salePrice
      sellwichPrice
      customDescription
      customImages
      customVideos
      defaultProductVariant
      productVariants
      shippingCost
    }
  }
`;
export const createSellwichShopper = /* GraphQL */ `
  mutation CreateSellwichShopper($input: CreateSellwichShopperInput!) {
    createSellwichShopper(input: $input) {
      email
      accessCode
      name
      phoneNumber
      shippingAddress
      referralCode
      timestamp
    }
  }
`;
export const updateSellwichShopper = /* GraphQL */ `
  mutation UpdateSellwichShopper($input: UpdateSellwichShopperInput!) {
    updateSellwichShopper(input: $input) {
      email
      accessCode
      name
      phoneNumber
      shippingAddress
      referralCode
      timestamp
    }
  }
`;
export const deleteSellwichShopper = /* GraphQL */ `
  mutation DeleteSellwichShopper($input: DeleteSellwichShopperInput!) {
    deleteSellwichShopper(input: $input) {
      email
      accessCode
      name
      phoneNumber
      shippingAddress
      referralCode
      timestamp
    }
  }
`;
export const createSellwichOrder = /* GraphQL */ `
  mutation CreateSellwichOrder($input: CreateSellwichOrderInput!) {
    createSellwichOrder(input: $input) {
      orderNumber
      shopName
      stripeCustomerId
      shopperEmail
      shopperName
      orderTotal
      items
      shippingAddress
      trackingNumbers
      referralCode
      lastestPaymentTime
      paymentFailedTime
      paymentDisputeTime
      timestamp
      shopDisplayName
      dobaOrderPlacedTime
    }
  }
`;
export const updateSellwichOrder = /* GraphQL */ `
  mutation UpdateSellwichOrder($input: UpdateSellwichOrderInput!) {
    updateSellwichOrder(input: $input) {
      orderNumber
      shopName
      stripeCustomerId
      shopperEmail
      shopperName
      orderTotal
      items
      shippingAddress
      trackingNumbers
      referralCode
      lastestPaymentTime
      paymentFailedTime
      paymentDisputeTime
      timestamp
      shopDisplayName
      dobaOrderPlacedTime
    }
  }
`;
export const deleteSellwichOrder = /* GraphQL */ `
  mutation DeleteSellwichOrder($input: DeleteSellwichOrderInput!) {
    deleteSellwichOrder(input: $input) {
      orderNumber
      shopName
      stripeCustomerId
      shopperEmail
      shopperName
      orderTotal
      items
      shippingAddress
      trackingNumbers
      referralCode
      lastestPaymentTime
      paymentFailedTime
      paymentDisputeTime
      timestamp
      shopDisplayName
      dobaOrderPlacedTime
    }
  }
`;
export const createSellwichShopSale = /* GraphQL */ `
  mutation CreateSellwichShopSale($input: CreateSellwichShopSaleInput!) {
    createSellwichShopSale(input: $input) {
      itemNumberAndOrderNumber
      shopName
      itemNumber
      orderNumber
      quantity
      salePrice
      sellwichPrice
      timestamp
    }
  }
`;
export const updateSellwichShopSale = /* GraphQL */ `
  mutation UpdateSellwichShopSale($input: UpdateSellwichShopSaleInput!) {
    updateSellwichShopSale(input: $input) {
      itemNumberAndOrderNumber
      shopName
      itemNumber
      orderNumber
      quantity
      salePrice
      sellwichPrice
      timestamp
    }
  }
`;
export const deleteSellwichShopSale = /* GraphQL */ `
  mutation DeleteSellwichShopSale($input: DeleteSellwichShopSaleInput!) {
    deleteSellwichShopSale(input: $input) {
      itemNumberAndOrderNumber
      shopName
      itemNumber
      orderNumber
      quantity
      salePrice
      sellwichPrice
      timestamp
    }
  }
`;
export const createSellwichEvent = /* GraphQL */ `
  mutation CreateSellwichEvent($input: CreateSellwichEventInput!) {
    createSellwichEvent(input: $input) {
      sessionId
      timestamp
      userId
      message
      type
      source
      importance
    }
  }
`;
export const updateSellwichEvent = /* GraphQL */ `
  mutation UpdateSellwichEvent($input: UpdateSellwichEventInput!) {
    updateSellwichEvent(input: $input) {
      sessionId
      timestamp
      userId
      message
      type
      source
      importance
    }
  }
`;
export const deleteSellwichEvent = /* GraphQL */ `
  mutation DeleteSellwichEvent($input: DeleteSellwichEventInput!) {
    deleteSellwichEvent(input: $input) {
      sessionId
      timestamp
      userId
      message
      type
      source
      importance
    }
  }
`;
export const createSellwichUser = /* GraphQL */ `
  mutation CreateSellwichUser($input: CreateSellwichUserInput!) {
    createSellwichUser(input: $input) {
      id
      email
      hasCancelled
      hasGraduatedFromAcademy
      hasPublishedToFacebook
      hasSetupShop
      hasSignedUpForAcademy
      isInTrial
      referralCode
      stripeCustomerId
      firstname
      lastname
      middlename
      phoneNumber
      timestamp
      affiliateCode
      shopIds
      whatsappNumber
      facebookAccessToken
      facebookUsername
      facebookTokenExpiresIn
      facebookTokenExpirationTime
      trialStartTime
      academyStartTime
      academyGraduationTime
      cancelTime
      lastestPaymentTime
      paymentFailedTime
      profilePicture
      stripeSubscriptionId
      setupShopTime
    }
  }
`;
export const updateSellwichUser = /* GraphQL */ `
  mutation UpdateSellwichUser($input: UpdateSellwichUserInput!) {
    updateSellwichUser(input: $input) {
      id
      email
      hasCancelled
      hasGraduatedFromAcademy
      hasPublishedToFacebook
      hasSetupShop
      hasSignedUpForAcademy
      isInTrial
      referralCode
      stripeCustomerId
      firstname
      lastname
      middlename
      phoneNumber
      timestamp
      affiliateCode
      shopIds
      whatsappNumber
      facebookAccessToken
      facebookUsername
      facebookTokenExpiresIn
      facebookTokenExpirationTime
      trialStartTime
      academyStartTime
      academyGraduationTime
      cancelTime
      lastestPaymentTime
      paymentFailedTime
      profilePicture
      stripeSubscriptionId
      setupShopTime
    }
  }
`;
export const deleteSellwichUser = /* GraphQL */ `
  mutation DeleteSellwichUser($input: DeleteSellwichUserInput!) {
    deleteSellwichUser(input: $input) {
      id
      email
      hasCancelled
      hasGraduatedFromAcademy
      hasPublishedToFacebook
      hasSetupShop
      hasSignedUpForAcademy
      isInTrial
      referralCode
      stripeCustomerId
      firstname
      lastname
      middlename
      phoneNumber
      timestamp
      affiliateCode
      shopIds
      whatsappNumber
      facebookAccessToken
      facebookUsername
      facebookTokenExpiresIn
      facebookTokenExpirationTime
      trialStartTime
      academyStartTime
      academyGraduationTime
      cancelTime
      lastestPaymentTime
      paymentFailedTime
      profilePicture
      stripeSubscriptionId
      setupShopTime
    }
  }
`;

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Facebook } from '../utils/Facebook';
import { Footer } from '../components/Footer';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const Logout: React.FC = () => {

    const router = useIonRouter();
    const location = useLocation();

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        Facebook.logout()
            .then((resp) => {
                if (resp) {
                    // router.push('/');
                    window.location.assign('/');
                } else {
                    window.location.assign('/')
                }
            });
        EventData.logEvent(`Logout page visited.`, EventType.LOGOUT_PAGE_VISIT, 'LogoutPage');
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Logout</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Logout</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="container">
                    <div>
                        Logout
                    </div>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

export default Logout;

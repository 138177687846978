import React, { useState } from 'react';
import { IonContent, IonButton, IonHeader, IonFooter, IonToolbar, IonModal, IonItem, useIonToast, IonThumbnail, IonText, IonIcon
} from '@ionic/react';

import { checkmarkDoneOutline } from 'ionicons/icons';
import { Numbers } from '../utils/Numbers';
import { Forms } from '../utils/Forms';
import { ToastMessage } from '../utils/ToastMessage';
import { InventoryProduct } from '../models/InventoryProduct';

import './AddedToCartConfirmationModal.css';

interface AddedToCartConfirmationModalProps {
    isOpen: boolean;
    toggleIsOpen: Function;
    product: InventoryProduct;
    shopName: string;
}
export const AddedToCartConfirmationModal: React.FC<AddedToCartConfirmationModalProps> = ({ isOpen, toggleIsOpen, product, shopName }) => {

    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    const [niOpen, setNiOpen] = useState(isOpen);

    const cancelClicked = () => {
        setNiOpen(false);
        setTimeout(() => {
            toggleIsOpen();
        }, 500);
    }

    return (
        <IonModal isOpen={niOpen} onWillDismiss={cancelClicked}>
            <IonContent className='ion-padding'>
                <IonHeader>
                    <IonToolbar className='ion-text-center'>
                        <h5>Added to cart</h5>
                        <IonIcon icon={checkmarkDoneOutline} size='large' color='success' slot='end'></IonIcon>
                    </IonToolbar>
                </IonHeader>
                <div className='ion-justify-content-center ion-text-center container'>
                    <IonItem lines='none' className='model-item ion-text-center'>
                        <IonThumbnail id='product-thumbnail'>
                            <img src={product.productImages[0]}></img>
                        </IonThumbnail>
                        <IonText>
                            {product.productName}
                        </IonText>
                        {/** <IonIcon icon={checkmarkDoneOutline} size='large' color='success' slot='end'></IonIcon> */}
                    </IonItem>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar className='ion-padding' id='model-footer-toolbar'>
                    <IonButton fill='outline' color='medium' onClick={cancelClicked}>Keep shopping</IonButton>
                    <IonButton href={shopName + '/shopping/cart'} slot='end' fill='solid' color='primary'>Go to cart</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    );
};
import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, IonGrid, IonRow, IonCol, IonText } from '@ionic/react';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Forms } from '../utils/Forms';
import { useLocation } from 'react-router-dom';
import { LocalStorageData } from '../data/LocalStorageData';
import AddressComponent from '../components/Address';
import Address from '../models/Address';
import StripePayment from './../components/StripePayment';
import { Strings } from '../utils/Strings';
import { ShoppingCart } from '../models/ShoppingCart';
import { ShopperData } from '../data/ShopperData';
import { Shopper } from '../models/Shopper';
import { Order } from '../models/Order';
import { Shop } from '../models/Shop';
import { ShopData } from '../data/ShopData';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const Checkout: React.FC = () => {

    const router = useIonRouter();
    let { shopName } = useParams<{ shopName: string; }>();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paymentStatus = params.get('redirect_status');
    const paymentSucceeded = paymentStatus === 'succeeded' ? true : false;
    const ordaNum = params.get('orderNumber');

    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [address, setAddress] = useState(new Address());
    const [cart, setCart] = useState<ShoppingCart>();
    const [order, setOrder] = useState<Order>();
    const [shop, setShop] = useState<Shop>();

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        if (paymentSucceeded) {
            LocalStorageData.clearShoppingCart();
        }
        LocalStorageData.getShoppingCart()
            .then(kart => {
                if (kart) {
                    setCart(kart);
                } else {
                    EventData.logEvent(`Error no cart.`, EventType.ERROR, 'CheckoutPage');
                }
            });
        ShopData.getShopByName(shopName)
            .then(shopp => {
                if (shopp) {
                    setShop(shopp);
                }
            });
    }, []);

    const onStateChange = (e: any) => {
        let stateAbbr = e.target.value;
        address.state = stateAbbr;
        validateAddress();
        Forms.showErrorMessage(!validateState(stateAbbr), 'stateErrorMessage');
    }
    const validateGenericTextInput = (value: any): boolean => {
        if (!value || value.trim().length < 4) {
            return false;
        } else {
            return true;
        }
    }
    const validateState = (state: any) => {
        if (!state || (state.trim().length != 2)) {
            return false;
        } else {
            return true;
        }
    }
    const validateZipCode = (zip: any): boolean => {
        if (!zip || (zip.toString().length != 5)) {
            return false;
        } else {
            return true;
        }
    }
    const validateAddress = () => {
        // @ts-ignore
        if (!Strings.validateEmail(address.email)) {
            return false;
        } else if (!validateGenericTextInput(address.fullName)) {
            return false;
        } else if (!validateGenericTextInput(address.address1)) {
            return false;
        } else if (!validateGenericTextInput(address.city)) {
            return false;
        } else if (!validateState(address.state)) {
            return false;
        } else if (!validateZipCode(address.zipCode)) {
            return false;
        } else {
            return true;
        }
    }
    const updateEmail = (e: any) => {
        const value = e.target.value;
        address.email = value;
        Forms.showErrorMessage(!Strings.validateEmail(value), 'emailErrorMessage');
    }
    const updateName = (e: any) => {
        const value = e.target.value;
        address.fullName = value
        Forms.showErrorMessage(!validateGenericTextInput(value), 'nameErrorMessage');
    }
    const updateAddress1 = (e: any) => {
        const value = e.target.value;
        address.address1 = value;
        Forms.showErrorMessage(!validateGenericTextInput(value), 'address1ErrorMessage');
    }
    const updateAddress2 = (e: any) => {
        const value = e.target.value;
        address.address2 = value;
    }
    const updateCity = (e: any) => {
        const value = e.target.value;
        address.city = value;
        Forms.showErrorMessage(!validateGenericTextInput(value), 'cityErrorMessage');
    }
    const updateZipCode = (e: any) => {
        const value = e.target.value;
        address.zipCode = value;
        Forms.showErrorMessage(!validateZipCode(value), 'zipCodeErrorMessage');
    }
    const updatePhone = (e: any) => {
        const value = e.target.value;
        address.phone = value;
    }
    const next = () => {
        if (validateAddress()) {
            const orda = new Order;
            orda.shopName = shopName;
            orda.orderTotal = cart?.getOrderTotal() ? cart?.getOrderTotal() : 0;
            orda.shippingAddress = address;
            orda.shopperName = address.fullName ? address.fullName : '';
            orda.shopDisplayName = shop?.displayName ? shop?.displayName : '';
            orda.shopperEmail = address.email ? address.email : '';
            // @ts-ignore
            orda.items = Array.from(cart?.items.values());
            setOrder(orda);
            setShowPaymentForm(true);
            const shopper = new Shopper();
            shopper.email = address.email ? address.email : '';
            shopper.name = address.fullName ? address.fullName : '';
            shopper.phoneNumber = address.phone ? address.phone : '';
            shopper.timestamp = new Date().getTime();
            shopper.shippingAddress = address;
            ShopperData.createShopper(shopper);
        } else {
            // @ts-ignore
            Forms.showErrorMessage(!Strings.validateEmail(address.email), 'emailErrorMessage');
            Forms.showErrorMessage(!validateGenericTextInput(address.fullName), 'nameErrorMessage');
            Forms.showErrorMessage(!validateGenericTextInput(address.address1), 'address1ErrorMessage');
            Forms.showErrorMessage(!validateGenericTextInput(address.city), 'cityErrorMessage');
            Forms.showErrorMessage(!validateState(address.state), 'stateErrorMessage');
            Forms.showErrorMessage(!validateZipCode(address.zipCode), 'zipCodeErrorMessage');
        }
    }

    return (
        <IonPage>
            <IonContent class='ion-justify-content-center ion-text-center' fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Checkout</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="checkout-container ion-justify-content-center ion-text-center">
                    {!paymentSucceeded && !showPaymentForm &&
                    <>
                        <h5>Shipping address</h5>
                        <AddressComponent address={address} updateName={updateName} updateAddress1={updateAddress1} updateAddress2={updateAddress2} updateCity={updateCity} onStateChange={onStateChange} updateZipCode={updateZipCode} updateEmail={updateEmail} updatePhone={updatePhone}></AddressComponent>
                    </>
                    }
                    {!paymentSucceeded && showPaymentForm &&
                        <div className='section'>
                            <div className="">
                                <IonGrid class='full-height checkout-row'>
                                    <IonRow class='full-height'>
                                        <IonCol className=''>
                                            <IonGrid className='cart-order-detail-grid'>
                                                <IonRow>
                                                    <IonCol>
                                                        Shipping to: <b>{address.address1}</b>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className='ion-text-start'>Items ({cart?.getItemsCount()}):</IonCol>
                                                    <IonCol className='ion-text-end'>${cart?.getSubtotal()}</IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className='ion-text-start'>Shipping & handling:</IonCol>
                                                    <IonCol className='ion-text-end'>${cart?.getShippingAndHandlingFees()}</IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className='ion-text-start'>Tax:</IonCol>

                                                    <IonCol className='ion-text-end'>${cart?.getTaxes()}</IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol className='ion-text-start'><strong>Order total:</strong></IonCol>
                                                    <IonCol className='ion-text-end'><IonText color='danger'><strong>${cart?.getOrderTotal()}</strong></IonText></IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonCol>
                                        <IonCol id='payment-info-col' className='ion-justify-content-center ion-text-center'>
                                            { order &&
                                                /** @ts-ignore */
                                                < StripePayment amount={cart?.getOrderTotal()} paymentFrequency='once' email={address?.email} name={address?.fullName} order={order} />
                                            }
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </div>
                        </div>
                    }
                    {!paymentSucceeded && !showPaymentForm &&
                        <IonButton onClick={next} size='default' color='warning'>Next</IonButton>
                    }
                    { paymentSucceeded &&
                        <div className='container'>
                            <h2>Order number: <i>{ordaNum}</i></h2>
                            <h5>Thank you for your order. Check your email for order updates. </h5>
                            <h3><a href={'/' + shopName}>Keep shopping.</a></h3>
                        </div>
                    }
                    { paymentStatus && !paymentSucceeded &&
                        <div className='container'>
                            <h2 color='danger'>Payment failed. <a href={'/' + shopName + 'shopping/checkout'}>Try again.</a></h2>
                        </div>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Checkout;

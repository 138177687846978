import { IonButton, useIonToast, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonMenuButton, IonPage, IonRow, IonText, IonTitle, IonToolbar, IonSelect, IonSelectOption } from '@ionic/react';
import { ShoppingCart, CartItem } from '../models/ShoppingCart';
import { LocalStorageData } from '../data/LocalStorageData';
import { ToastMessage } from '../utils/ToastMessage';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Scripts } from '../utils/Scripts';

import './Cart.css';


const Cart: React.FC = () => {

    // TODO move the cart to the backend to avoid malicious actors from manipulating prices

    let { shopName } = useParams<{ shopName: string; }>();
    const [cart, setCart] = useState<ShoppingCart>();
    const [cartItems, setCartItems] = useState(new Array<CartItem>());
    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    const updateDisplayedCartItems = (kart: ShoppingCart) => {
        const itemList = new Array<CartItem>();
        kart?.items.forEach((item) => {
            itemList.push(item);
        });
        setCartItems([...itemList]);
    }

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        LocalStorageData.getShoppingCart()
            .then(kart => {
                if (kart) {
                    updateDisplayedCartItems(kart);
                    setCart(kart);
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                }
            });
    }, []);

    const removeClicked = (evt: any) => {
        // @ts-ignore
        LocalStorageData.removeItemFromCart(evt.target.id)
            .then(cartResp => {
                if (cartResp) {
                    // @ts-ignore
                    updateDisplayedCartItems(cartResp);
                    setCart(cartResp);
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                }
            });
    }

    const quantityChanged = (evt: any) => {
        const qty = parseInt(evt.target.value);
        const itemNumber = evt.target.dataset.itemNumber;
        if (!isNaN(qty)) {
            LocalStorageData.updateItemQuantityInCart(itemNumber, qty)
                .then(cartResp => {
                    if (cartResp) {
                        // @ts-ignore
                        updateDisplayedCartItems(cartResp);
                        setCart(cartResp);
                    } else {
                        toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                    }
                });
        }
    }

    return (
        <IonPage id='main'>
            <IonHeader>
                <IonToolbar>
                    <div className='cart-checkout-button'>
                        <p className='cart-subtotal'>Subtotal <strong>${cart?.getSubtotal()}</strong></p>
                        <IonButton disabled={cartItems.length === 0} href={shopName + '/shopping/checkout'} size='large' expand='block' color='warning'>Proceed to checkout</IonButton>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {/**
                 *   <IonHeader collapse="condense">
                        <IonToolbar>
                            <div className='cart-checkout-button'>
                                <IonButton disabled={cartItems.length === 0} href={shopName + '/shopping/checkout'} size='large' expand='block' color='warning'>Proceed to checkout</IonButton>
                            </div>
                        </IonToolbar>
                    </IonHeader>
                 */}
                <IonGrid id='cart-grid'>
                    {// @ts-ignore
                    cart && cartItems.map((item, idx) => {
                        return (
                            <IonRow className='cart-item-row' key={idx}>
                                <IonCol className='cart-img-col'>
                                    <img className='cart-img' src={item.product.productImages[0]}></img>
                                </IonCol>
                                <IonCol className='cart-desc-col'>
                                    <span className='cart-product-title'>{item.product.productName}</span>
                                    <span className='cart-sub-text'><strong>{item.product.variationTheme1}: </strong>{item.product.variationValue1}</span>
                                    <br></br>
                                    <span className='cart-sub-text'><strong>{item.product.variationTheme2}: </strong>{item.product.variationValue2}</span>
                                    <br></br>
                                    <IonItem color='light'>
                                        <IonInput onIonChange={quantityChanged} value={item.quantity} data-itemNumber={item.itemNumber}></IonInput>
                                        <IonButton slot='end' id={item.itemNumber} onClick={removeClicked} fill='clear' color='medium'>Remove</IonButton>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        );
                    })}
                    { cart && cartItems && cartItems.length === 0 &&
                        <div className='container'>
                            <h3>The cart is empty. <a href={'/' + shopName}>Do shopping.</a></h3>
                        </div>
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Cart;

import { IonButton } from "@ionic/react";
import { useEffect } from "react";
import { EventData } from "../data/EventData";
import { EventType } from "../models/Event";

interface WalletProps {
    setTitle: Function;
}

const Wallet: React.FC<WalletProps> = ({ setTitle }) => {

    useEffect(() => {
        EventData.logEvent(`Wallet page visited.`, EventType.WALLET_PAGE_VISIT, 'WalletComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);
    setTitle('Wallet');
    return (
        <div className="container">
            <div>To withdraw your earnings, you need to connect to a bank account or a paypal account.</div>
            <IonButton >Add your PayPal</IonButton>
            <IonButton >Add a bank account</IonButton>
        </div>
    );
};

export default Wallet;

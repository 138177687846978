import React, { useEffect, useState } from 'react';
import { IonSpinner } from '@ionic/react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckout from './StripeCheckout';
import FetchData from '../data/FetchData';
import { Constants } from '../utils/Constants';
import { LocalStorageData } from '../data/LocalStorageData';
import { UserData } from '../data/UserData';
import { OrderData } from './../data/OrderData';

import './StripePayment.css';
import { Order } from '../models/Order';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const stripePromise = loadStripe(Constants.STRIPE_PUBLISHABLE_KEY_PROD);
interface PaymentProps {
    amount: number;
    paymentFrequency: string;
    email: string;
    name: string;
    order: Order;
}

const StripePayment: React.FC<PaymentProps> = ({ amount, paymentFrequency, email, name, order }) => {

    const [clientSecret, setClientSecret] = useState('');
    const [options, setOptions] = useState({});
    const [subscriptionId, setSubscriptionId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [userId, setUserId] = useState<string>();

    amount *= 100;

    useEffect(() => {
        if (paymentFrequency === Constants.PAYMENT_FREQUENCY.ONCE) {
            // console.log(`THe user is a shopper.. the name is: ${name} the email is: ${email}, frequency: ${paymentFrequency}`);
            FetchData.post(Constants.STRIPE_CREATE_CUSTOMER_URL,
                { isTest: false, email: email, customerName: name })
                .then(resp => {
                    setCustomerId(resp['customerId']);
                    onCustomerCreated(resp['customerId'], '');
                }).catch(err => {
                    EventData.logEvent(`Error creating stripe customer. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                });
        } else if (paymentFrequency === Constants.PAYMENT_FREQUENCY.MONTHLY) {
            LocalStorageData.getLoggedInUserId()
                .then(usrId => {
                    if (usrId) {
                        setUserId(usrId);
                        UserData.getUserById(usrId)
                            .then(user => {
                                if (user?.email) {
                                    setCustomerEmail(user?.email);
                                }
                                if (user?.firstname && user.lastname) {
                                    setCustomerName(user.firstname + ' ' + user.lastname);
                                }
                                FetchData.post(Constants.STRIPE_CREATE_CUSTOMER_URL,
                                    { isTest: false, email: user?.email, customerName: user?.firstname + ' ' + user?.lastname })
                                    .then(resp => {
                                        setCustomerId(resp['customerId']);
                                        UserData.updateUser(usrId, { stripeCustomerId: resp['customerId'] })
                                            .then(resp => {
                                                //console.log(`stripeCustomerId: ${customerId} updated successfully. `, resp);
                                            }).catch(err => {
                                                EventData.logEvent(`Failed to update user's stripeCustomerId. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                                            });
                                        onCustomerCreated(resp['customerId'], usrId);
                                    }).catch(err => {
                                        EventData.logEvent(`Error creating stripe customer. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                                    });
                            });
                    }
                })
        }
    }, []);

    const onCustomerCreated = (customerId: string, usrId: string) => {
        if (clientSecret) {
            // console.log('Client secret created already: ', clientSecret);
            return;
        }
        if (paymentFrequency == Constants.PAYMENT_FREQUENCY.MONTHLY) {
            FetchData.post(Constants.STRIPE_SUBSCRIPTION_URL,
                { isTest: false, customerId: customerId, priceId: Constants.STRIPE_SUBSCRIPTION_PRICE_ID_PROD, amount: amount, productId: Constants.STRIPE_SUBSCRIPTION_PRODUCT_ID_PROD })
                .then(sub => {
                    // @ts-ignore
                    UserData.updateUser(usrId, { stripeSubscriptionId: sub['subscriptionId'] })
                        .then(resp => {
                            //console.log(`stripeSubscriptionId: ${subscriptionId} updated successfully. `, resp);
                        }).catch(err => {
                            EventData.logEvent(`Failed to update user's stripeSubscriptionId. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                        });
                    setSubscriptionId(sub['subscriptionId']);
                    const intentId = sub['setupIntentId'];
                    FetchData.post(Constants.STRIPE_RETRIEVE_INTENT_URL,
                        { isTest: false, intentId: intentId })
                        .then(intent => {
                            setOptions({ clientSecret: intent['clientSecret'] });
                            setClientSecret(intent['clientSecret']);
                        }).catch (err => {
                            EventData.logEvent(`Error retrieving intent. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                        });
                });
        } else if (paymentFrequency == Constants.PAYMENT_FREQUENCY.ONCE) {
            order.stripeCustomerId = customerId;
            OrderData.createOrder(order);
            FetchData.post(Constants.STRIPE_SIMPLE_INTENT_URL, { isTest: false, amount: amount, customerId: customerId })
                .then((intent) => {
                    const clientSecret = intent.clientSecret;
                    setOptions({ clientSecret });
                    setClientSecret(clientSecret);
                }).catch(err => {
                    EventData.logEvent(`Error creating simple intent. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripePayment', 1);
                });
        }
    }

    const appearance = { theme: 'stripe' };

    return (
        <>
            { !clientSecret &&
             <IonSpinner></IonSpinner>
            }
            <div>
                {clientSecret &&
                    <Elements options={options} stripe={stripePromise}>
                        <StripeCheckout email={customerEmail} name={customerName} amount={amount} frequency={paymentFrequency} orderNumber={order.orderNumber} shopName={order.shopName} ></StripeCheckout>
                    </Elements>
                }
            </div>
        </>
    );
};

export default StripePayment;

import { IonButton, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import BuiltShops from './BuiltShops';

import './ChooseBuiltStore.css';

interface BuiltShopProps {
    goToDashboard: Function;
}

const ChooseBuiltStore: React.FC<BuiltShopProps> = ( { goToDashboard } ) => {

    const selectingProducts = (e: any) => {
        goToDashboard('');
    }

    const pickedShop = (e: any) => {
        const shopToCloneId = e.target.dataset.shopId;
        goToDashboard(shopToCloneId);
    }

    return (
        <div id='built-store-container'>
            <h1 className='ion-padding'>Choose your own products or start with a made shop</h1>
            <IonButton onClick={selectingProducts} color='warning' fill='outline' size='large'>Select your own products</IonButton>

            <h1>OR</h1>

            <h3>Select from shops built to succeed</h3>
            <p>You can customize them, add or remove products</p>
            <BuiltShops pickedShop={pickedShop} />
        </div>
    );
};

export default ChooseBuiltStore;

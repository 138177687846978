export class InventoryProduct {

    itemNumber!: string;
    spuNumber!: string;
    isDiscontinued: boolean = false;
    updatedAt = new Date().getTime();
    skuCode!: string;
    url!: string;
    categoryString!: string;
    category!: string;
    topCategory!: string;
    parentCategory!: string;
    productName!: string;
    supplier!: string;
    brand!: string;
    variationTheme1!: string;
    variationValue1!: string;
    variationTheme2!: string;
    variationValue2!: string;
    msrp!: number;
    map!: number;
    dropshippingPrice!: number;
    inventoryQuantity!: number;
    inventoryLocation!: string;
    shippingMethod!: string;
    shipTo!: string;
    shipNotTo!: string;
    shippingEstimateCost!: number;
    pickupPriceWithPrepaidShippingLabel!: number;
    promotionFlag!: string;
    salePrice!: number;
    salePriceForPickup!: number;
    promotionStartDatePST!: string;
    promotionEndDatePST!: string;
    productVideo!: string;
    additionalResources!: string;
    productImages!: Array<string>;
    description!: string;
    descriptionHTML!: string;
    upc!: string;
    asin!: string;
    shippingLimitations!: string;
    prohibitedMarketplace!: string;
    processingBusinessDays!: number;
    returnAndRefundPolicy!: string;
    packagingSizeLength!: number;
    packagingSizeWidth!: number;
    packagingSizeHeight!: number;
    packagingSizeUnit!: string;
    packagingWeight!: number;
    packagingWeightUnit!: string;
    productSizeLength!: number;
    productSizeWidth!: number;
    productSizeHeight!: number;
    productSizeUnit!: string;
    productWeight!: number;
    productWeightUnit!: string;
    parameters!: Map<string, string>;
    inShops!: [];
}

import Address from "./Address";
import { Strings } from "../utils/Strings";

export class Shopper {
    email!: string;
    name!: string;
    phoneNumber!: string;
    shippingAddress!: Address;
    accessCode = Strings.genCode(6);
    referralCode!: string;
    timestamp = new Date().getTime();
}
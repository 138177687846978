import { InventoryProduct } from "./InventoryProduct";
import { Numbers } from './../utils/Numbers'
import { Constants } from "../utils/Constants";
import { EventData } from "../data/EventData";
import { EventType } from "./Event";

export class ShoppingCart {

    // TODO these calculations should be made and validated on the server-side otherwise
    // a malicious user could manually modifying it on the front-end
    constructor(items = null) {
        if (items) {
            this.items = new Map<string, CartItem>();
            // @ts-ignore
            for (let key in items) {
                //console.log(`Looping thru items, key: ${key}, value: `, items[key]);
                this.items.set(key, items[key]);
            }
        } else {
            this.items = new Map<string, CartItem>();
        }
        //console.log('IN the constructor, items: ', this.items);
    }

    items: Map<string, CartItem>;

    addItem = (product: InventoryProduct, quantity: number, salePrice: number, shippingCost: number, sellwichPrice: number) => {
        if (!this.items?.has(product.itemNumber)) {
            const item: CartItem = {
                itemNumber: product.itemNumber,
                quantity: quantity,
                product: product,
                salePrice: salePrice,
                shippingCost: shippingCost,
                sellwichPrice: sellwichPrice,
            }
            this.items.set(product.itemNumber, item);
        } else {
            const item = this.items?.get(product.itemNumber);
            if (!item) {
                EventData.logEvent(`The item should be in the cart already.`, EventType.ERROR, 'ShoppingCartPage', 1);
            } else {
                item.quantity += quantity;
                this.items.set(item.itemNumber, item);
            }
        }
    };

    removeItem = (itemNumber: string) => {
        return this.items.delete(itemNumber);
    }

    updateQuantity = (itemNumber: string, newQuantity: number) => {
        const item = this.items.get(itemNumber);
        if (item) {
            item.quantity = newQuantity;
            this.items.set(itemNumber, item);
        }
    }

    getItemsCount = () => {
        let count = 0;
        this.items?.forEach((val) => {
            count += val.quantity;
        });
        return count;
    }

    itemCount = this.getItemsCount();

    getSubtotal = () => {
        let total = 0;
        this.items?.forEach((val) => {
            total += val.quantity * val.salePrice;
        });
        return Math.round(total * 100)/100;
    }

    getShippingAndHandlingFees = () => {
        let total = 0;
        this.items?.forEach((val) => {
            total += val.shippingCost;
        });
        return Numbers.roundToDecimals(total);
    }

    getTaxes = () => {
        const total = this.getSubtotal() + this.getShippingAndHandlingFees();
        return Numbers.roundToDecimals(total * Constants.DEFAULT_TAX_RATE);
    }

    getOrderTotal = () => {
        const sum = this.getSubtotal() + this.getShippingAndHandlingFees() + this.getTaxes();
        return Numbers.roundToDecimals(sum);
    }
}

export interface CartItem {
    itemNumber: string;
    quantity: number;
    product: InventoryProduct;
    salePrice: number;
    shippingCost: number;
    sellwichPrice: number;
}

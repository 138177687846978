import { IonContent, IonPage } from '@ionic/react';
import { Header } from './../components/Header';
import { Footer } from '../components/Footer';
import FAQ from './../components/FAQ';
import { Scripts } from '../utils/Scripts';
import { useEffect } from 'react';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

export const FAQPage: React.FC = () => {

    Scripts.loadGoogleAnalytics();
    useEffect(() => {
        EventData.logEvent(`FAQ page visited`, EventType.FAQ_PAGE_VISIT, 'FAQPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    return (
        <IonPage>
            <IonContent fullscreen>
                <Header />
                <div className="ion-justify-content-center ion-text-center">
                    <FAQ />
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

import { IonButton, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonPage, IonToolbar,
    IonList, IonItem, IonThumbnail, IonSelect, IonSelectOption, IonLabel, IonIcon, IonButtons, IonGrid, IonRow, IonCol, IonInput, IonNote, IonBadge
} from '@ionic/react';
import { useParams } from 'react-router';
import { ShopFooter } from '../components/ShopFooter';
import { InventoryProduct } from '../models/InventoryProduct';
import { InventoryProductData } from '../data/InventoryProductData';
import { Strings } from '../utils/Strings';
import { cartOutline, shareSocial } from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { useEffect, useState } from 'react';
import { LocalStorageData } from '../data/LocalStorageData';
import { ShopProductData } from '../data/ShopProductData';
import { Forms } from '../utils/Forms';
import { ShoppingCart } from '../models/ShoppingCart';
import { AddedToCartConfirmationModal } from '../components/AddedToCartConfirmationModal';
import { ShopProduct } from '../models/ShopProduct';
import { ShopData } from './../data/ShopData';
import { Scripts } from '../utils/Scripts';

import './ShopProductDetail.css';
import { Shop } from '../models/Shop';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

export const ShopProductDetail: React.FC = () => {

    const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    const { shopName } = useParams<{ shopName: string; }>();
    const { itemNumber } = useParams<{ itemNumber: string; }>();
    const { spuNumber } = useParams<{ spuNumber: string; }>();
    // @ts-ignore
    const [inventoryProduct, setInventoryProduct] = useState<InventoryProduct>();
    const [shopProduct, setShopProduct] = useState<ShopProduct>();
    const [productVariants, setProductVariants] = useState<Array<InventoryProduct>>();
    const [theme1Variants, setTheme1Variants] = useState<Array<InventoryProduct>>();
    const [theme2Variants, setTheme2Variants] = useState<Array<InventoryProduct>>();
    const [images, setImages] = useState<Array<string>>();
    const [displayedImage, setDisplayedImage] = useState('https://arthurmillerfoundation.org/wp-content/uploads/2018/06/default-placeholder.png');
    const [canShare, setCanShare] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [theme1Value, setTheme1Value] = useState();
    const [theme2Value, setTheme2Value] = useState();
    const [cart, setCart] = useState<ShoppingCart>();
    const [showProductAddedModal, setShowProductAddedModal] = useState(false);
    const [shop, setShop] = useState<Shop>();

    // TODO order variants
    // TODO add related and `Who bought this bought that`, `Frequently bought together` sections etc. This is a P3 thing

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        Share.canShare().then(can => {
            if (can.value === true) {
                setCanShare(can.value);
            }
        }).catch(err => {
            setCanShare(false);
            EventData.logEvent(`Error checking canShare. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopProductDetailPage');
        });
        InventoryProductData.getInventoryProduct(itemNumber, spuNumber)
            .then(prod => {
                if (prod) {
                    setInventoryProduct(prod);
                    setDisplayedImage(prod.productImages[0]);
                    setImages(prod.productImages);
                } else {
                    // TODO show an error message
                    EventData.logEvent(`Error happened getting the product.`, EventType.ERROR, 'ShopProductDetail', 1);
                }
            });
        InventoryProductData.queryInventoryProductBySpuNumber(spuNumber)
            .then((prods) => {
                if (prods) {
                    setTheme1Variants(prods);
                    setTheme2Variants(prods);
                    setProductVariants(prods);
                }
            });
        ShopProductData.getShopProduct(spuNumber, shopName)
            .then(prod => {
                if (prod) {
                    setShopProduct(prod);
                }
            });
        LocalStorageData.getShoppingCart()
            .then(cartt => {
                if (cartt) {
                    setCart(cartt);
                }
            });
        ShopData.getShopByName(shopName)
            .then(shopp => {
                if (shopp) {
                    setShop(shopp);
                }
            });
        EventData.logEvent(`ShopProductDetail page visited.`, EventType.SHOP_PRODUCT_DETAIL_PAGE_VISIT, 'ShopProductDetailPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    const updateVariantsBySelectedValue = (theme: number, value = '') => {
        if (theme === 1) {
            setTheme1Variants(productVariants?.filter(v => v.variationValue2 === value));
        } else if (theme === 2) {
            const tempProds = productVariants?.filter(v => v.variationValue1 === value);
            setTheme2Variants(tempProds);
        }
    }

    const addToCart = (evt: any) => {
        let isValid = true;
        let errorMessage = '';
        if (inventoryProduct?.variationTheme1 && inventoryProduct.variationValue1 != theme1Value) {
            errorMessage += `Select ${inventoryProduct.variationTheme1}`;
            isValid = false;
            Forms.showErrorMessage(true, 'theme1ErrorMessage');
        }
        if (inventoryProduct?.variationTheme2 && inventoryProduct.variationValue2 != theme2Value) {
            if (errorMessage) {
                errorMessage += ` and ${inventoryProduct.variationTheme2}`;
            } else {
                errorMessage = `Select ${inventoryProduct.variationTheme2}`;
            }
            Forms.showErrorMessage(true, 'theme2ErrorMessage');
            isValid = false;
        }
        if (isValid) {
            if (inventoryProduct) {
                // @ts-ignore
                LocalStorageData.addProductToCart(inventoryProduct, quantity, shopProduct?.salePrice, inventoryProduct.shippingEstimateCost, shopProduct?.sellwichPrice)
                    .then(resp => {
                        // @ts-ignore
                        setCart(resp);
                        setShowProductAddedModal(true);
                    }).catch(err => {
                        EventData.logEvent(`Failed to add product to the shopping cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopProductDetail', 1);
                    });
            } else {
                EventData.logEvent(`The product to add to cart is null and it should not be null.`, EventType.ERROR, 'ShopProductDetail', 1);
                throw (`The product to add to cart is null and it should not be null.`);
            }
        }
    }

    const imageClicked = (evt: any) => {
        if (evt.target.src) {
            setDisplayedImage(evt.target.src);
        }
    }

    const variantChanged = (evt: any) => {
        const values = evt.target.value.split('-');
        const value = values[0];
        const itemNumber = values[1];
        const theme = parseInt(values[2]);
        var selectedVariant = productVariants?.find(x => x.itemNumber === itemNumber);
        if (selectedVariant) {
            if (theme === 1) {
                setTheme1Value(value);
                updateVariantsBySelectedValue(2, value);
                Forms.showErrorMessage(false, 'theme1ErrorMessage');
            } else if (theme === 2) {
                setTheme2Value(value);
                updateVariantsBySelectedValue(1, value);
                Forms.showErrorMessage(false, 'theme2ErrorMessage');
            }
            setInventoryProduct(selectedVariant);
            setImages(selectedVariant.productImages);
            setDisplayedImage(selectedVariant.productImages[0]);
        }
    }

    const getVariantSelect = (theme: number, variants: any) => {
        const mapped = new Map();
        if (theme === 1) {
            return <IonSelect onIonChange={variantChanged} id='theme1'>
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue1)) {
                        mapped.set(variant.variationValue1, 1);
                        return (
                            <IonSelectOption onClick={variantChanged} value={variant.variationValue1 + '-' + variant.itemNumber + '-' + '1'} data-itemNumber={variant.itemNumber} data-theme='1' key={index}>{variant.variationValue1}</IonSelectOption>
                        );
                    } else {
                        return null;
                    }
                })}
            </IonSelect>;
        }
        else if (theme === 2) {
            return <IonSelect onIonChange={variantChanged} id='theme2'>
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue2)) {
                        mapped.set(variant.variationValue2, 1);
                        return (
                            <IonSelectOption value={variant.variationValue2 + '-' + variant.itemNumber + '-' + '2'} data-itemNumbe={variant.itemNumber} data-theme='2' key={index}>{variant.variationValue2}</IonSelectOption>
                        );
                    }
                })}
            </IonSelect>;
        }
    }

    const share = () => {
        Share.share({
            title: `Check out this product`,
            text: `${inventoryProduct?.productName}`,
            url: `https://sellwich.com/product/${inventoryProduct?.itemNumber}/${inventoryProduct?.spuNumber}`,
            dialogTitle: `Check out this product`,
        }).then(resp => {
            // console.log('Shared successfully: ', resp);
        }).catch(err => {
            EventData.logEvent(`Error sharing product. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopProductDetail', 1);
        });
    }

    const onQuantityChanged = (evt: any) => {
        const value = parseInt(evt.target.value);
        if (value) {
            setQuantity(value);
        }
    }

    const getFooterDisplayNameStyles = () => {
        return {
            // @ts-ignore
            'color': shop.templateParameters['fontColorFooter'],
            // @ts-ignore
            'fontFamily': shop.templateParameters['fontFamilyFooter'],
            // @ts-ignore
            'fontSize': shop.templateParameters['fontSizeFooter'],
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton href={shopName + '/shopping/cart'}>
                            <IonBadge>{cart?.getItemsCount()}</IonBadge>
                            <IonIcon size='large' icon={cartOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div id='product-detail-container' className='ion-justify-content-center'>
                    {inventoryProduct && mobileMediaQuery.matches &&
                        <IonCard>
                            <IonCardHeader>
                                <h3>{Strings.shortenStringDisplay(inventoryProduct.productName, 60)}</h3>
                            </IonCardHeader>
                            <img className='product-img-mobile' src={displayedImage} alt={inventoryProduct.productName}></img>
                            <div className='product-thumbnails'>
                                {images && images.map((img, idx) => {
                                    return (
                                        <IonThumbnail key={idx}>
                                            <img src={img} onClick={imageClicked} alt={inventoryProduct.productName + ' image'}></img>
                                        </IonThumbnail>
                                    );
                                })}
                            </div>
                            <IonCardContent>
                                <h1>${shopProduct?.salePrice}</h1>
                            </IonCardContent>
                            <IonItem>Shipping: ${inventoryProduct.shippingEstimateCost}</IonItem>
                            {(inventoryProduct.variationTheme1 || inventoryProduct.variationTheme2) &&
                                <div className='product-variants'>
                                    <IonList>
                                        {inventoryProduct.variationTheme1 &&
                                            <>
                                            <IonItem>
                                                <IonLabel>{inventoryProduct.variationTheme1}:</IonLabel>
                                                {getVariantSelect(1, theme1Variants)}
                                            </IonItem>
                                            <IonItem id='theme1ErrorMessage' className='error-message' lines='none'>
                                                <IonNote>{'Select ' + inventoryProduct.variationTheme1}</IonNote>
                                            </IonItem>
                                            </>
                                        }
                                        {inventoryProduct.variationTheme2 &&
                                            <>
                                            <IonItem>
                                                <IonLabel>{inventoryProduct.variationTheme2}:</IonLabel>
                                                {getVariantSelect(2, theme2Variants)}
                                            </IonItem>
                                            <IonItem id='theme2ErrorMessage' className='error-message' lines='none'>
                                                <IonNote>{'Select ' + inventoryProduct.variationTheme2}</IonNote>
                                            </IonItem>
                                            </>
                                        }
                                    </IonList>
                                </div>
                            }
                            <IonItem>
                                <IonLabel>Quantity:</IonLabel>
                                <IonInput name='quantity' onIonChange={onQuantityChanged} type='number' value={quantity}></IonInput>
                            </IonItem>
                            <div id='add-to-shop-div'>
                                <IonButton size='default' expand='block' color='warning' onClick={addToCart} id={inventoryProduct.spuNumber} >Add to cart</IonButton>
                            </div>
                            { canShare &&
                                <IonButton onClick={share} expand='block' fill='outline' color='primary' id='share-btn'><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>
                            }

                            <h5 className='ion-padding'>Details:</h5>
                            <div dangerouslySetInnerHTML={{ __html: inventoryProduct.descriptionHTML }} className='ion-padding'></div>
                        </IonCard>
                    }
                    {inventoryProduct && desktopMediaQuery.matches &&
                        <IonGrid className='desktop-grid'>
                            <IonRow>
                                <IonCol id='title-img-col'>
                                    <h1>{Strings.shortenStringDisplay(inventoryProduct.productName, 90)}</h1>
                                    <div id='img-div ion-justify-content-center ion-text-center'>
                                        <img className='product-img-desktop' src={displayedImage} alt={inventoryProduct.productName}></img>
                                    </div>
                                    <div className='product-thumbnails'>
                                        {images && images.map((img, idx) => {
                                            return (
                                                <IonThumbnail key={idx}>
                                                    <img src={img} onClick={imageClicked} alt={inventoryProduct.productName + ' image'}></img>
                                                </IonThumbnail>
                                            );
                                        })}
                                    </div>

                                </IonCol>
                                <IonCol id='more-details-col'>
                                    <h1>${inventoryProduct.dropshippingPrice}</h1>
                                    <IonItem className='product-sub-item'>Shipping: ${inventoryProduct.shippingEstimateCost}</IonItem>
                                    {canShare &&
                                        <IonButton onClick={share} expand='block' fill='outline' color='primary' id='share-btn'><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>
                                    }
                                    {(inventoryProduct.variationTheme1 || inventoryProduct.variationTheme2) &&
                                        <div className='product-variants'>
                                            <IonList>
                                                {inventoryProduct.variationTheme1 &&
                                                    <>
                                                    <IonItem>
                                                        <IonLabel>{inventoryProduct.variationTheme1}:</IonLabel>
                                                        {getVariantSelect(1, theme1Variants)}
                                                    </IonItem>
                                                    <IonItem id='theme1ErrorMessage' className='error-message' lines='none'>
                                                        <IonNote>{'Select ' + inventoryProduct.variationTheme1}</IonNote>
                                                    </IonItem>
                                                    </>
                                                }
                                                {inventoryProduct.variationTheme2 &&
                                                    <>
                                                    <IonItem>
                                                        <IonLabel>{inventoryProduct.variationTheme2}:</IonLabel>
                                                        {getVariantSelect(2, theme2Variants)}
                                                    </IonItem>
                                                    <IonItem id='theme2ErrorMessage' className='error-message' lines='none'>
                                                        <IonNote>{'Select ' + inventoryProduct.variationTheme2}</IonNote>
                                                    </IonItem>
                                                    </>
                                                }
                                            </IonList>
                                        </div>
                                    }
                                    <IonItem>
                                        <IonLabel>Quantity:</IonLabel>
                                        <IonInput name='quantity' onIonChange={onQuantityChanged} type='number' value={quantity}></IonInput>
                                    </IonItem>

                                    <div id='add-to-shop-div'>
                                        {!inventoryProduct.inShops &&
                                            <IonButton expand='block' size='large' color='warning' onClick={addToCart} id={inventoryProduct.spuNumber} >Add to cart</IonButton>
                                        }
                                    </div>

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <h5 className='ion-padding'>Details:</h5>
                                    <div dangerouslySetInnerHTML={{ __html: inventoryProduct.descriptionHTML }} className='ion-padding'></div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    }
                    {!inventoryProduct &&
                        <div className='container'>
                            <h1>No product found. Go to <a href='/shop/dashboard'>dashboard</a></h1>
                        </div>
                    }
                </div>
                {showProductAddedModal && inventoryProduct &&
                    <AddedToCartConfirmationModal isOpen={showProductAddedModal} product={inventoryProduct} toggleIsOpen={() => setShowProductAddedModal(!showProductAddedModal)} shopName={shopName} />
                }
                { shop &&
                    <ShopFooter shopDisplayName={shop.displayName} displayNameStyles={getFooterDisplayNameStyles()} />
                }
            </IonContent>
        </IonPage>
    );
};

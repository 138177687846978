import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import Page from './pages/Page';
import Home from './pages/Home';
import Shop from './pages/Shop';
import Login from './pages/Login';
import Benefit from './pages/Benefit';
import Payment from './pages/Payment';
import CreateShop from './pages/CreateShop';
import ShopDashboard from './pages/ShopDashboard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import { AuthContextProvider } from './utils/AuthContext';
import { ProtectedRoute } from './utils/ProtectedRoute';
import { ProductDetail } from './pages/ProductDetail';
import { ShopProductDetail } from './pages/ShopProductDetail';
import InventoryProducts from './pages/InventoryProducts';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import OrdersManager from './pages/OrdersManager';
import Logout from './pages/Logout';
import MyShop from './pages/MyShop';
import { Learn } from './pages/Learn';
import { HowToCreateFacebookShop } from './pages/HowToCreateFacebookShop';
import { HowToCreateFacebookShopCatalog  } from './pages/HowToCreateFacebookShopCatalog';
import { HowToAddProductsToFacebookShop } from './pages/HowToAddProductsToFacebookShop';
import { HowToVerifyFacebookBusinessAccount } from './pages/HowToVerifyFacebookBusinessAccount';
import { HowToAdvertiseShopOnFB } from './pages/HowToAdvertiseShopOnFB';
import { SellOnFacebookShop } from './pages/SellOnFacebookShop';
import { FacebookMarketingStrategies } from './pages/FacebookMarketingStrategies';
import { CreatingAdsWithKliken } from './pages/CreatingAdsWithKliken';
import { HowToUseFacebookPixel } from './pages/HowToUseFacebookPixel';
import { SellingOnFacebookLiveStream } from './pages/SellingOnFacebookLiveStream';
import { PromotingOnFacebook } from './pages/PromotingOnFacebook';
import { WaysToMakeYourFirstSale } from './pages/WaysToMakeYourFirstSale';
import { SellOnFacebookMessenger } from './pages/SellOnFacebookMessenger';
import { FacebookInstagramTips } from './pages/FacebookInstagramTips';
import { HowToAddProductsToFacebookCatalog } from './pages/HowToAddProductsToFacebookCatalog';
import { AdsRetargeting } from './pages/AdsRetargeting';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { TaggingProductsOnInstagram } from './pages/TaggingProductsOnInstagram';
import { GoogleAnalyticsGuide } from './pages/GoogleAnalyticsGuide';
import { SalesWithInstagramStories } from './pages/SalesWithInstagramStories';
import { AdvertizingOnTikTok } from './pages/AdvertisingOnTikTok';
import { FAQPage } from './pages/FAQ';

/* Theme variables */
// import './theme/variables.css';

setupIonicReact();
Amplify.configure({
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHTYPE,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  Logging: {
    logGroupName: 'sellwich-client-log-group',
    logStreamName: 'Sellwich-client-log-stream',
    region: process.env.REACT_APP_AWS_PROJECT_REGION,
  }
});

const App: React.FC = () => {

  return (
    <AuthContextProvider>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route path="/" exact component={Home}></Route>
              <Route path="/login" exact component={Login}></Route>
              <Route path="/logout" exact component={Logout}></Route>
              <Route path="/learn" exact component={Learn}></Route>
              <Route path="/privacy-policy" exact component={PrivacyPolicy}></Route>
              <Route path="/terms-of-use" exact component={TermsOfUse}></Route>
              <Route path="/faq" exact component={FAQPage}></Route>
              <Route path="/shop/create" component={CreateShop}></Route>
              <Route path="/shop/dashboard"><ProtectedRoute><ShopDashboard /></ProtectedRoute></Route>
              <Route path="/ref/:referralCode" component={Home}></Route>
              <Route path="/ad/:adId" component={Home}></Route>
              <Route path="/learn/how-to-create-facebook-shop" component={HowToCreateFacebookShop}></Route>
              <Route path="/learn/how-to-create-facebook-shop-catalog" component={HowToCreateFacebookShopCatalog}></Route>
              <Route path="/learn/how-to-add-products-to-facebook-shop" component={HowToAddProductsToFacebookShop}></Route>
              <Route path="/learn/how-to-verify-facebook-business-account" component={HowToVerifyFacebookBusinessAccount}></Route>
              <Route path="/learn/how-to-advertise-your-shop-on-facebook" component={HowToAdvertiseShopOnFB}></Route>
              <Route path="/learn/sell-on-facebook-shop" component={SellOnFacebookShop}></Route>
              <Route path="/learn/strategies-to-boost-sales-with-facebook-marketing" component={FacebookMarketingStrategies}></Route>
              <Route path="/learn/creating-ads-with-kliken" component={CreatingAdsWithKliken}></Route>
              <Route path="/learn/how-to-use-facebook-pixel" component={HowToUseFacebookPixel}></Route>
              <Route path="/learn/selling-on-facebook-live-stream" component={SellingOnFacebookLiveStream}></Route>
              <Route path="/learn/promoting-on-facebook" component={PromotingOnFacebook}></Route>
              <Route path="/learn/30-ways-to-make-your-first-sale" component={WaysToMakeYourFirstSale}></Route>
              <Route path="/learn/sell-on-facebook-messenger" component={SellOnFacebookMessenger}></Route>
              <Route path="/learn/instagram-and-facebook-tips" component={FacebookInstagramTips}></Route>
              <Route path="/learn/how-to-add-products-to-facebook-catalog-for-my-business" component={HowToAddProductsToFacebookCatalog}></Route>
              <Route path="/learn/facebook-ads-retargeting" component={AdsRetargeting}></Route>
              <Route path="/learn/tagging-products-on-instagram" component={TaggingProductsOnInstagram}></Route>
              <Route path="/learn/complete-guide-to-google-analytics" component={GoogleAnalyticsGuide}></Route>
              <Route path="/learn/ways-to-generate-sales-with-instagram" component={SalesWithInstagramStories}></Route>
              <Route path="/learn/advertising-on-tiktok" component={AdvertizingOnTikTok}></Route>
              <Route path="/shop/edit/:shopName"><ProtectedRoute><MyShop /></ProtectedRoute></Route>
              <Route path="/product/:itemNumber/:spuNumber"><ProtectedRoute><ProductDetail /></ProtectedRoute></Route>
              <Route path="/product/search"><ProtectedRoute><InventoryProducts /></ProtectedRoute></Route>
              <Route path="/orders/manager"><ProtectedRoute><OrdersManager /></ProtectedRoute></Route>
              {/** <Route path="/page/:name" component={Page}></Route> */}
              <Route path="/pay/:product/:paymentType"><ProtectedRoute><Payment /></ProtectedRoute></Route>
              <Route path="/:productType/benefits"><ProtectedRoute><Benefit /></ProtectedRoute></Route>
              <Route path="/:shopName/shopping/cart" component={Cart}></Route>
              <Route path="/:shopName/shopping/checkout" component={Checkout}></Route>
              <Route path="/:shopName/:itemNumber/:spuNumber" component={ShopProductDetail}></Route>
              <Route path="/:shopName" component={Shop}></Route>
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AuthContextProvider>
  );
};

export default App;

import { IonButton, IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import { MouseEventHandler } from 'react';

import './ChooseBuiltStore.css';

interface BuiltShopsProps {
    pickedShop:  MouseEventHandler<HTMLIonButtonElement>;
}

const BuiltShops: React.FC<BuiltShopsProps> = ({ pickedShop }) => {

    return (
        <IonGrid>
            <IonRow className='ion-padding store-row'>
                <IonCol className='img-col ion-padding'>
                    <IonImg src='https://s3.amazonaws.com/sellwich.com/img/LaShein_Preview_min.webp'></IonImg>
                </IonCol>
                <IonCol className='ion-padding'>
                    <h3>Fast fashion shop that came from 0 to $1M in 1 year</h3>
                    <div className='shop-desc'>
                        LaShein is an established fast fashion ecommerce business that has already gained popularity among tens of thousands of fans and currently rakes in over $97,000 a month with over 140,000 monthly visitors.
                    </div>
                    <p>Revenue: <b>$97,000</b>/month</p>
                </IonCol>
                <IonCol className='make-it-yours-col ion-padding ion-justify-content-center ion-text-center'>
                    <div className='make-it-yours-col-content'>
                        <h5>Start a business like this</h5>
                        <IonButton data-shopId='lashein' onClick={pickedShop} size='large' color='warning'>Make it yours</IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow className='ion-padding store-row'>
                <IonCol className='img-col ion-padding'>
                    <IonImg src='https://s3.amazonaws.com/sellwich.com/img/TheHomecoming_Preview_min.webp'></IonImg>
                </IonCol>
                <IonCol className='ion-padding'>
                    <h3>Furniture store that reached $49K/month in 3 months</h3>
                    <div className='shop-desc'>
                        With a new trend in working from home, people have been updating their homes to commodate home offices; this store capitalize on that trend and provide affordable high quality products.
                    </div>
                    <p>Revenue: <b>$49,000</b>/month</p>
                </IonCol>
                <IonCol className='make-it-yours-col ion-padding ion-justify-content-center ion-text-center'>
                    <div className='make-it-yours-col-content'>
                        <h5>Start a business like this</h5>
                        <IonButton data-shopId='thehomecoming' onClick={pickedShop} size='large' color='warning'>Make it yours</IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <IonRow className='ion-padding store-row'>
                <IonCol className='img-col ion-padding'>
                    <IonImg src='https://s3.amazonaws.com/sellwich.com/img/DirtyDiana_Preview_min.webp'></IonImg>
                </IonCol>
                <IonCol className='ion-padding'>
                    <h3>The lingerie store that makes $33K/month</h3>
                    <div className='shop-desc'>
                        This online lingerie shop has more lingerie selection than your average mall Victoria Secret store and brings in more than $33K/month in revenue.
                    </div>
                    <p>Revenue: <b>$33,000</b>/month</p>
                </IonCol>
                <IonCol className='make-it-yours-col ion-padding ion-justify-content-center ion-text-center'>
                    <div className='make-it-yours-col-content'>
                        <h5>Start a business like this</h5>
                        <IonButton data-shopId='dirtydiana' onClick={pickedShop} size='large' color='warning'>Make it yours</IonButton>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default BuiltShops;

import { thumbsUpOutline, informationCircleOutline } from "ionicons/icons";

export class ToastMessage {

    constructor(presentToastMessage: Function) {
        this.presentMessage = presentToastMessage;
    }

    presentMessage: Function;

    showSuccessMessage = (message: string) => {
        this.showMessage(message, 'success', thumbsUpOutline);
    }

    showErrorMessage = (message: string) => {
        this.showMessage(message, 'danger', informationCircleOutline);
    }

    showMessage = (message: string, color: string, icon: any) => {

        this.presentMessage({
            message: message,
            duration: 2000,
            position: 'top',
            color: color,
            icon: icon
        })
    }
}
import { IonItem, IonList } from '@ionic/react';

import './LearningLinks.css';

export const LearnLinks: React.FC = () => {

    return (
        <IonList id='learning-links-list'>
            <IonItem>
                <a href='/learn/how-to-create-facebook-shop' className='learning-link-a' target='_target'>
                    How to create a Facebook Shop?
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-create-facebook-shop-catalog' className='learning-link-a' target='_target'>
                    How to create a Facebook Shop Catalog?
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-add-products-to-facebook-shop' className='learning-link-a' target='_target'>
                    How to add products to your Facebook Shop?
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-verify-facebook-business-account' className='learning-link-a' target='_target'>
                    How to verify your Facebook Business Account?
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-advertise-your-shop-on-facebook' className='learning-link-a' target='_target'>
                    How to Advertise your Shop on Facebook for beginners
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/sell-on-facebook-shop' className='learning-link-a' target='_target'>
                    Sell on Facebook with a Facebook Shop
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/strategies-to-boost-sales-with-facebook-marketing' className='learning-link-a' target='_target'>
                    Strategies to Boost Sales With Facebook Marketing
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/creating-ads-with-kliken' className='learning-link-a' target='_target'>
                    Creating ads with Kliken and Facebook Ads Manager
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-use-facebook-pixel' className='learning-link-a' target='_target'>
                    What Is Facebook Pixel and How to Use It
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/selling-on-facebook-live-stream' className='learning-link-a' target='_target'>
                    How to Sell Products Using Facebook Live Shopping
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/promoting-on-facebook' className='learning-link-a' target='_target'>
                    Promoting your Shop on Facebook
                </a>
            </IonItem>

            <IonItem>
                <a href='/learn/30-ways-to-make-your-first-sale' className='learning-link-a' target='_target'>
                    30 Ways to make your first Sale
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/sell-on-facebook-messenger' className='learning-link-a' target='_target'>
                    Sell on Facebook Messenger
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/instagram-and-facebook-tips' className='learning-link-a' target='_target'>
                    Proven Facebook and Instagram Tips
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/how-to-add-products-to-facebook-catalog-for-my-business' className='learning-link-a' target='_target'>
                    How to add Products to your Facebook Catalog for your Business
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/facebook-ads-retargeting' className='learning-link-a' target='_target'>
                    Ads Retargeting
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/tagging-products-on-instagram' className='learning-link-a' target='_target'>
                    Tagging Products on Instagram
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/complete-guide-to-google-analytics' className='learning-link-a' target='_target'>
                    A Complete Guide to Google Analytics for E-commerce Shops
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/ways-to-generate-sales-with-instagram' className='learning-link-a' target='_target'>
                    Easy Steps to Generate Sales with Instagram Stories
                </a>
            </IonItem>
            <IonItem>
                <a href='/learn/advertising-on-tiktok' className='learning-link-a' target='_target'>
                    Advertising on TikTok
                </a>
            </IonItem>
        </IonList>
    );
};

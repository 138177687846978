export const Numbers = {

    roundToDecimals: (num: number, decimals: number = 2): number => {
        let multiplier = 100;
        switch(decimals) {
            case 1:
                multiplier = 10;
                break;
            case 3:
                multiplier = 1000;
                break;
            case 4:
                multiplier = 10000;
                break;
            case 5:
                multiplier = 10000;
                break;
            default:
                multiplier = 100;
                break;
        }
        return Math.round(num * multiplier)/multiplier;
    },
}
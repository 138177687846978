import {
    IonAccordion,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonMenu,
    IonToolbar
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { listCircleOutline, listCircleSharp ,informationCircleOutline, informationCircleSharp ,helpCircleOutline, helpCircleSharp ,settingsOutline, settingsSharp, walletOutline, walletSharp,flagOutline, cashOutline, cashSharp, flagSharp, rocketOutline, rocketSharp, logOutOutline, logOutSharp } from 'ionicons/icons';
import './DashboardMenu.css';
import { useEffect, useRef, useState } from 'react';
import { ShopData } from '../data/ShopData';
import { LocalStorageData } from '../data/LocalStorageData';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

interface DashboardComponent {
    url: string;
    iosIcon?: string;
    mdIcon?: string;
    title: string;
    subs?: DashboardComponent[]
}

interface DashboardMenuProps {
    menuShops: any;
}

const dashboardComponents: DashboardComponent[] = [
    {
        title: 'My Sellwich',
        url: '/shop/dashboard',
        iosIcon: flagOutline,
        mdIcon: flagSharp
    },
    {
        title: 'My Shops',
        url: '/shop/dashboard',
        iosIcon: rocketOutline,
        mdIcon: rocketSharp,
        subs: []
    },
    {
        title: 'My Sales',
        url: '/shop/dashboard',
        iosIcon: cashOutline,
        mdIcon: cashSharp,
        subs: [
            {
                title: 'Direct sales',
                url: '/shop/dashboard?sales=direct'
            },
            {
                title: 'Referrals',
                url: '/shop/dashboard?referrals=referral'
            }
        ]
    },
    {
        title: 'Search Products',
        url: '/product/search',
        iosIcon: listCircleOutline,
        mdIcon: listCircleSharp
    },
    {
        title: 'Wallet',
        url: '/shop/dashboard?wallet=1',
        iosIcon: walletOutline,
        mdIcon: walletSharp
    },
    {
        title: 'Learning Center',
        url: '/shop/dashboard?learn=1',
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp
    },
    {
        title: 'Help',
        url: '/shop/dashboard?help=1',
        iosIcon: helpCircleOutline,
        mdIcon: helpCircleSharp
    },
    {
        title: 'Settings',
        url: '/shop/dashboard?settings=1',
        iosIcon: settingsOutline,
        mdIcon: settingsSharp
    },
    {
        title: 'Logout',
        url: '/logout',
        iosIcon: logOutOutline,
        mdIcon: logOutSharp
    }
];

const DashboardMenu: React.FC = () => {

    // TODO highlight currently selected menu item

    const location = useLocation();
    const menu = useRef<HTMLIonMenuElement>(null);

    const [menuComponents, setMenuComponents] = useState(dashboardComponents);

    useEffect(() => {
        LocalStorageData.getLoggedInUserId()
            .then(userId => {
                if (userId) {
                    ShopData.queryShopsByUserId(userId)
                     .then(shops => {
                        if (shops) {
                            let menuShops = new Array<DashboardComponent>();
                            shops.forEach(shop => {
                                menuShops.push({ title: shop.displayName, url: `/shop/edit/${shop.name}`})
                            });
                            menuComponents[1]['subs'] = menuShops;
                            setMenuComponents([...menuComponents]);
                        }
                     })
                } else {
                    EventData.logEvent(`Should be logged in, here. Something weird happened. Investigate`, EventType.ERROR, 'DashboardMenu', 1);
                }
            })
    }, []);

    const itemClicked = (evt: any) => {
        if (menu.current) {
            menu.current.close();
        }
    }
    return (
        <IonMenu contentId="main" type="overlay" menuId='main' ref={menu}>
            <IonHeader>
                <IonToolbar color='primary'>
                    <h4 className='ion-padding'>
                        Sellwich
                    </h4>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {menuComponents.map((appPage, index) => {
                    return (<div key={index}>
                        {appPage.subs &&
                            <>
                                <IonItem lines='none' detail={false}>
                                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} color='primary' />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            {appPage.subs.map((sub, idx) => {
                                return (
                                    <IonItem onClick={itemClicked} routerLink={sub.url} routerDirection="none" key={idx} lines='none' detail={false} className={location.pathname + location.search === sub.url ? 'selected sub-item' : 'sub-item'}>
                                        <IonLabel>{sub.title}</IonLabel>
                                    </IonItem>
                                )
                            })}
                            </>
                        }
                        {!appPage.subs &&
                            <IonItem onClick={itemClicked} key={index} className={location.pathname + location.search === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines='none' detail={false}>
                                <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} color='primary' />
                                <IonLabel>{appPage.title}</IonLabel>
                            </IonItem>
                        }
                    </div>);
                })}
            </IonContent>
        </IonMenu>
    );
};

export default DashboardMenu;

export const Constants = {
    MONTHLY_SUBSCRIPTION_PRICE: 24,
    MONTHLY_SUBSCRIPTION_COMMISSION_RATE: 0.5,
    MONTHLY_SUBSCRIPTION_COMMISSION_MONTHS: 3, // Number of months to pay the commission
    ACADEMY_PRICE: 249,
    ACADEMY_COMMISSION_RATE: 0.6,
    DEFAULT_TAX_RATE: 0.056,
    LOCAL_STORAGE_KEYS: {
        USER_ID: 'USER_ID',
        SHOP_ID_TO_CLONE: 'SHOP_ID_TO_CLONE',
        SHOPPING_CART: 'SHOPPING_CART',
        USER_SHOPS: 'USER_SHOPS',
        SESSION_ID: 'SESSION_ID',
        REFERRAL_CODE: 'REFERRAL_CODE',
        AD_ID: 'AD_ID',
    },
    AFFILIATE_CODE_LENGTH: 6,
    PRODUCTS_INITIAL_LOAD_NUM: 20,
    TEMPLATES: {
        DEFAULT: {
            NAME: 'default',
            // TODO this should be in the DB and retrive it from there
            PARAMS: { "displayNameFontFamily": "Trade Winds", "displayNameColorDesktop": "#fff", "fontSizeDesktop": "52px",
            "topHeaderImageDesktop": "url(https://s3.amazonaws.com/sellwich.com/img/header-image-default.jpeg)",
            "displayNameColorMobile": "#fff", "fontSizeMobile": "32px",
            "topHeaderImageMobile": "url(https://s3.amazonaws.com/sellwich.com/img/header-image-default.jpeg)",
            "numberOfModelPerRow": 4,
            "fontSizeFooter": "52px",
            "fontColorFooter": "#fff",
            "fontFamilyFooter": "Trade Winds" }
        }
    },
    INVENTORY_PRODUCTS_SEARCH_URL: 'https://4wast5x6fv2qzgm5ove43nwtli0qnpnq.lambda-url.us-east-1.on.aws/',
    STRIPE_SIMPLE_INTENT_URL: 'https://2ko27uj76f44kreau34dh4z2ki0hbjew.lambda-url.us-east-1.on.aws/',
    STRIPE_SUBSCRIPTION_URL: 'https://fp6emxod2z2hkk2oz6pjfamava0brbcb.lambda-url.us-east-1.on.aws/',
    STRIPE_CREATE_CUSTOMER_URL: 'https://tgvzx4hrkoa3j334yui7tx37hq0patvx.lambda-url.us-east-1.on.aws/',
    STRIPE_RETRIEVE_INTENT_URL: 'https://uv763eyhztuhhht56u5g32vpiu0evlhx.lambda-url.us-east-1.on.aws/',
    STRIPE_STANDARD_PRICE_ID_TEST: 'price_1MXIfCKPCuKCurseB7dyQDav',
    STRIPE_STANDARD_PRODUCT_ID_TEST: 'prod_NHsQnKWUaov9Tw',
    STRIPE_STANDARD_PRICE_ID_PROD: 'price_1MXIgpKPCuKCurseMisKYoo9',
    STRIPE_STANDARD_PRODUCT_ID_PROD: 'prod_NHsQnb3SkeDKfw',
    STRIPE_SUBSCRIPTION_PRICE_ID_TEST: 'price_1MXI4qKPCuKCurse6EAjOLWX',
    STRIPE_SUBSCRIPTION_PRODUCT_ID_TEST: 'prod_NHro5KWGnk5Jxz',
    STRIPE_SUBSCRIPTION_PRICE_ID_PROD: 'price_1MXI0LKPCuKCursenRIZa8qX',
    STRIPE_SUBSCRIPTION_PRODUCT_ID_PROD: 'prod_NHrj006MXBLLbw',
    STRIPE_PUBLISHABLE_KEY_TEST: 'pk_test_51LmpU4KPCuKCurseD3uEXJEfjlZdk3MvBPYLrd4keByqCAPl8pythzRrjxEnrHNPpf83enICeM4I0YViCVTfVsZe00pFiSdCRM',
    STRIPE_PUBLISHABLE_KEY_PROD: 'pk_live_51LmpU4KPCuKCurseyLue7VrbKU0ohSmJ9IQjjg8fGws1xfWseyxgA5V3yULsKra12wHemrqcf1BAKqhYHI5Lcbfl00KZIQgHcb',
    FACEBOOK_PRODUCT_DATA_FEED_URL: 'https://3acwdlavijcsabv5qviskqbzau0iwoeu.lambda-url.us-east-1.on.aws/',
    PAYMENT_FREQUENCY: {
        MONTHLY: 'monthly',
        ONCE: 'once'
    },
    SHIPPING_CARRIERS: {
        DHL: {
            TRACKING_URL: 'https://www.dhl.com/us-en/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=TRACKING_PLACEHOLDER'
        },
        USPS: {
            TRACKING_URL: 'https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=TRACKING_PLACEHOLDER'
        },
        UPS: {
            TRACKING_URL: 'https://www.ups.com/track?loc=en_US&requester=ST/TRACKING_PLACEHOLDER'
        },
        FEDEX: {
            TRACKING_URL: 'https://www.fedex.com/fedextrack/?trknbr=TRACKING_PLACEHOLDER'
        }
    },
    GOOGLE_DOCS_URL: {
        HOW_TO_CREATE_FACEBOOK_SHOP: 'https://docs.google.com/document/d/172NMF7Z_wHQe8MZi3IgVy5mVq-Tt4HwH2eYPOLLY1mw/',
        HOW_TO_CREATE_FACEBOOK_SHOP_CATALOG: 'https://docs.google.com/document/d/1H9uLhwY8gK-CSfRb8WeTThd9SIVVwzrInWg3X1Djerw/',
        HOW_TO_ADD_PRODUCTS_TO_FACEBOOK_SHOP: 'https://docs.google.com/document/d/1gEp_RlyF9tFw_yzKBD0YK5H_4KGI9sXoxzG79J6AnZo/',
        HOW_TO_VERIFY_FACEBOOK_BUSINESS_ACCOUNT: 'https://docs.google.com/document/d/1e5LyyM59Wds6VfR6a8XzEg39fAt49NrvM8eL3sHMesM/',
        HOW_TO_ADVERTISE_SHOP_ON_FB: 'https://docs.google.com/document/d/16-gd1jRJUPFR38pbm_oGgfgEYs5jcaEx2hEIesUTBhs/',
        SELL_ON_FACEBOOK_SHOP: 'https://docs.google.com/document/d/1ZP7Xe-n_Stkw1VxS4QZwHb84i-P8B5d7pM8Yzv8xJn0/',
        FACEBOOK_MARKETING_STRATEGIES: 'https://docs.google.com/document/d/1crE7TfulenWxfkHwm1q4nibeBEieGl-vJtuGWN7FM0w/',
        CREATING_ADS_WITH_KLIKEN: 'https://docs.google.com/document/d/1eiNSDQS7A6kXN_pQ0EnpYbUFuNrPB1Uw9xMzHrPBTYQ/',
        HOW_TO_USE_FACEBOOK_PIXEL: 'https://docs.google.com/document/d/1AYF6IO9Ykt-qRlG_cH8Exv1GKFxnanMvMeZcqSkKVW8/',
        SELLING_ON_FACEBOOK_LIVE_STREAM: 'https://docs.google.com/document/d/1gjrc5tUlRCLGpkL-zGvTbHun3watJ3Atwk_WHU2cOog/',
        PROMOTING_ON_FACEBOOK: 'https://docs.google.com/document/d/1_agHyCOd2Oi5fnuGIK9LrUX3IvH-11pU4MjeNiUPSHY/',
        WAYS_TO_MAKE_YOUR_FIRST_SALE: 'https://docs.google.com/document/d/1npYgCVeFI9PnHuey4_Hd7jLVTA9UqstPV-nJY-ARUlA/',
        SELL_ON_FACEBOOK_MESSENGER: 'https://docs.google.com/document/d/1DexPIRUjjWq2mJAq9HytWNIQpFiu6AMZ0MZfXcXQ-zQ/',
        FACEBOOK_INSTAGRAM_TIPS: 'https://docs.google.com/document/d/1a9ao3XIGKQt_BHrHHM7rzZzIOB4ieKMGWLPJ0GzqPKs/',
        HOW_TO_ADD_PRODUCTS_TO_FACEBOOK_CATALOG: 'https://docs.google.com/document/d/1efKK9rIZDrYBNEJq7ttqbIsTRdUlbO8Nx4ECdNlPVs4/',
        ADS_RETARGETING: 'https://docs.google.com/document/d/1JLD_qQjlVLi3iiwBAFDnzElIGptPwg_-bPcpBbntxK4/',
        TAGGING_PRODUCTS_ON_INSTAGRAM: 'https://docs.google.com/document/d/1PmphLgWuhNHaxFLLeV90eC29_8k5w0gHARG1DlbREvM/',
        GOOGLE_ANALYTICS_GUIDE: 'https://docs.google.com/document/d/1isAtmmMRZaD5wDk7lVDqVJrkZ4oF_3lGv7lUIiBBpGo/',
        SALES_WITH_INSTAGRAM_STORIES: 'https://docs.google.com/document/d/1DRJG2QKxC6O3GcKvEW5kZzx7x61DEQrRo9GBQUVloIc/',
        ADVERTISING_ON_TIKTOK: 'https://docs.google.com/document/d/14IgT4QU6zTmMDqKnH5WDAhjr4uCzL6Uc5y9j5wZAs7k/'
    },
    SCRIPTS: {
        FACEBOOK_CHAT_JS: './scripts/facebook.js',
        FACEBOOK_CHAT_JS_URL: 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js',
        FACEBOOK_PIXEL: './scripts/facebookPixel.js',
        FACEBOOK_PIXEL_URL: 'https://connect.facebook.net/en_US/fbevents.js',
        GOOGLE_ANALYTICS_JS: './scripts/google.js',
        GOOGLE_ANALYTICS_JS_URL: 'https://www.googletagmanager.com/gtag/js?id=G-4VKZWLX4KM'
    },
    YOUTUBE_PROMO_VIDEO_ID: 'sbwLH-BDoak',
    DEFAULT_REFERRAL_CODE: 'SELLWICH',
}
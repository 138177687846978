import { API, graphqlOperation } from 'aws-amplify';
import { getSellwichOrder, listSellwichOrders } from './../graphql/queries';
import { createSellwichOrder, updateSellwichOrder } from './../graphql/mutations';
import { Order } from '../models/Order';
import { EventData } from './EventData';
import { EventType } from '../models/Event';

export const OrderData = {

    getOrderByOrderNumber: async (orderNumber: string) => {
        try {
            const order = await API.graphql(graphqlOperation(getSellwichOrder, { orderNumber: orderNumber }));
            // @ts-ignore
            return new Order(order['data']['getSellwichOrder']);
        } catch (err) {
            EventData.logEvent(`Error getting order: ${orderNumber}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'OrderData', 1);
            return null;
        }
    },

    updateOrder: async (orderNumber: string, attrs: object) => {
        try {
            const updated = await API.graphql(graphqlOperation(updateSellwichOrder,
                { input: { orderNumber: orderNumber, ...attrs } }));
            return updated;
        } catch (err: any) {
            EventData.logEvent(`Error updating order with attrs: ${attrs}, orderNumber: ${orderNumber}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'OrderData', 1);
        }
    },

    createOrder: async (order: Order) => {
        try {
            // @ts-ignore
            order.items = JSON.stringify(order.items);
            // @ts-ignore
            order.shippingAddress = JSON.stringify(order.shippingAddress);
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichOrder,
                    { input: order }));
            // @ts-ignore
            return created['data']['createSellwichOrder'];
        } catch (err) {
            EventData.logEvent(`Error creating order. OrderId: ${order.orderNumber}, ShopName: ${order.shopName}, shopperEmail: ${order.shopperEmail}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'OrderData', 1);
        }
    },

    listOrders: async () => {
        try {
            const ordersResults = await API.graphql(graphqlOperation(listSellwichOrders, { input: { limit: 20 } }));
            // @ts-ignore
            const results = ordersResults['data']['listSellwichOrders']['items'];
            const orders = new Array<Order>();
            results.forEach((r: any) => {
                orders.push(new Order(r));
            })
            return orders;
        } catch (err) {
            EventData.logEvent(`Error listing orders. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'OrderData', 1);
            return null;
        }
    },
}
import { IonButton, IonItem, IonList } from "@ionic/react";
import { useEffect } from "react";
import { EventData } from "../data/EventData";
import { EventType } from "../models/Event";
import { LearnLinks } from "./LearningLinks";

interface LearningCenterProps {
    setTitle: Function;
}

const LearningCenter: React.FC<LearningCenterProps> = ({ setTitle }) => {

    useEffect(() => {
        EventData.logEvent(`LearningCenter page visited.`, EventType.LEARNING_CENTER_PAGE_VISIT, 'LearningCenterComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);
    setTitle('Learning Center');
    return (
        <div className="ion-padding">
            <LearnLinks />
        </div>
    );
};

export default LearningCenter;

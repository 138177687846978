import { API, graphqlOperation } from 'aws-amplify';
import { getSellwichShopper, listSellwichShoppers } from './../graphql/queries';
import { createSellwichShopper, updateSellwichShopper } from './../graphql/mutations';
import { Shopper } from '../models/Shopper';
import { EventData } from './EventData';
import { EventType } from '../models/Event';

export const ShopperData = {

    getShopperByEmail: async (email: string) => {
        try {
            const shopper = await API.graphql(graphqlOperation(getSellwichShopper, { email: email }));
            // @ts-ignore
            return shopper['data']['getSellwichShopper'] as Shopper;
        } catch (err) {
            EventData.logEvent(`Error getting Shopper: ${email}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopperData', 1);
            return null;
        }
    },

    updateShopper: async (email: string, attrs: object) => {
        try {
            const updated = await API.graphql(graphqlOperation(updateSellwichShopper,
                { input: { email: email, ...attrs } }));
            return updated;
        } catch (err: any) {
            EventData.logEvent(`Error updating shopper with attrs: ${attrs}, email: ${email}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopperData', 1);
        }
    },

    createShopper: async (shopper: Shopper) => {
        try {
            // @ts-ignore
            shopper.shippingAddress = JSON.stringify(shopper.shippingAddress);
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichShopper,
                    { input: shopper }));
            // @ts-ignore
            return created['data']['createSellwichShopper'];
        } catch (err) {
            EventData.logEvent(`Error creating shopper. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopperData', 1);
        }
    },

    listShoppers: async () => {
        try {
            const shoppers = await API.graphql(graphqlOperation(listSellwichShoppers, { input: { limit: 20 } }));
            // @ts-ignore
            return shoppers['data']['listSellwichShoppers']['items'];
        } catch (err) {
            EventData.logEvent(`Error listing shoppers. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopperData', 1);
            return null;
        }
    },
}
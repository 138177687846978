import {
    IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, useIonToast, IonPage, IonSpinner, IonToolbar, } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { ShopData } from './../data/ShopData';
import { Strings } from './../utils/Strings';

// Templates
import Default from './../templates/Default';

import './Shop.css';
import { Constants } from '../utils/Constants';
import { UserData } from '../data/UserData';
import { ShopProductData } from '../data/ShopProductData';
import { ShopProduct } from '../models/ShopProduct';
import { ProductVariants } from '../components/ProductVariants';
import { InventoryProductData } from '../data/InventoryProductData';
import { InventoryProduct } from '../models/InventoryProduct';
import { cartOutline } from 'ionicons/icons';
import { ShoppingCart } from '../models/ShoppingCart';
import { LocalStorageData } from '../data/LocalStorageData';
import { AddedToCartConfirmationModal } from '../components/AddedToCartConfirmationModal';
import { ToastMessage } from '../utils/ToastMessage';
import { Scripts } from '../utils/Scripts';

let nextToken = '';
let allShopProds: Array<ShopProduct>;
let allProductsLoaded = false;

const Shop: React.FC = () => {

    let { shopName } = useParams<{ shopName: string; }>();
    shopName = Strings.normalizeShopName(shopName);
    const [shop, setShop] = useState(undefined);
    const [templateName, setTemplateName] = useState('');
    const [shopIsActive, setShopIsActive] = useState(true);
    const [shopFound, setShopFound] = useState(true);
    const [shopProducts, setShopProducts] = useState<Array<ShopProduct>>();
    const [productVariants, setProductVariants] = useState<Array<InventoryProduct>>();
    const [showProductVariants, setShowProductsVariants] = useState(false);
    const [cart, setCart] = useState<ShoppingCart>();
    const [showProductAddedModal, setShowProductAddedModal] = useState(false);
    const [shippingCostForProductToAddToCart, setShippingCostForProductToAddToCart] = useState(0);
    const [productAddedToCart, setProductAddedToCart] = useState<InventoryProduct>();
    const [shopProductToAddToCart, setShopProductToAddToCart] = useState<ShopProduct>();
    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        ShopData.getShopByName(shopName)
            .then((shopData) => {
                if (!shopData) {
                    setShopFound(false);
                } else {
                    UserData.getUserById(shopData?.userId)
                        .then(user => {
                            if (user) {
                                // The user is in trial or made a payment in the last 31 days
                                if (user?.isInTrial === 1 || user?.lastestPaymentTime > (new Date().getTime() - (31 * 24 * 60 * 60 * 1000))) {
                                    getShopProducts(shopData);
                                } else {
                                    LocalStorageData.getLoggedInUserId()
                                        .then(userId => {
                                            if (userId && userId === shopData?.userId) {
                                                getShopProducts(shopData);
                                            } else {
                                                setShopIsActive(false);
                                            }
                                        });
                                }
                            } else {
                                setShopIsActive(false);
                            }
                        })
                }
            });
        LocalStorageData.getShoppingCart()
            .then(cartt => {
                if (cartt) {
                    setCart(cartt);
                }
            });
    }, []);

    const getShopProducts = (shopData: any) => {
        ShopProductData.queryShopProductsByShopName(shopData.name, nextToken)
            .then(results => {
                nextToken = results?.nextToken
                if (results?.shopProds) {
                    setShopProducts(results?.shopProds);
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                    throw (`Failed to get shop product for ${shopData.name}`);
                }
            });
        // @ts-ignore
        setShop(shopData);
        // @ts-ignore
        setTemplateName(shopData?.templateName);
    }

    const getMoreShopProducts = (evt: any) => {
        if (allProductsLoaded) {
            if (evt) {
                evt.target.complete();
            }
            return;
        }
        // @ts-ignore
        ShopProductData.queryShopProductsByShopName(shop?.name, nextToken)
            .then(results => {
                if (results) {
                    nextToken = results.nextToken;
                    if (shopProducts) {
                        if (shopProducts[0].productId === results.shopProds[0].productId) {
                            allProductsLoaded = true;
                        } else {
                            setShopProducts([...shopProducts, ...results.shopProds]);
                        }
                    }
                    if (evt) {
                        evt.target.complete();
                    }
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                    // @ts-ignore
                    throw (`Failed to get shop product for ${shop?.name}`);
                }
            });
    }

    const addToCartClicked = (spuNumber: string) => {
        // TODO show a spinner until prods are returned - P2
        InventoryProductData.queryInventoryProductBySpuNumber(spuNumber)
            .then(prods => {
                if (prods) {
                    let cost = 0;
                    prods.forEach(p => {
                        if (p.shippingEstimateCost > cost) {
                            cost = p.shippingEstimateCost;
                        }
                    });
                    setShippingCostForProductToAddToCart(cost);
                    setProductVariants(prods);
                    setShowProductsVariants(true);
                }
            });
        ShopProductData.getShopProduct(spuNumber, shopName)
            .then(prodResp => {
                if (prodResp) {
                    setShopProductToAddToCart(prodResp);
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.')
                }
            });
    }

    const onProductAddedToCart = (product: InventoryProduct) => {
        setProductAddedToCart(product);
        setShowProductAddedModal(true);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {/** @ts-ignore
                    <IonTitle class="ion-justify-content-center ion-text-center">{shop?.displayName}</IonTitle>
                     */}
                    <IonButtons slot='end'>
                        <IonButton href={shopName + '/shopping/cart'}><IonBadge>{cart?.getItemsCount()}</IonBadge><IonIcon icon={cartOutline} size='large'></IonIcon></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {/** @ts-ignore */}
                {shop && shopProducts && templateName == Constants.TEMPLATES.DEFAULT.NAME && shopIsActive && <Default shop={shop} shopProducts={shopProducts} addToCart={addToCartClicked} getMoreShopProducts={getMoreShopProducts} />
                }

                { !shopFound &&
                    <div className='container'>
                        <h4>Shop not found.</h4>
                        <p>Create a new shop <a href='/shop/create'>here</a>.</p>
                    </div>
                }

                { !shopIsActive &&
                    <div className='container'>
                        <h4>This shop is not active.</h4>
                        <p>Go <a href='/'>home</a>.</p>
                    </div>
                }

                { shop === undefined && shopIsActive && shopFound &&
                    <div className='container'>
                        <IonSpinner></IonSpinner>
                    </div>
                }
                {productVariants && showProductVariants && shopProductToAddToCart &&
                    <ProductVariants productVariants={productVariants} shippingCost={shippingCostForProductToAddToCart} salePrice={shopProductToAddToCart.salePrice} sellwichPrice={shopProductToAddToCart.sellwichPrice} isOpen={showProductVariants} toggleIsOpen={() => setShowProductsVariants(!showProductVariants)} onProductAddedToCart={onProductAddedToCart} setCart={setCart}  />
                }
                {showProductAddedModal && productAddedToCart &&
                    <AddedToCartConfirmationModal isOpen={showProductAddedModal} product={productAddedToCart} toggleIsOpen={() => setShowProductAddedModal(!showProductAddedModal)} shopName={shopName} />
                }
            </IonContent>

        </IonPage>
    );
};

export default Shop;

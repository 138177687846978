import React from 'react';
import { IonButton, IonButtons, IonFooter, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { logoTwitter, logoFacebook, logoInstagram, logoTiktok, logoPinterest, logoYoutube, logoLinkedin, heart } from 'ionicons/icons';

import './ShopFooter.css';

interface ShopFooterProps {
    shopDisplayName: string;
    displayNameStyles: any;
}
export const ShopFooter: React.FC<ShopFooterProps> = ({ shopDisplayName, displayNameStyles }) => (
    <>
        <IonFooter id='shop-footer' className='ion-padding' >
            <IonToolbar id='shop-footer-toolbar'>
                <h1 className='show-on-desktop' style={displayNameStyles}>{shopDisplayName}</h1>
                <IonButtons slot='end'>
                    <IonButton href='/terms-of-use' slot='end' color='light'>Terms of use</IonButton>
                    <IonButton href='/privacy-policy' slot='end' color='light'>Privacy Policy</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>

    </>
);
const UsStates = [
    { abbr: "AL", name: "Alabama", combinedTaxRate: 0.0924, taxRate: 0.04 },
    { abbr: "AK", name: "Alaska", combinedTaxRate: 0.0176, taxRate: 0.0 },
    { abbr: "AZ", name: "Arizona", combinedTaxRate: 0.084, taxRate: 0.056 },
    { abbr: "AR", name: "Arkansas", combinedTaxRate: 0.0947, taxRate: 0.065 },
    { abbr: "CA", name: "California", combinedTaxRate: 0.0882, taxRate: 0.0725 },
    { abbr: "CO", name: "Colorado", combinedTaxRate: 0.0777, taxRate: 0.029 },
    { abbr: "CT", name: "Connecticut", combinedTaxRate: 0.0635, taxRate: 0.0635 },
    { abbr: "DE", name: "Delaware", combinedTaxRate: 0.0, taxRate: 0.0 },
    { abbr: "DC", name: "District of Columbia", combinedTaxRate: 0.06, taxRate: 0.06 },
    { abbr: "FL", name: "Florida", combinedTaxRate: 0.0701, taxRate: 0.06 },
    { abbr: "GA", name: "Georgia", combinedTaxRate: 0.0735, taxRate: 0.04 },
    { abbr: "HI", name: "Hawaii", combinedTaxRate: 0.0444, taxRate: 0.04 },
    { abbr: "ID", name: "Idaho", combinedTaxRate: 0.0602, taxRate: 0.06 },
    { abbr: "IL", name: "Illinois", combinedTaxRate: 0.0881, taxRate: 0.0625 },
    { abbr: "IN", name: "Indiana", combinedTaxRate: 0.07, taxRate: 0.07 },
    { abbr: "IA", name: "Iowa", combinedTaxRate: 0.0694, taxRate: 0.06 },
    { abbr: "KS", name: "Kansas", combinedTaxRate: 0.087, taxRate: 0.065 },
    { abbr: "KY", name: "Kentucky", combinedTaxRate: 0.06, taxRate: 0.06 },
    { abbr: "LA", name: "Louisiana", combinedTaxRate: 0.0955, taxRate: 0.0445 },
    { abbr: "ME", name: "Maine", combinedTaxRate: 0.055, taxRate: 0.055 },
    { abbr: "MD", name: "Maryland", combinedTaxRate: 0.06, taxRate: 0.06 },
    { abbr: "MA", name: "Massachusetts", combinedTaxRate: 0.0625, taxRate: 0.0625 },
    { abbr: "MI", name: "Michigan", combinedTaxRate: 0.06, taxRate: 0.06 },
    { abbr: "MN", name: "Minnesota", combinedTaxRate: 0.0749, taxRate: 0.06875 },
    { abbr: "MS", name: "Mississippi", combinedTaxRate: 0.0707, taxRate: 0.07 },
    { abbr: "MO", name: "Missouri", combinedTaxRate: 0.0829, taxRate: 0.04225 },
    { abbr: "MT", name: "Montana", combinedTaxRate: 0.0, taxRate: 0.0 },
    { abbr: "NE", name: "Nebraska", combinedTaxRate: 0.0694, taxRate: 0.055 },
    { abbr: "NV", name: "Nevada", combinedTaxRate: 0.0823, taxRate: 0.0685 },
    { abbr: "NH", name: "New Hampshire", combinedTaxRate: 0.0, taxRate: 0.0 },
    { abbr: "NJ", name: "New Jersey", combinedTaxRate: 0.066, taxRate: 0.06625 },
    { abbr: "NM", name: "New Mexico", combinedTaxRate: 0.0784, taxRate: 0.05125 },
    { abbr: "NY", name: "New York", combinedTaxRate: 0.0852, taxRate: 0.04 },
    { abbr: "NC", name: "North Carolina", combinedTaxRate: 0.0689, taxRate: 0.0475 },
    { abbr: "ND", name: "North Dakota", combinedTaxRate: 0.0696, taxRate: 0.05 },
    { abbr: "OH", name: "Ohio", combinedTaxRate: 0.0722, taxRate: 0.0575 },
    { abbr: "OK", name: "Oklahoma", combinedTaxRate: 0.0897, taxRate: 0.045 },
    { abbr: "OR", name: "Oregon", combinedTaxRate: 0.0, taxRate: 0.0 },
    { abbr: "PA", name: "Pennsylvania", combinedTaxRate: 0.0634, taxRate: 0.06 },
    { abbr: "RI", name: "Rhode Island", combinedTaxRate: 0.07, taxRate: 0.07 },
    { abbr: "SC", name: "South Carolina", combinedTaxRate: 0.0744, taxRate: 0.06 },
    { abbr: "SD", name: "South Dakota", combinedTaxRate: 0.064, taxRate: 0.045 },
    { abbr: "TN", name: "Tennessee", combinedTaxRate: 0.0955, taxRate: 0.07 },
    { abbr: "TX", name: "Texas", combinedTaxRate: 0.082, taxRate: 0.0625 },
    { abbr: "UT", name: "Utah", combinedTaxRate: 0.0719, taxRate: 0.061 },
    { abbr: "VT", name: "Vermont", combinedTaxRate: 0.0624, taxRate: 0.06 },
    { abbr: "VA", name: "Virginia", combinedTaxRate: 0.0575, taxRate: 0.053 },
    { abbr: "WA", name: "Washington", combinedTaxRate: 0.0929, taxRate: 0.065 },
    { abbr: "WV", name: "West Virginia", combinedTaxRate: 0.0652, taxRate: 0.06 },
    { abbr: "WI", name: "Wisconsin", combinedTaxRate: 0.0543, taxRate: 0.05 },
    { abbr: "WY", name: "Wyoming", combinedTaxRate: 0.0522, taxRate: 0.04 }
];

export default UsStates;
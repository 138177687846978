/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSellwichInventoryCategory = /* GraphQL */ `
  query GetSellwichInventoryCategory($name: String!) {
    getSellwichInventoryCategory(name: $name) {
      name
      parentCategory
      displayName
      subCategories
      updatedAt
    }
  }
`;
export const listSellwichInventoryCategories = /* GraphQL */ `
  query ListSellwichInventoryCategories(
    $filter: TableSellwichInventoryCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichInventoryCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        parentCategory
        displayName
        subCategories
        updatedAt
      }
      nextToken
    }
  }
`;
export const querySellwichInventoryCategoriesByParentCategoryIndex = /* GraphQL */ `
  query QuerySellwichInventoryCategoriesByParentCategoryIndex(
    $parentCategory: String!
    $first: Int
    $after: String
  ) {
    querySellwichInventoryCategoriesByParentCategoryIndex(
      parentCategory: $parentCategory
      first: $first
      after: $after
    ) {
      items {
        name
        parentCategory
        displayName
        subCategories
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSellwichInventoryProduct = /* GraphQL */ `
  query GetSellwichInventoryProduct($itemNumber: String!) {
    getSellwichInventoryProduct(itemNumber: $itemNumber) {
      itemNumber
      category
      parentCategory
      skuCode
      spuNumber
      isDiscontinued
      updatedAt
      url
      categoryString
      topCategory
      productName
      supplier
      brand
      variationTheme1
      variationValue1
      variationTheme2
      variationValue2
      msrp
      map
      dropshippingPrice
      inventoryQuantity
      inventoryLocation
      shippingMethod
      shipTo
      shipNotTo
      shippingEstimateCost
      pickupPriceWithPrepaidShippingLabel
      promotionFlag
      salePrice
      salePriceForPickup
      promotionStartDatePST
      promotionEndDatePST
      productVideo
      additionalResources
      productImages
      description
      descriptionHTML
      upc
      asin
      shippingLimitations
      prohibitedMarketplace
      processingBusinessDays
      returnAndRefundPolicy
      packagingSizeLength
      packagingSizeWidth
      packagingSizeHeight
      packagingSizeUnit
      packagingWeight
      packagingWeightUnit
      productSizeLength
      productSizeWidth
      productSizeHeight
      productSizeUnit
      productWeight
      productWeightUnit
      parameters
    }
  }
`;
export const listSellwichInventoryProducts = /* GraphQL */ `
  query ListSellwichInventoryProducts(
    $filter: TableSellwichInventoryProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichInventoryProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        itemNumber
        category
        parentCategory
        skuCode
        spuNumber
        isDiscontinued
        updatedAt
        url
        categoryString
        topCategory
        productName
        supplier
        brand
        variationTheme1
        variationValue1
        variationTheme2
        variationValue2
        msrp
        map
        dropshippingPrice
        inventoryQuantity
        inventoryLocation
        shippingMethod
        shipTo
        shipNotTo
        shippingEstimateCost
        pickupPriceWithPrepaidShippingLabel
        promotionFlag
        salePrice
        salePriceForPickup
        promotionStartDatePST
        promotionEndDatePST
        productVideo
        additionalResources
        productImages
        description
        descriptionHTML
        upc
        asin
        shippingLimitations
        prohibitedMarketplace
        processingBusinessDays
        returnAndRefundPolicy
        packagingSizeLength
        packagingSizeWidth
        packagingSizeHeight
        packagingSizeUnit
        packagingWeight
        packagingWeightUnit
        productSizeLength
        productSizeWidth
        productSizeHeight
        productSizeUnit
        productWeight
        productWeightUnit
        parameters
      }
      nextToken
    }
  }
`;
export const querySellwichInventoryProductsBySkuCodeIndex = /* GraphQL */ `
  query QuerySellwichInventoryProductsBySkuCodeIndex(
    $skuCode: String!
    $first: Int
    $after: String
  ) {
    querySellwichInventoryProductsBySkuCodeIndex(
      skuCode: $skuCode
      first: $first
      after: $after
    ) {
      items {
        itemNumber
        category
        parentCategory
        skuCode
        spuNumber
        isDiscontinued
        updatedAt
        url
        categoryString
        topCategory
        productName
        supplier
        brand
        variationTheme1
        variationValue1
        variationTheme2
        variationValue2
        msrp
        map
        dropshippingPrice
        inventoryQuantity
        inventoryLocation
        shippingMethod
        shipTo
        shipNotTo
        shippingEstimateCost
        pickupPriceWithPrepaidShippingLabel
        promotionFlag
        salePrice
        salePriceForPickup
        promotionStartDatePST
        promotionEndDatePST
        productVideo
        additionalResources
        productImages
        description
        descriptionHTML
        upc
        asin
        shippingLimitations
        prohibitedMarketplace
        processingBusinessDays
        returnAndRefundPolicy
        packagingSizeLength
        packagingSizeWidth
        packagingSizeHeight
        packagingSizeUnit
        packagingWeight
        packagingWeightUnit
        productSizeLength
        productSizeWidth
        productSizeHeight
        productSizeUnit
        productWeight
        productWeightUnit
        parameters
      }
      nextToken
    }
  }
`;
export const querySellwichInventoryProductsByCategoryIndex = /* GraphQL */ `
  query QuerySellwichInventoryProductsByCategoryIndex(
    $category: String!
    $first: Int
    $after: String
  ) {
    querySellwichInventoryProductsByCategoryIndex(
      category: $category
      first: $first
      after: $after
    ) {
      items {
        itemNumber
        category
        parentCategory
        skuCode
        spuNumber
        isDiscontinued
        updatedAt
        url
        categoryString
        topCategory
        productName
        supplier
        brand
        variationTheme1
        variationValue1
        variationTheme2
        variationValue2
        msrp
        map
        dropshippingPrice
        inventoryQuantity
        inventoryLocation
        shippingMethod
        shipTo
        shipNotTo
        shippingEstimateCost
        pickupPriceWithPrepaidShippingLabel
        promotionFlag
        salePrice
        salePriceForPickup
        promotionStartDatePST
        promotionEndDatePST
        productVideo
        additionalResources
        productImages
        description
        descriptionHTML
        upc
        asin
        shippingLimitations
        prohibitedMarketplace
        processingBusinessDays
        returnAndRefundPolicy
        packagingSizeLength
        packagingSizeWidth
        packagingSizeHeight
        packagingSizeUnit
        packagingWeight
        packagingWeightUnit
        productSizeLength
        productSizeWidth
        productSizeHeight
        productSizeUnit
        productWeight
        productWeightUnit
        parameters
      }
      nextToken
    }
  }
`;
export const querySellwichInventoryProductsBySpuNumberIndex = /* GraphQL */ `
  query QuerySellwichInventoryProductsBySpuNumberIndex(
    $spuNumber: String!
    $first: Int
    $after: String
  ) {
    querySellwichInventoryProductsBySpuNumberIndex(
      spuNumber: $spuNumber
      first: $first
      after: $after
    ) {
      items {
        itemNumber
        category
        parentCategory
        skuCode
        spuNumber
        isDiscontinued
        updatedAt
        url
        categoryString
        topCategory
        productName
        supplier
        brand
        variationTheme1
        variationValue1
        variationTheme2
        variationValue2
        msrp
        map
        dropshippingPrice
        inventoryQuantity
        inventoryLocation
        shippingMethod
        shipTo
        shipNotTo
        shippingEstimateCost
        pickupPriceWithPrepaidShippingLabel
        promotionFlag
        salePrice
        salePriceForPickup
        promotionStartDatePST
        promotionEndDatePST
        productVideo
        additionalResources
        productImages
        description
        descriptionHTML
        upc
        asin
        shippingLimitations
        prohibitedMarketplace
        processingBusinessDays
        returnAndRefundPolicy
        packagingSizeLength
        packagingSizeWidth
        packagingSizeHeight
        packagingSizeUnit
        packagingWeight
        packagingWeightUnit
        productSizeLength
        productSizeWidth
        productSizeHeight
        productSizeUnit
        productWeight
        productWeightUnit
        parameters
      }
      nextToken
    }
  }
`;
export const querySellwichInventoryProductsByParentCategoryIndex = /* GraphQL */ `
  query QuerySellwichInventoryProductsByParentCategoryIndex(
    $parentCategory: String!
    $first: Int
    $after: String
  ) {
    querySellwichInventoryProductsByParentCategoryIndex(
      parentCategory: $parentCategory
      first: $first
      after: $after
    ) {
      items {
        itemNumber
        category
        parentCategory
        skuCode
        spuNumber
        isDiscontinued
        updatedAt
        url
        categoryString
        topCategory
        productName
        supplier
        brand
        variationTheme1
        variationValue1
        variationTheme2
        variationValue2
        msrp
        map
        dropshippingPrice
        inventoryQuantity
        inventoryLocation
        shippingMethod
        shipTo
        shipNotTo
        shippingEstimateCost
        pickupPriceWithPrepaidShippingLabel
        promotionFlag
        salePrice
        salePriceForPickup
        promotionStartDatePST
        promotionEndDatePST
        productVideo
        additionalResources
        productImages
        description
        descriptionHTML
        upc
        asin
        shippingLimitations
        prohibitedMarketplace
        processingBusinessDays
        returnAndRefundPolicy
        packagingSizeLength
        packagingSizeWidth
        packagingSizeHeight
        packagingSizeUnit
        packagingWeight
        packagingWeightUnit
        productSizeLength
        productSizeWidth
        productSizeHeight
        productSizeUnit
        productWeight
        productWeightUnit
        parameters
      }
      nextToken
    }
  }
`;
export const getSellwichShop = /* GraphQL */ `
  query GetSellwichShop($name: String!) {
    getSellwichShop(name: $name) {
      name
      userId
      timestamp
      displayName
      domainName
      subDomainName
      templateName
      templateParameters
      status
    }
  }
`;
export const listSellwichShops = /* GraphQL */ `
  query ListSellwichShops(
    $filter: TableSellwichShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        userId
        timestamp
        displayName
        domainName
        subDomainName
        templateName
        templateParameters
        status
      }
      nextToken
    }
  }
`;
export const querySellwichShopsByUserIdIndex = /* GraphQL */ `
  query QuerySellwichShopsByUserIdIndex(
    $userId: String!
    $first: Int
    $after: String
  ) {
    querySellwichShopsByUserIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
      items {
        name
        userId
        timestamp
        displayName
        domainName
        subDomainName
        templateName
        templateParameters
        status
      }
      nextToken
    }
  }
`;
export const getSellwichShopProduct = /* GraphQL */ `
  query GetSellwichShopProduct($productId: String!, $shopName: String!) {
    getSellwichShopProduct(productId: $productId, shopName: $shopName) {
      productId
      shopName
      spuNumber
      salePrice
      sellwichPrice
      customDescription
      customImages
      customVideos
      defaultProductVariant
      productVariants
      shippingCost
    }
  }
`;
export const listSellwichShopProducts = /* GraphQL */ `
  query ListSellwichShopProducts(
    $filter: TableSellwichShopProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichShopProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productId
        shopName
        spuNumber
        salePrice
        sellwichPrice
        customDescription
        customImages
        customVideos
        defaultProductVariant
        productVariants
        shippingCost
      }
      nextToken
    }
  }
`;
export const querySellwichShopProductsByShopNameIndex = /* GraphQL */ `
  query QuerySellwichShopProductsByShopNameIndex(
    $shopName: String!
    $first: Int
    $after: String
  ) {
    querySellwichShopProductsByShopNameIndex(
      shopName: $shopName
      first: $first
      after: $after
    ) {
      items {
        productId
        shopName
        spuNumber
        salePrice
        sellwichPrice
        customDescription
        customImages
        customVideos
        defaultProductVariant
        productVariants
        shippingCost
      }
      nextToken
    }
  }
`;
export const getSellwichShopper = /* GraphQL */ `
  query GetSellwichShopper($email: String!) {
    getSellwichShopper(email: $email) {
      email
      accessCode
      name
      phoneNumber
      shippingAddress
      referralCode
      timestamp
    }
  }
`;
export const listSellwichShoppers = /* GraphQL */ `
  query ListSellwichShoppers(
    $filter: TableSellwichShopperFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichShoppers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        accessCode
        name
        phoneNumber
        shippingAddress
        referralCode
        timestamp
      }
      nextToken
    }
  }
`;
export const querySellwichShoppersByAccessCodeIndex = /* GraphQL */ `
  query QuerySellwichShoppersByAccessCodeIndex(
    $accessCode: String!
    $first: Int
    $after: String
  ) {
    querySellwichShoppersByAccessCodeIndex(
      accessCode: $accessCode
      first: $first
      after: $after
    ) {
      items {
        email
        accessCode
        name
        phoneNumber
        shippingAddress
        referralCode
        timestamp
      }
      nextToken
    }
  }
`;
export const getSellwichOrder = /* GraphQL */ `
  query GetSellwichOrder($orderNumber: String!) {
    getSellwichOrder(orderNumber: $orderNumber) {
      orderNumber
      shopName
      stripeCustomerId
      shopperEmail
      shopperName
      orderTotal
      items
      shippingAddress
      trackingNumbers
      referralCode
      lastestPaymentTime
      paymentFailedTime
      paymentDisputeTime
      timestamp
      shopDisplayName
      dobaOrderPlacedTime
    }
  }
`;
export const listSellwichOrders = /* GraphQL */ `
  query ListSellwichOrders(
    $filter: TableSellwichOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        orderNumber
        shopName
        stripeCustomerId
        shopperEmail
        shopperName
        orderTotal
        items
        shippingAddress
        trackingNumbers
        referralCode
        lastestPaymentTime
        paymentFailedTime
        paymentDisputeTime
        timestamp
        shopDisplayName
        dobaOrderPlacedTime
      }
      nextToken
    }
  }
`;
export const querySellwichOrdersByShopNameIndex = /* GraphQL */ `
  query QuerySellwichOrdersByShopNameIndex(
    $shopName: String!
    $first: Int
    $after: String
  ) {
    querySellwichOrdersByShopNameIndex(
      shopName: $shopName
      first: $first
      after: $after
    ) {
      items {
        orderNumber
        shopName
        stripeCustomerId
        shopperEmail
        shopperName
        orderTotal
        items
        shippingAddress
        trackingNumbers
        referralCode
        lastestPaymentTime
        paymentFailedTime
        paymentDisputeTime
        timestamp
        shopDisplayName
        dobaOrderPlacedTime
      }
      nextToken
    }
  }
`;
export const querySellwichOrdersByStripeCustomerIdIndex = /* GraphQL */ `
  query QuerySellwichOrdersByStripeCustomerIdIndex(
    $stripeCustomerId: String!
    $first: Int
    $after: String
  ) {
    querySellwichOrdersByStripeCustomerIdIndex(
      stripeCustomerId: $stripeCustomerId
      first: $first
      after: $after
    ) {
      items {
        orderNumber
        shopName
        stripeCustomerId
        shopperEmail
        shopperName
        orderTotal
        items
        shippingAddress
        trackingNumbers
        referralCode
        lastestPaymentTime
        paymentFailedTime
        paymentDisputeTime
        timestamp
        shopDisplayName
        dobaOrderPlacedTime
      }
      nextToken
    }
  }
`;
export const getSellwichShopSale = /* GraphQL */ `
  query GetSellwichShopSale(
    $shopName: String!
    $itemNumberAndOrderNumber: String!
  ) {
    getSellwichShopSale(
      shopName: $shopName
      itemNumberAndOrderNumber: $itemNumberAndOrderNumber
    ) {
      itemNumberAndOrderNumber
      shopName
      itemNumber
      orderNumber
      quantity
      salePrice
      sellwichPrice
      timestamp
    }
  }
`;
export const listSellwichShopSales = /* GraphQL */ `
  query ListSellwichShopSales(
    $filter: TableSellwichShopSaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichShopSales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        itemNumberAndOrderNumber
        shopName
        itemNumber
        orderNumber
        quantity
        salePrice
        sellwichPrice
        timestamp
      }
      nextToken
    }
  }
`;
export const querySellwichShopSalesByItemNumberIndex = /* GraphQL */ `
  query QuerySellwichShopSalesByItemNumberIndex(
    $itemNumber: String!
    $first: Int
    $after: String
  ) {
    querySellwichShopSalesByItemNumberIndex(
      itemNumber: $itemNumber
      first: $first
      after: $after
    ) {
      items {
        itemNumberAndOrderNumber
        shopName
        itemNumber
        orderNumber
        quantity
        salePrice
        sellwichPrice
        timestamp
      }
      nextToken
    }
  }
`;
export const querySellwichShopSalesByShopNameIndex = /* GraphQL */ `
  query QuerySellwichShopSalesByShopNameIndex(
    $shopName: String!
    $first: Int
    $after: String
  ) {
    querySellwichShopSalesByShopNameIndex(
      shopName: $shopName
      first: $first
      after: $after
    ) {
      items {
        itemNumberAndOrderNumber
        shopName
        itemNumber
        orderNumber
        quantity
        salePrice
        sellwichPrice
        timestamp
      }
      nextToken
    }
  }
`;
export const getSellwichEvent = /* GraphQL */ `
  query GetSellwichEvent($sessionId: String!, $timestamp: Float!) {
    getSellwichEvent(sessionId: $sessionId, timestamp: $timestamp) {
      sessionId
      timestamp
      userId
      message
      type
      source
      importance
    }
  }
`;
export const listSellwichEvents = /* GraphQL */ `
  query ListSellwichEvents(
    $filter: TableSellwichEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        sessionId
        timestamp
        userId
        message
        type
        source
        importance
      }
      nextToken
    }
  }
`;
export const querySellwichEventsByUserIdIndex = /* GraphQL */ `
  query QuerySellwichEventsByUserIdIndex(
    $userId: String!
    $first: Int
    $after: String
  ) {
    querySellwichEventsByUserIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
      items {
        sessionId
        timestamp
        userId
        message
        type
        source
        importance
      }
      nextToken
    }
  }
`;
export const getSellwichUser = /* GraphQL */ `
  query GetSellwichUser($id: String!) {
    getSellwichUser(id: $id) {
      id
      email
      hasCancelled
      hasGraduatedFromAcademy
      hasPublishedToFacebook
      hasSetupShop
      hasSignedUpForAcademy
      isInTrial
      referralCode
      stripeCustomerId
      firstname
      lastname
      middlename
      phoneNumber
      timestamp
      affiliateCode
      shopIds
      whatsappNumber
      facebookAccessToken
      facebookUsername
      facebookTokenExpiresIn
      facebookTokenExpirationTime
      trialStartTime
      academyStartTime
      academyGraduationTime
      cancelTime
      lastestPaymentTime
      paymentFailedTime
      profilePicture
      stripeSubscriptionId
      setupShopTime
    }
  }
`;
export const listSellwichUsers = /* GraphQL */ `
  query ListSellwichUsers(
    $filter: TableSellwichUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellwichUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByHasCancelledIndex = /* GraphQL */ `
  query QuerySellwichUsersByHasCancelledIndex(
    $hasCancelled: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByHasCancelledIndex(
      hasCancelled: $hasCancelled
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByHasPublishedToFacebookIndex = /* GraphQL */ `
  query QuerySellwichUsersByHasPublishedToFacebookIndex(
    $hasPublishedToFacebook: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByHasPublishedToFacebookIndex(
      hasPublishedToFacebook: $hasPublishedToFacebook
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByIsInTrialIndex = /* GraphQL */ `
  query QuerySellwichUsersByIsInTrialIndex(
    $isInTrial: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByIsInTrialIndex(
      isInTrial: $isInTrial
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByHasGraduatedFromAcademyIndex = /* GraphQL */ `
  query QuerySellwichUsersByHasGraduatedFromAcademyIndex(
    $hasGraduatedFromAcademy: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByHasGraduatedFromAcademyIndex(
      hasGraduatedFromAcademy: $hasGraduatedFromAcademy
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByReferralCodeIndex = /* GraphQL */ `
  query QuerySellwichUsersByReferralCodeIndex(
    $referralCode: String!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByReferralCodeIndex(
      referralCode: $referralCode
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByHasSetupShopIndex = /* GraphQL */ `
  query QuerySellwichUsersByHasSetupShopIndex(
    $hasSetupShop: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByHasSetupShopIndex(
      hasSetupShop: $hasSetupShop
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByHasSignedUpForAcademyIndex = /* GraphQL */ `
  query QuerySellwichUsersByHasSignedUpForAcademyIndex(
    $hasSignedUpForAcademy: Int!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByHasSignedUpForAcademyIndex(
      hasSignedUpForAcademy: $hasSignedUpForAcademy
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByStripeCustomerIdIndex = /* GraphQL */ `
  query QuerySellwichUsersByStripeCustomerIdIndex(
    $stripeCustomerId: String!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByStripeCustomerIdIndex(
      stripeCustomerId: $stripeCustomerId
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;
export const querySellwichUsersByEmailIndex = /* GraphQL */ `
  query QuerySellwichUsersByEmailIndex(
    $email: String!
    $first: Int
    $after: String
  ) {
    querySellwichUsersByEmailIndex(
      email: $email
      first: $first
      after: $after
    ) {
      items {
        id
        email
        hasCancelled
        hasGraduatedFromAcademy
        hasPublishedToFacebook
        hasSetupShop
        hasSignedUpForAcademy
        isInTrial
        referralCode
        stripeCustomerId
        firstname
        lastname
        middlename
        phoneNumber
        timestamp
        affiliateCode
        shopIds
        whatsappNumber
        facebookAccessToken
        facebookUsername
        facebookTokenExpiresIn
        facebookTokenExpirationTime
        trialStartTime
        academyStartTime
        academyGraduationTime
        cancelTime
        lastestPaymentTime
        paymentFailedTime
        profilePicture
        stripeSubscriptionId
        setupShopTime
      }
      nextToken
    }
  }
`;

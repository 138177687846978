import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './StripeCheckout.css';
import { Constants } from '../utils/Constants';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

interface CheckoutProps {
    email: string;
    name: string;
    amount: number;
    frequency: string;
    orderNumber: string;
    shopName: string;
}

const StripeCheckout: React.FC<CheckoutProps> = ({ email, name, amount, frequency, orderNumber, shopName }) => {

    // TODO figure out why discover testing number don't fire carge.succeeded event
    // And figure out whether it's only for testing number, not in PROD

    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const appearance = { theme: 'stripe' };
    const options = { clientSecret };

    const payButtonText = frequency === 'montly' ? 'Start trial' : 'Pay';

    useEffect(() => {
        if (!stripe) {
            return;
        }
        // const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
            return;
        }
        stripe.retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                switch (paymentIntent?.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            }).catch(err => {
                EventData.logEvent(`Error happened getting payment intent. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'StripeCheckout', 1);
            });
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            //console.log('!stripe || !elements');
            return;
        }

        setIsLoading(true);


        if (frequency === Constants.PAYMENT_FREQUENCY.MONTHLY) {
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    // TODO create a success page where to redirect the user after the payment.
                    return_url: `https://www.sellwich.com/shop/dashboard?sub=1`,
                }
            });
            handleError(error);
        } else if ( frequency === Constants.PAYMENT_FREQUENCY.ONCE) {
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // TODO create a success page where to redirect the user after the payment.
                    return_url: `https://www.sellwich.com/${shopName}/shopping/checkout?orderNumber=${orderNumber}`,
                }
            });
            handleError(error);
        }

        setIsLoading(false);
    };

    const handleError = (error: any) => {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        EventData.logEvent(`Stripe payment error happened. Error: ${error}`, EventType.ERROR, 'StripeCheckout', 1);
        if (error?.type === "card_error" || error?.type === "validation_error") {
            // @ts-ignore
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }
    }

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                <button disabled={isLoading || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : payButtonText}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    );
};

export default StripeCheckout;



import React, { useState } from 'react';
import { IonContent, IonButton, IonHeader, IonFooter, IonNote, IonToolbar, IonModal, IonItem, IonText, IonLabel, IonInput,
    IonSelect, IonSelectOption, useIonToast } from '@ionic/react';
import { Numbers } from '../utils/Numbers';
import { Forms } from '../utils/Forms';
import { ToastMessage } from '../utils/ToastMessage';
import { ShopProduct } from '../models/ShopProduct';
import { ShopProductData } from '../data/ShopProductData';

import './Footer.css';
import { InventoryProduct } from '../models/InventoryProduct';
import { Shop } from '../models/Shop';

interface AddProductToShopModalProps {
    selectedProduct: InventoryProduct;
    shops: Array<Shop>;
    markProductsInShops: Function;
    isOpen: boolean;
    toggleIsOpen: Function;
}
export const AddProductToShopModal: React.FC<AddProductToShopModalProps> = ({ selectedProduct, shops, markProductsInShops, isOpen, toggleIsOpen }) => {

    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    const [niOpen, setNiOpen] = useState(isOpen);
    const sellwichPrice = parseFloat(selectedProduct?.dropshippingPrice.toString());
    const salePrice = Numbers.roundToDecimals(sellwichPrice + (sellwichPrice * 0.12));
    const [selectedShopId, setSelectedShopId] = useState('');

    const shopIdChanged = (evt: any) => {
        setSelectedShopId(evt.detail.value);
        if (evt.detail.value !== '') {
            Forms.showErrorMessage(false, 'shopErrorMessage');
        }
    }

    const saveSelectedProduct = (evt: any) => {
        if (selectedShopId === '') {
            Forms.showErrorMessage(true, 'shopErrorMessage');
            return;
        }
        const shopProduct = new ShopProduct({ shopName: selectedShopId, inventoryProduct: selectedProduct });
        shopProduct.salePrice = salePrice;
        ShopProductData.createShopProduct(shopProduct)
            .then((resp) => {
                if (resp === null) {
                    toastMessage.showErrorMessage('Failed to add Product. Refresh and try again.');
                } else {
                    setNiOpen(false);
                    toastMessage.showSuccessMessage(`Product addedd to shop.`);
                    markProductsInShops();
                }
            })
    }

    const cancelClicked = () => {
        setNiOpen(false);
        setTimeout(() => {
            toggleIsOpen();
        }, 500);
    }

    return (
        <IonModal isOpen={niOpen} onWillDismiss={cancelClicked}>
            <IonContent className='ion-padding'>
                <IonHeader>
                    <IonToolbar className='ion-text-center'>
                        <h5>Add product to a shop</h5>
                    </IonToolbar>
                </IonHeader>
                <div className='model-main-content ion-justify-content-center ion-text-center'>
                    <IonItem lines='none' className='model-item'>
                        <IonText>Sellwich price: ${sellwichPrice}</IonText>
                    </IonItem>
                    <IonItem lines='none' fill='outline' className='model-item'>
                        <IonLabel>Enter your price: $</IonLabel>
                        <IonInput type='number' value={salePrice}></IonInput>
                    </IonItem>
                    <IonItem lines='none' className='model-item'>
                        <IonText>Your profit: <IonText color='success'><strong>${Numbers.roundToDecimals(salePrice - sellwichPrice)}</strong></IonText></IonText>
                    </IonItem>
                    <IonItem lines='none' fill='outline' className='model-item'>
                        <IonLabel>Select shop:</IonLabel>
                        <IonSelect value={selectedShopId} onIonChange={shopIdChanged} placeholder='Select shop'>
                            {shops && shops.map((shop, index) => {
                                return (
                                    <IonSelectOption key={index} value={shop.name}>{shop.displayName}</IonSelectOption>
                                )
                            })}
                        </IonSelect>
                    </IonItem>
                    <IonNote id='shopErrorMessage' className='error-message'>Select a shop to add the product to</IonNote>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar className='ion-padding' id='model-footer-toolbar'>
                    <IonButton fill='outline' color='medium' onClick={cancelClicked}>Cancel</IonButton>
                    <IonButton onClick={saveSelectedProduct} slot='end' fill='solid' color='primary'>Add</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    );
};
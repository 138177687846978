import { API, graphqlOperation } from 'aws-amplify';
import { getSellwichInventoryProduct, listSellwichInventoryProducts,
    querySellwichInventoryProductsByParentCategoryIndex, querySellwichInventoryProductsBySpuNumberIndex,
    querySellwichInventoryProductsByCategoryIndex } from './../graphql/queries';

import { InventoryProduct } from '../models/InventoryProduct';
import FetchData from './FetchData';
import { Constants } from '../utils/Constants';
import { EventData } from './EventData';
import { EventType } from '../models/Event';

export const InventoryProductData = {

    getInventoryProduct: async (itemNumber: string ,spuNumber: string) => {
        try {
            const inventProd = await API.graphql(graphqlOperation(getSellwichInventoryProduct, { itemNumber: itemNumber, spuNumber: spuNumber }));
            // @ts-ignore
            return inventProd['data']['getSellwichInventoryProduct'] as InventoryProduct;
        } catch (err) {
            EventData.logEvent(`Error get inventory product, itemNumber ${itemNumber}, spuNumber: ${spuNumber}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
            return null;
        }
    },

    queryInventoryProductByCategory: async (category: string) => {
        try {
            // TODO gather all subcategories and loop thru them, otherwise we'd get products on one level
            const inventProds = await API.graphql(graphqlOperation(querySellwichInventoryProductsByCategoryIndex, { category: category }));
            // @ts-ignore
            return inventProds['data']['querySellwichInventoryProductsByCategoryIndex']['items'] as Array<InventoryProduct>;
        } catch (err) {
            EventData.logEvent(`Error querying inventory products by category: ${category}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
            return null;
        }
    },

    queryInventoryProductByParentCategory: async (parentCat: string) => {
        try {
            // TODO gather all subcategories and loop thru them, otherwise we'd get products on one level
            const inventProds = await API.graphql(graphqlOperation(querySellwichInventoryProductsByParentCategoryIndex, { parentCategory: parentCat }));
            // @ts-ignore
            return inventProds['data']['querySellwichInventoryProductsByParentCategoryIndex']['items'] as Array<InventoryProduct>;
        } catch (err) {
            EventData.logEvent(`Error querying inventory products by parentCategory: ${parentCat}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
            return null;
        }
    },

    queryInventoryProductBySpuNumber: async (spuNumber: string) => {
        try {
            const inventProds = await API.graphql(graphqlOperation(querySellwichInventoryProductsBySpuNumberIndex, { spuNumber: spuNumber }));
            // @ts-ignore
            return inventProds['data']['querySellwichInventoryProductsBySpuNumberIndex']['items'] as Array<InventoryProduct>;
        } catch (err) {
            EventData.logEvent(`Error querying inventory products by spuNumber: ${spuNumber}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
            return null;
        }
    },

    listInventoryProducts: async (limit=40, nextToken='') => {
        try {
            const productsData = await API.graphql(graphqlOperation(listSellwichInventoryProducts,
                { limit: limit, nextToken: nextToken } ));
            // @ts-ignore
            const nxtToken = productsData['data']['listSellwichInventoryProducts']['nextToken'];
            // @ts-ignore
            const items = productsData['data']['listSellwichInventoryProducts']['items'] as Array<InventoryProduct>;
            return { items: items, nextToken: nxtToken};
        } catch (err) {
            EventData.logEvent(`Error listing shop products. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
            return null;
        }
    },

    transformSearchResults: (results: any) => {
        const products = new Array<InventoryProduct>();
        results.forEach((element: any) => {
            const prod = new InventoryProduct();
            prod.productName = element.product_name[0];
            prod.spuNumber = element.spu_no[0];
            prod.itemNumber = element.item_no_[0];
            prod.dropshippingPrice = parseFloat(element.dropshipping_price__us__[0]);
            prod.shippingEstimateCost = parseFloat(element.estimate_shipping_cost__us__[0]);
            prod.inventoryQuantity = parseFloat(element.inventory_qty[0]);
            prod.productImages = [];
            element.product_images_1.forEach((el:any) => {
                // const parsed = JSON.parse(el);
                prod.productImages.push(el);
            });
            prod.inventoryLocation = element.inventory_location[0];
            products.push(prod);
        });
        return products;
    },

    searchInventoryProducts: async (searchTerm: string, start=0,
        returnFields ='product_name,spu_no,item_no_,inventory_qty,dropshipping_price__us__,estimate_shipping_cost__us__,inventory_location,product_images_1') => {
        return FetchData.post(Constants.INVENTORY_PRODUCTS_SEARCH_URL,
            { query: searchTerm, return: returnFields, size: 100, start: start, sort: 'item_no_ desc' })
            .then(resp => {
                return InventoryProductData.transformSearchResults(resp);
            }).catch(err => {
                EventData.logEvent(`Error searching. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryProductData', 1);
                return null;
            });
    },
}
import {
    IonButton, IonInput, IonLabel, IonGrid, IonRow, IonCol, IonImg, IonItem, useIonToast, IonText, IonList, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonInfiniteScroll,
    IonInfiniteScrollContent, IonSplitPane, IonPage, IonContent, IonHeader, IonToolbar, IonMenuButton, IonButtons, IonTitle } from '@ionic/react';

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ShopData } from '../data/ShopData';
import { Strings } from '../utils/Strings';
import { ToastMessage } from '../utils/ToastMessage';

import './MyShop.css';
import { ShopProduct } from '../models/ShopProduct';
import { LocalStorageData } from '../data/LocalStorageData';
import { ShopProductData } from '../data/ShopProductData';
import DashboardMenu from '../components/DashboardMenu';
import { Shop } from '../models/Shop';
import { User } from '../models/User';
import { UserData } from '../data/UserData';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

interface MyShopProps {
    shopId: string,
    setTitle: Function
}

let nextToken = '';
let allProductsLoaded = false;

const MyShop: React.FC = () => {

    const { shopName } = useParams<{ shopName: string; }>();

    const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    const [products, setProducts] = useState(new Array<ShopProduct>());
    const [shop, setShop] = useState<Shop>();
    const [user, setUser] = useState<User>();
    const [title, setTitle] = useState<string>();
    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    // TODO fetch product sales and profit for each product
    // TODO add a way to change and update a template.

    const menu = useRef();

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        ShopData.getShopByName(shopName)
            .then((shop) => {
                if (shop === null) {
                    EventData.logEvent(`There no such shop as ${shopName}.`, EventType.ERROR, 'MyShopPage');
                    toastMessage.showErrorMessage('Error getting the shop.');
                } else {
                    LocalStorageData.getLoggedInUserId()
                        .then((userId) => {
                            if (shop.userId === userId) {
                                getUser(userId);
                                setTitle(shop.displayName + ': products');
                                // @ts-ignore
                                setShop(shop);
                                ShopProductData.queryShopProductsByShopName(shopName)
                                    .then((results) => {
                                        nextToken = results?.nextToken;
                                        if (results?.shopProds) {
                                            setProducts(results.shopProds);
                                        }
                                    });
                            }
                        })
                }
            });
        EventData.logEvent(`MyShop page visited`, EventType.MYSHOP_PAGE_VISIT, 'MyShopPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    const removeProduct = (e: any) => {
        const prodId = e.target.id;
        const idx = e.target.dataset.index;
        products.splice(idx, 1);
        setProducts([...products]);
        // @ts-ignore
        ShopProductData.removeShopProduct(shop?.name,prodId)
            .then(resp => {
                if (resp === null) {
                    toastMessage.showErrorMessage('Failed to remove item. Please, refresh and try again.');
                    EventData.logEvent(`Failed to remove the product.`, EventType.ERROR, 'MyShopPage', 1);
                } else {
                    toastMessage.showSuccessMessage('Item removed.');
                }
            });
    }

    const saveProductPrice = (evt: any) => {
        // save products new price to the DB
        const newSalePrice = parseFloat(evt.target.value);
        if (isNaN(newSalePrice)) {
            return;
        }
        const idx = evt.target.dataset.index;
        if (idx >= 0 && idx < products.length && !isNaN(newSalePrice)) {
            const product = products[idx];
            ShopProductData.updateProduct(product.productId, product.shopName, { salePrice: newSalePrice})
                .then(resp => {
                    if (resp === null) {
                        toastMessage.showErrorMessage('Failed to update the product\`s sale price.');
                        EventData.logEvent(`Failed to updated salePrice.`, EventType.ERROR, 'MyShopPage');
                    } else {
                        toastMessage.showSuccessMessage(`Product\'s sale price updated to $${newSalePrice}`)
                    }
                })
        }
    }

    const updateProductPrice = (evt: any) => {
        // update the local product price copy
        const newSalePrice = parseFloat(evt.target.value);
        if (isNaN(newSalePrice)) {
            return;
        }
        const idx = evt.target.dataset.index;
        const sellwichPrice = parseFloat(evt.target.dataset.sellwichPrice);
        if (idx >= 0 && idx < products.length && !isNaN(newSalePrice)) {
            // TODO test the following works
            if (newSalePrice > sellwichPrice) {
                products[idx].salePrice = newSalePrice;
                setProducts([...products]);
            } else {
                toastMessage.showErrorMessage('Your sale price has to be greater than Sellwich price.');
            }
        }
    }

    const getMoreShopProducts = (evt: any) => {

        if (allProductsLoaded) {
            if (evt) {
                evt.target.complete();
            }
            return;
        }
        // @ts-ignore
        ShopProductData.queryShopProductsByShopName(shop?.name, nextToken)
            .then(results => {
                if (results) {
                    nextToken = results.nextToken;
                    if (!results.shopProds || results.shopProds.length == 0 || results.shopProds[0].productId === products[0].productId) {
                        allProductsLoaded = true;
                    } else {
                        setProducts([...products, ...results.shopProds]);
                    }
                    if (evt) {
                        evt.target.complete();
                    }
                } else {
                    toastMessage.showErrorMessage('An error happened. Please, refresh and try again.');
                    // @ts-ignore
                    throw (`Failed to get shop product for ${shop?.name}`);
                }
            });
    }

    const getShopButtons = () => {
        return (<>
            <IonButton href={'/product/search'} color='medium' fill='outline'>
                Add products
            </IonButton>
            <IonButton href={'/' + shopName} target='_blank' color='primary' fill='outline'>
                View
            </IonButton>
            {user && !user.isInTrial && !user.lastestPaymentTime &&
                <IonButton routerLink='/shop/benefits' color='warning' fill='solid' size='large'>
                    Start making money
                </IonButton>
            }
        </>);
    }

    const getUser = (userId: string) => {
        UserData.getUserById(userId)
            .then(user => {
                if (user) {
                    setUser(user);
                }
            })
    }

    return (
        <IonSplitPane contentId='main'>
            <DashboardMenu />
            <IonPage id='main'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <IonButtons slot='end'>
                            {shopName && getShopButtons()}
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">{title}</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <div id="my-shop-container">
                        {products.length === 0 &&
                            <div className='container'>This shop has no products. <a href='/product/search'>Add Products</a></div>
                        }
                        {products.length > 0 && desktopMediaQuery.matches &&
                            <IonGrid className='show-on-desktop'>
                                <IonRow className='ion-justify-content-center ion-text-center'>
                                    <IonCol><b></b></IonCol>
                                    <IonCol><b>Name</b></IonCol>
                                    <IonCol><b>Sellwich Price</b></IonCol>
                                    <IonCol><b>Your price</b></IonCol>
                                    <IonCol><b>Profit/unit</b></IonCol>
                                    <IonCol><b>Sales</b></IonCol>
                                    <IonCol><b>Revenue</b></IonCol>
                                    <IonCol><b></b></IonCol>
                                </IonRow>
                                {products.map((prod, index) => {
                                    return (
                                        <IonRow key={index} className='ion-justify-content-center ion-text-center my-shop-row'>
                                            <IonCol className='ion-padding'><a href={'/product/' + prod.defaultProductVariant.itemNumber + '/' + prod.spuNumber}><IonImg className='product-img' src={prod.defaultProductVariant.productImages[0]}></IonImg></a></IonCol>
                                            <IonCol><a href={'/product/' + prod.defaultProductVariant.itemNumber + '/' + prod.spuNumber} className='product-header-title my-shop-product-name'>{prod.defaultProductVariant.productName}</a></IonCol>
                                            <IonCol>${prod.sellwichPrice}</IonCol>
                                            <IonCol>
                                                <IonItem fill='outline'>
                                                    <IonLabel>$</IonLabel>
                                                    <IonInput data-index={index} onIonBlur={saveProductPrice} onIonChange={updateProductPrice} type='number' value={prod.salePrice}></IonInput>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol><IonText color={(prod.salePrice > prod.sellwichPrice) ? 'success' : 'danger'}><b>${Math.round((prod.salePrice - prod.sellwichPrice) * 100) / 100}</b></IonText></IonCol>
                                            <IonCol>4</IonCol>
                                            <IonCol>${Math.round((prod.salePrice * 4) * 100) / 100}</IonCol>
                                            <IonCol><IonButton color='danger' fill='outline' onClick={removeProduct} id={prod.productId} data-index={index}>Remove</IonButton></IonCol>
                                        </IonRow>)
                                })}
                            </IonGrid>
                        }
                        {products.length > 0 && mobileMediaQuery.matches &&
                            <IonList className='show-on-mobile'>
                                {products.map((prod, index) => {
                                    return (
                                        <IonCard key={index}>
                                            <span className='my-shop-product-name'><a href={'/product/' + prod.defaultProductVariant.itemNumber + '/' + prod.spuNumber} className='product-header-title'>{prod.defaultProductVariant.productName}</a></span>
                                            <a href={'/product/' + prod.defaultProductVariant.itemNumber + '/' + prod.spuNumber}><img className='product-img-mobile' src={prod.defaultProductVariant.productImages[0]} alt='Product image' loading="lazy"></img></a>
                                            <IonCardHeader>
                                                <IonCardSubtitle>Sales: 4</IonCardSubtitle>
                                                <IonCardSubtitle>Revenue: ${Math.round((prod.salePrice * 4) * 100) / 100}</IonCardSubtitle>
                                            </IonCardHeader>
                                            <IonCardContent>
                                                <IonItem>Sellwich price: ${prod.sellwichPrice}</IonItem>
                                                <IonItem fill='outline'>
                                                    <IonLabel position='floating'>Your price $</IonLabel>
                                                    <IonInput data-index={index} data-sellwichPrice={prod.sellwichPrice} onIonBlur={saveProductPrice} onIonChange={updateProductPrice} type='number' value={prod.salePrice}></IonInput>
                                                </IonItem>
                                                <IonItem lines='none'>Profit/unit: <IonText color={(prod.salePrice > prod.sellwichPrice) ? 'success' : 'danger'}> <strong>${Math.round((prod.salePrice - prod.sellwichPrice) * 100) / 100}</strong> </IonText></IonItem>
                                            </IonCardContent>
                                            <IonItem>
                                                <IonButton slot='end' fill='outline' color='danger' onClick={removeProduct} id={prod.productId} data-index={index}>Remove</IonButton>
                                            </IonItem>
                                        </IonCard>
                                    )
                                })}
                            </IonList>
                        }
                        <IonInfiniteScroll onIonInfinite={getMoreShopProducts}>
                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                </IonContent>
            </IonPage>
        </IonSplitPane>
    );
};

export default MyShop;

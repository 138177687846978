import { InventoryProduct } from "./InventoryProduct";

export class ShopProduct {

    constructor(params: any) {

        //shopName: string, inventProd: InventoryProduct
        if (params.shopName && params.inventoryProduct) {
            this.shopName = params.shopName;
            this.productId = params.inventoryProduct.spuNumber;
            this.spuNumber = params.inventoryProduct.spuNumber;
            this.shippingCost = params.inventoryProduct.shippingEstimateCost;
            this.salePrice = parseFloat(params.inventoryProduct.dropshippingPrice.toString());
            this.sellwichPrice = parseFloat(params.inventoryProduct.dropshippingPrice.toString());
            // By default set the salePrice to dropShipPrice + 12%, the user can change this late
            this.salePrice += Math.round(params.inventoryProduct.salePrice * 12) / 100;
            this.defaultProductVariant = params.inventoryProduct;
        }

        if (params.shopProductData) {
            this.shopName = params.shopProductData.shopName;
            this.productId = params.shopProductData.spuNumber;
            this.spuNumber = params.shopProductData.spuNumber;
            this.salePrice = params.shopProductData.salePrice;
            this.shippingCost = params.shopProductData.shippingCost;
            this.sellwichPrice = params.shopProductData.sellwichPrice;
            this.customDescription = params.shopProductData.customDescription;
            this.defaultProductVariant = JSON.parse(params.shopProductData.defaultProductVariant);
            this.customImages = JSON.parse(params.shopProductData.customImages);
            this.customVideos = JSON.parse(params.shopProductData.customVideos);
            this.defaultProductVariant = JSON.parse(params.shopProductData.defaultProductVariant);
        }
    }
    setCustomerPrice = (price: number) => {

        this.salePrice = price;
    }
    setProductVariants = (variants: Array<InventoryProduct>) => {

        this.productVariants = variants;
    }
    productId!: string;
    shopName!: string;
    salePrice!: number;
    sellwichPrice!: number;
    customDescription!: string;
    customImages!: Array<string>;
    customVideos!: Array<string>;
    spuNumber!: string;
    shippingCost!: number;
    defaultProductVariant!: InventoryProduct;
    productVariants!: Array<InventoryProduct>; // not to be stored in the DB
}
import React from 'react';
import { IonButton, IonButtons, IonFooter, IonIcon, IonToolbar } from '@ionic/react';
import { logoTwitter, logoFacebook, logoInstagram, logoTiktok, logoPinterest, logoYoutube, logoLinkedin, heart } from 'ionicons/icons';

import './Footer.css';

export const Footer: React.FC = () => (
    <>
        <IonFooter collapse="fade" id='footer' className='ion-padding'>
            <IonToolbar id='footer-toolbar'>
                <IonButtons className='ion-justify-content-center ion-text-center'>
                    <IonButton color='light' href='https://www.facebook.com/profile.php?id=100089474028163' target='blank'><IonIcon icon={logoFacebook}></IonIcon></IonButton>
                    <IonButton color='light' href='https://www.instagram.com/sell_wich/' target='blank'><IonIcon icon={logoInstagram}></IonIcon></IonButton>
                    <IonButton color='light'><IonIcon icon={logoYoutube}></IonIcon></IonButton>
                    <IonButton color='light' href='https://www.tiktok.com/@sellwich' target='blank'><IonIcon icon={logoTiktok}></IonIcon></IonButton>
                    <IonButton color='light'><IonIcon icon={logoPinterest}></IonIcon></IonButton>
                    <IonButton color='light' href='https://twitter.com/sell_wich' target='blank'><IonIcon icon={logoTwitter}></IonIcon></IonButton>
                    <IonButton color='light'><IonIcon icon={logoLinkedin}></IonIcon></IonButton>
                </IonButtons>
                <div id='with-luv' className='ion-justify-content-center ion-text-center'>Made with <IonIcon icon={heart} color='danger'></IonIcon>  in Austin</div>
                <IonButtons slot='end' className='show-on-desktop'>
                    <IonButton href='/faq' slot='end' color='light'>FAQ</IonButton>
                    <IonButton href='/terms-of-use' slot='end' color='light'>Terms of use</IonButton>
                    <IonButton href='/privacy-policy' slot='end' color='light'>Privacy Policy</IonButton>
                </IonButtons>
            </IonToolbar>
        </IonFooter>

    </>
);
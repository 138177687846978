import { IonCol, IonGrid, IonItem, IonList, IonRow, IonButton, IonIcon } from '@ionic/react';
import { useEffect, useState } from 'react';
import { shareSocial } from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { EventData } from '../data/EventData';
import { LocalStorageData } from '../data/LocalStorageData';
import { UserData } from '../data/UserData';
import { EventType } from '../models/Event';
import { Shop } from '../models/Shop';
import { User } from '../models/User';
import { ShopData  } from './../data/ShopData';

import './MySellwich.css';

interface MySellwichProps {
    setTitle: Function;
    sub: string;
}

const MySellwich: React.FC<MySellwichProps> = ({ setTitle, sub }) => {

    useEffect(() => {
        EventData.logEvent(`MySellwich page visited`, EventType.MY_SELLWICH_PAGE_VISIT, 'MySellwichComponent');
    }, []);

    setTitle('Sellwich Dashboard');

    const [shops, setShops] = useState<Array<Shop>>();
    const [user, setUser] = useState<User>();
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        LocalStorageData.getLoggedInUserId()
            .then((userId) => {
                if (userId) {
                    // @ts-ignore
                    ShopData.queryShopsByUserId(userId)
                        .then((shops) => {
                            if (shops) {
                                setShops(shops);
                            }
                        });
                    UserData.getUserById(userId)
                        .then(user => {
                            if (user) {
                                setUser(user);
                            }
                        });
                }
            });
        Share.canShare().then(can => {
            if (can.value === true) {
                setCanShare(can.value);
            }
        }).catch(err => {
            setCanShare(false);
            EventData.logEvent(`Error checking canShare. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'MySellwichComponent');
        });
    }, []);

    const share = (evt: any) => {
        Share.share({
            title: `Sellwich: a Way to Financial Freedom`,
            text: `Try Sellwich.com and start making passive income today.`,
            url: `https://sellwich.com/ref/${user?.affiliateCode}`,
            dialogTitle: `Sellwich.com: a tried and true way to make passive income online.`,
        }).then(resp => {
            // console.log('Shared successfully: ', resp);
        }).catch(err => {
            EventData.logEvent(`Error sharing refferal link. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ReferralsComponent', 1);
        });
    }

    return (
        <div className="ion-padding">
            <div className='ion-padding my-sellwich-container'>
                <IonGrid>
                    {user && (user?.firstname || user?.lastname) &&
                        <IonRow>
                            <IonCol>
                                <h5>{ user.firstname + ' ' + user.lastname }</h5>
                            </IonCol>
                        </IonRow>
                    }
                    <IonRow>
                        <IonCol>
                            Store Orders: <strong>0</strong>
                        </IonCol>
                        <IonCol>
                            Referral sales: <strong>$0</strong>
                        </IonCol>
                        <IonCol>
                            30 days income: <strong>$0</strong>
                        </IonCol>
                    </IonRow>
                    <IonRow></IonRow>
                    <IonRow id='affiliate-col'>
                        <IonCol>Your affiliate link:</IonCol>
                        <IonCol>{user && `https://www.sellwich.com/ref/${user.affiliateCode}`}</IonCol>
                        <IonCol>{canShare && <IonButton onClick={share} fill='outline' size="small"><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>}</IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <div className="progressContainer">
                <h4>Welcome to Sellwich!</h4>
                <p>Finish the following steps and start making money now.</p>
                <ul className="progress">
                    <li className={(shops && shops.length > 0) ? "progress__item progress__item--completed" : 'progress__item'}>
                        <p className="progress__title">Create shop</p>
                        <p className="progress__info">Get started and add products to your shop</p>
                    </li>
                    <li className={(shops && (user?.isInTrial || sub)) ? "progress__item progress__item--completed" : 'progress__item'}>
                        <p className="progress__title"><a href='/shop/benefits' className='steps-links'>Start free trial</a></p>
                        <p className="progress__info">Start a free 14 days trial</p>
                    </li>
                    <li className={(shops && user?.hasPublishedToFacebook) ? "progress__item progress__item--completed" : 'progress__item'}>
                        <p className="progress__title">Go social</p>
                        <p className="progress__info">Publish your shop to Facebook and Instagram</p>
                    </li>
                    <li className={(shops && user?.hasPublishedToFacebook) ? "progress__item progress__item--completed" : 'progress__item'}>
                        <p className="progress__title">Get paid</p>
                        <p className="progress__info">Start promoting and getting paid</p>
                    </li>
                </ul>
            </div>
            <div className='ion-padding my-sellwich-container'>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h5>Monthly Reports</h5>
                            <IonList>
                                <IonItem>

                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
};

export default MySellwich;

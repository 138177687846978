import ImportScripts from '../hooks/ImportScripts';
import { Constants } from './Constants';

export const Scripts = {
    loadLiveChat: () => {
        ImportScripts({ url: Constants.SCRIPTS.FACEBOOK_CHAT_JS });
        ImportScripts({ url: Constants.SCRIPTS.FACEBOOK_CHAT_JS_URL });
    },
    loadGoogleAnalytics: () => {
        ImportScripts({ url: Constants.SCRIPTS.GOOGLE_ANALYTICS_JS_URL });
        ImportScripts({ url: Constants.SCRIPTS.GOOGLE_ANALYTICS_JS });
    },
    loadFacebookPixel: () => {
        //ImportScripts({ url: Constants.SCRIPTS.FACEBOOK_PIXEL_URL});
        ImportScripts({ url: Constants.SCRIPTS.FACEBOOK_PIXEL});
    }
}

export class Test {

}
import { API, graphqlOperation } from 'aws-amplify';
import { getSellwichShop, listSellwichShops, querySellwichShopsByUserIdIndex } from './../graphql/queries';
import { createSellwichShop, updateSellwichShop } from './../graphql/mutations';

import { Shop } from '../models/Shop';
import { EventData } from './EventData';
import { EventType } from '../models/Event';

export const ShopData = {

    getShopByName: async (shopName: string) => {

        try {
            const shopResult = await API.graphql(graphqlOperation(getSellwichShop, { name: shopName }));
            // @ts-ignore
            const shop = shopResult['data']['getSellwichShop'] as Shop;
            // @ts-ignore
            if (shop && shop.templateParameters) {
                // @ts-ignore
                shop.templateParameters = JSON.parse(shop.templateParameters.toString());
            }
            return shop;
        } catch(err) {
            EventData.logEvent(`Error getting shop name: ${shopName}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopData', 1);
            return null;
        }
    },

    queryShopsByUserId: async (userId: string) => {

        try {
            const shopData = await API.graphql(graphqlOperation(querySellwichShopsByUserIdIndex, { userId: userId }));
            // @ts-ignore
            const items = shopData['data']['querySellwichShopsByUserIdIndex']['items'];
            return items as Array<Shop>;
        } catch (err) {
            EventData.logEvent(`Error querying shops by userId : ${userId}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopData', 1);
            return null;
        }
    },

    createShop: async (shop: Shop) => {
        try {
            const $input = shop;
            // @ts-ignore
            $input.templateParameters = JSON.stringify($input.templateParameters);
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichShop,
                    { input : $input }));
            // @ts-ignore
            return created['data']['createSellwichShop'];
        } catch(err) {
            EventData.logEvent(`Error creating shop. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopData', 1);
            return null;
        }
    },

    updateShop: async (shopName: string, attrs: any) => {

        try {
            const created = await API.graphql(
                graphqlOperation(
                    updateSellwichShop,
                    { input: { name: shopName, ...attrs} }));
            // @ts-ignore
            return created['data']['updateSellwichShop'];
        } catch (err) {
            EventData.logEvent(`Error updating shop. ShopName: ${shopName}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopData', 1);
            return null;
        }
    },

    listShops: async () => {
        try {
            const shops = await API.graphql(graphqlOperation(listSellwichShops, { input: { limit: 20 } }));
            // @ts-ignore
            return shops['data']['listSellwichShops']['items'];
        } catch (err) {
            EventData.logEvent(`Error listing shops. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopData', 1);
            return null;
        }
    },
}
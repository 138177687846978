import { IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Facebook } from '../utils/Facebook';
import { Footer } from '../components/Footer';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';
//import './Home.css';

const Login: React.FC = () => {

    const router = useIonRouter();
    const basicScope = 'public_profile,email';
    const fullScope = 'public_profile,email,business_management,catalog_management,pages_read_engagement,instagram_basic,ads_management'; // manage_business_extension
    const restrictedScope = 'commerce_manage_accounts,commerce_account_manage_orders,commerce_account_read_orders,commerce_account_read_reports';
    const whatsAppScope = 'whatsapp_business_management,whatsapp_business_messaging,user_messenger_contact';
    const sellwichAdminAccessToken = 'EAAHB8krlEsABANm4WV9UMVl5dmdirM6XFiHIi1osYutiR6wvqA8qIWSqo3efmw156ayVRZBW1RaitnsqHv13sPVIpZAHEIfHMEAmEwCdHr5Kg5zV3LTL4BmhrJQYADKhb7lIqslnMJVZC2hamfrdQPrzZCx10Eh2Ar0PfM0t9gXbTERZCBVdo';
    const pageAccessToken = 'EAAHB8krlEsABAPQcoDluDLCgcZCRyMOZBOCpKUTZBZBVuBFRGg5iCqPJpHoFJTa1qZAhgKMuXJdoJI3ZCmroHcnb1IHsCu6VArAM8cvSByoDpXiWvMpqKZBZC47AmZCDvQwarpBU5gcnXl5i5jas5pM7vd5TyZBgoUoY0ToyGt4OGiAs4iKNb0vBaXuXg7opBAlJkZD';
    const productFeedId = '1621345894989656';
    const fbEUrlWithoutExtra = 'https://facebook.com/dialog/oauth?client_id=494721359483584&display=page&redirect_uri=https://localhost:3000/testshop&response_type=token&scope=catalog_management';
    const fbEUrlWithExtra = 'https://facebook.com/dialog/oauth?client_id=494721359483584&display=page&redirect_uri=https://localhost:3000/testshop&response_type=token&scope=catalog_management&extras={setup:{external_business_id:sellwich-123,timezone:America/Los_Angeles,currency:USD,business_vertical:ECOMMERCE,channel:COMMERCE_OFFSITE,domain:https://dev.sellwich.com},business_config:{business:{name:SellwichUserShop},catalog_feed_scheduled:{enabled:true,feed_url:https://localhost:3000/feed/testshop.csv}},repeat:false}';

    const location = useLocation();
    let d = '/shop/dashboard';
    try {
        const redirectSplit = location.search.split('d=/');
        if (redirectSplit.length > 1) {
            const redirect =redirectSplit[1];
            d = redirect ? ('/' + redirect) : d;
        }
    } catch {
        EventData.logEvent('Error splitting search.', EventType.ERROR, 'LoginPage', 1);
    }

    // TODO get the referral Code from the url

    const checkLoginStatus = (resp: any) => {
        // @ts-ignore
        Facebook.checkLoginStatus(resp, () => {
            // TODO figure out why we are not reaching here.....
            // console.log(`IN LOGIN redirecting to ${d}`);
            // console.log(`RESP in checkLogin status is: `, resp);
            if (d) {
                // console.log('In the IF')
                router.push(d);
            } else {
                // console.log(`In the ELSE`);
                router.push('/shop/dashboard')
            }
        });
    }

    // @ts-ignore
    window.checkLoginStatus = checkLoginStatus; //Facebook.checkLoginStatus;
    // @ts-ignore
    window.launchFBE = Facebook.launchFBE;

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        Facebook.InitFacebook()
            .then((loggedIn) => {
                if (loggedIn === true) {
                    if (d) {
                        router.push(d);
                    } else {
                        router.push('/shop/dashboard');
                    }
                } else {
                    // @ts-ignore
                    const FB = window.FB;
                    // console.log('FB: ', FB);
                    if (FB) {
                        FB.Event.subscribe('xfbml.render', finishedRendering);
                    }
                }
            }).catch(err => {
                EventData.logEvent(`Error InitFacebook. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LoginPage', 1);
            });
        EventData.logEvent(`Login page visited.`, EventType.LOGIN_PAGE_VISIT, 'LoginPage');
    }, []);

    const finishedRendering = () => {
        var spinner = document.getElementById("login-spinner");
        spinner?.remove();
    }

    const launchFBE = () => {
        console.log('Calling launchFBE..');
        Facebook.launchFBE();
    }

    const getCatalogProductFeed = () => {
        console.log('Calling getCatalog.....');
        Facebook.getCatalogProductFeed();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Login</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className='container'>
                    <div>
                        <IonSpinner id='login-spinner' name='circular' color='secondary'></IonSpinner>
                    </div>
                    <div data-onlogin='checkLoginStatus' data-scope={basicScope} className="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="true"></div>
                    {/*
                    <div>
                        <IonButton onClick={launchFBE} color="primary">Launch FBE Workflow</IonButton>
                    </div>
                    <div>
                        <IonButton onClick={getCatalogProductFeed} color="primary">Try Catalog call</IonButton>
                    </div>
                    */}
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

export default Login;

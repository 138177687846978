import React from 'react';
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react';

import './SellwichVsShopify.css';

export const SellwichVsShopify: React.FC = () => {

    return (<div>
        <h3 id='vs-title'>Sellwich vs Shopify</h3>
        <IonGrid id='vs-grid'>
            <IonRow>
                <IonCol></IonCol>
                <IonCol>
                    <h4>Sellwich</h4>
                </IonCol>
                <IonCol>
                    <h4>Shopify</h4>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Available products</b></IonCol>
                <IonCol>10,000<sup>+</sup> Best-selling products</IonCol>
                <IonCol>Bring your own products</IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Set-up time</b></IonCol>
                <IonCol>5 minutes</IonCol>
                <IonCol>48<sup>+</sup> hours</IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Price</b></IonCol>
                <IonCol>$24</IonCol>
                <IonCol>$20-$300</IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Time commitment</b></IonCol>
                <IonCol><IonText>2 hours/week</IonText></IonCol>
                <IonCol><IonText>Full-time</IonText></IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Handle packaging and shipping</b></IonCol>
                <IonCol><IonText color='success'>NO</IonText></IonCol>
                <IonCol><IonText color='danger'>YES</IonText></IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Deal with taxes</b></IonCol>
                <IonCol><IonText color='success'>NO</IonText></IonCol>
                <IonCol><IonText color='danger'>YES</IonText></IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Hands-on guidance on how to social-sell</b></IonCol>
                <IonCol><IonText color='success'>YES</IonText></IonCol>
                <IonCol><IonText color='danger'>NO</IonText></IonCol>
            </IonRow>
            <IonRow>
                <IonCol><b>Supporting community</b></IonCol>
                <IonCol><IonText color='success'>YES</IonText></IonCol>
                <IonCol><IonText color='danger'>NO</IonText></IonCol>
            </IonRow>
        </IonGrid>
    </div>
    );
}
import { IonButton, useIonToast, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonMenuButton, IonPage, IonRow, IonText, IonToolbar, IonSelect, IonSelectOption } from '@ionic/react';
import { ShoppingCart, CartItem } from '../models/ShoppingCart';
import { LocalStorageData } from '../data/LocalStorageData';
import { ToastMessage } from '../utils/ToastMessage';
import { useParams } from 'react-router';

import './OrdersManager.css';
import { useEffect, useState } from 'react';
import { Order } from '../models/Order';
import { OrderData } from '../data/OrderData';
import { OrderDetailsModal } from '../components/OrderDetailsModal';
import { Scripts } from '../utils/Scripts';

const OrdersManager: React.FC = () => {

    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);
    const [orders, setOrders] = useState<Array<Order>>();
    const [unShipped, setUnShipped] = useState<Array<Order>>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clickedOrder, setClickedOrder] = useState<Order>();

    useEffect(() => {
        OrderData.listOrders()
            .then((ordaz) => {
                if (ordaz) {
                    //console.log(`Got orders: `, ordaz);
                    setOrders(ordaz.filter(x => x.lastestPaymentTime)
                        .sort((x, y) => x.timestamp > y.timestamp ? -1 : 1));
                    setUnShipped(ordaz.filter(x => (x.lastestPaymentTime && (!x.trackingNumbers || x.trackingNumbers?.length === 0))));
                } else {
                    console.warn(`We aint got no orders.`);
                }
            })
    }, []);

    const getOrderRowClass = (order: Order) => {
        let clas = 'cart-item-row';
        if (!order.dobaOrderPlacedTime) {
            clas += ' order-911';
        } else if (!order.trackingNumbers || order.trackingNumbers.length === 0) {
            clas += ' order-needs-attention';
        }
        return clas;
    }

    const calculateDaysSince = (timestamp: number) => {
        return Math.round((new Date().getTime() - timestamp)/(24 * 60 * 60 * 1000));
    }

    const orderClicked = (evt: any) => {
        const index = parseInt(evt.target.dataset.index);
        if (orders && index < orders.length) {
            setClickedOrder(orders[index]);
            setIsModalOpen(true);
        } else {
            console.log(`Orders are EMPTY.`);
        }
    }

    const toggleIsOpen = () => {
        setIsModalOpen(!isModalOpen);
        // @ts-ignore
        setClickedOrder(null);
    }
    return (
        <IonPage id='main'>
            <IonHeader>
                <IonToolbar>
                    Orders Manager
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div>
                    <p>Unshipped orders #: <strong><IonText color='danger'>{ unShipped?.length}</IonText></strong></p>
                </div>
                <IonGrid id='cart-grid'>
                    {// @ts-ignore
                        orders && orders.map((item, idx) => {
                            return (
                                <IonRow data-test='yoyo' className='cart-item-row order-needs-attention' key={idx}>
                                    <IonCol className={getOrderRowClass(item)}>
                                        <div onClick={orderClicked} data-index={idx}>
                                            <span data-index={idx} className='cart-product-title'>{item.orderNumber}</span>
                                            <span data-index={idx} className='cart-sub-text'><strong>Shop name: </strong>{item.shopName}</span>
                                            <br></br>
                                            <span data-index={idx} className='cart-sub-text'><strong>Items: </strong>{item.items.length}</span>
                                            <br></br>
                                            <span data-index={idx} className='cart-sub-text'><strong>Order total: </strong>${item.orderTotal}</span>
                                            <br></br>
                                            <span data-index={idx} className='cart-sub-text'><strong>Days since placed: </strong>{calculateDaysSince(item.timestamp)}</span>
                                            <br></br>
                                        </div>
                                    </IonCol>
                                </IonRow>
                            );
                        })}
                    {orders && orders.length === 0 &&
                        <div className='container'>
                            <h3>There is no orders. <a href={'/shop/dashboard/'}>Go to dashboard.</a></h3>
                        </div>
                    }
                </IonGrid>
                { clickedOrder &&
                    <OrderDetailsModal order={clickedOrder} isOpen={isModalOpen} toggleIsOpen={toggleIsOpen} />
                }
            </IonContent>
        </IonPage>
    );
};

export default OrdersManager;

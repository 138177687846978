export const Forms = {
    showErrorMessage: (show: boolean, elId: string) => {
        let el = document.getElementById(elId);
        if (el) {
            if (show) {
                el.style.display = 'contents';
            } else {
                el.style.display = 'none';
            }
        }
    }
}
import { IonButton, IonItem, IonList } from "@ionic/react";
import { useEffect } from "react";
import { EventData } from "../data/EventData";
import { EventType } from "../models/Event";
import FAQComponent from "./FAQ";

interface HelpProps {
    setTitle: Function;
}

const Help: React.FC<HelpProps> = ({ setTitle }) => {

    useEffect(() => {
        EventData.logEvent(`Help page visited.`, EventType.HELP_PAGE_VISIT, 'HelpComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    setTitle('Help');

    return (
        <div className="ion-padding">
            <IonList>
                <IonItem>
                    Need help? We are here for you and ensure your success. Reach us on our discord community or directly via chat.
                </IonItem>
            </IonList>
            <FAQComponent />
        </div>
    );
};

export default Help;

import { IonButton, IonInput, IonGrid, IonRow, IonCol, IonImg, IonItem, useIonToast, IonText, IonList, IonCard, IonCardHeader,
    IonCardSubtitle, IonCardContent, IonContent, IonToolbar, IonHeader, IonTitle, useIonRouter, IonInfiniteScroll,
    IonInfiniteScrollContent, IonIcon, IonMenu, IonSpinner, IonSplitPane, IonPage, IonButtons, IonMenuButton } from '@ionic/react';
import { filterOutline, arrowBackOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from "react";
import { InventoryProduct } from "../models/InventoryProduct";
import { InventoryProductData } from '../data/InventoryProductData';
import { Strings } from '../utils/Strings';
import { Forms } from '../utils/Forms';

import './InventoryProducts.css';
import { ShopProduct } from '../models/ShopProduct';
import { ShopProductData } from '../data/ShopProductData';
import { ToastMessage } from '../utils/ToastMessage';
import { ShopData } from '../data/ShopData';
import { LocalStorageData } from '../data/LocalStorageData';
import { Shop } from '../models/Shop';
import { Constants } from '../utils/Constants';
import { Numbers } from '../utils/Numbers';
import { InventoryCategoryRepresentation } from '../models/InventoryCategory';
import { InventoryCategoryData } from '../data/InventoryCategoryData';
import { AddProductToShopModal } from '../components/AddProductToShopModal';
import DashboardMenu from '../components/DashboardMenu';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

interface InventoryProductsProps {
    setTitle: Function;
}

let displayedProducts = new Array<InventoryProduct>();
let tempShops: Array<Shop>;
let nextToken = '';
let categories: Array<InventoryCategoryRepresentation> = new Array<InventoryCategoryRepresentation>();
let nestedCatLevels: Array<number> = [];
let inSearchMode = false;
let searchStart = 0;

const InventoryProducts: React.FC = () => {

    const router = useIonRouter();
    const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    const [presentMessage] = useIonToast();
    const toastMessage = new ToastMessage(presentMessage);

    const [products, setProducts] = useState(new Array<InventoryProduct>());
    const [isOpen, setIsOpen] = useState(false);
    const [sellwichPrice, setSellwichPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState<InventoryProduct>();
    const [selectedShopId, setSelectedShopId] = useState('');
    const [shops, setShops] = useState<Array<Shop>>();
    const [displayCategories, setDisplayCategories] = useState(categories);
    const [isBackVisible, setBackVisible] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searching, setSearching] = useState(false);

    const catmenu = useRef(null);

    // TODO implement adding multiple products at once with checkboxes on products: P2
    // TODO when a product is selected to be added to a shop, show related products: P2

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        getInventory();
        LocalStorageData.getLoggedInUserId()
            .then(userId => {
                if (userId === null) {
                    // redirect to login, prolly the user aint logged in
                    router.push('/login?d=/product/search')
                } else {
                    ShopData.queryShopsByUserId(userId)
                        .then(shops => {
                            if (shops === null) {
                                EventData.logEvent(`Failed to get shops.`, EventType.ERROR, 'InventoryProducts', 1);
                            } else {
                                setShops(shops);
                                tempShops = shops;
                                markProductsInShops();
                            }
                        })
                }
            });
        InventoryCategoryData.getTransformedCategories()
            .then(categs => {
                if (categs != null) {
                    categories = categs;
                    setDisplayCategories(categories);
                }
            });
        EventData.logEvent(`Search product page visited.`, EventType.SEARCH_PRODUCTS_PAGE_VISIT, 'InventoryProductsPage');
    }, []);

    const updateProducts = (prods: Array<InventoryProduct>, keepDisplayedProducts=true) => {
        if (keepDisplayedProducts) {
            displayedProducts = removeSameSPUDuplicates([...displayedProducts, ...prods]);
        } else {
            displayedProducts = removeSameSPUDuplicates([...prods]);
        }
        // marks products in shops and updates products
        markProductsInShops();
    }

    const getInventory = (evt: any = null) => {
        InventoryProductData.listInventoryProducts(Constants.PRODUCTS_INITIAL_LOAD_NUM, nextToken)
            .then((respData) => {
                const prods = respData?.items;
                if (respData) {
                    nextToken = respData.nextToken;
                }
                if (prods) {
                    updateProducts(prods);
                    if (evt) {
                        evt.target.complete();
                    }
                }
            });
    }

    const removeSameSPUDuplicates = (prods: Array<InventoryProduct>): Array<InventoryProduct> => {
        const mappedSPUNumbers = new Map();
        for (let idx=0; idx<prods.length; idx++) {
            if (mappedSPUNumbers.get(prods[idx].spuNumber)) {
                prods.splice(idx, 1);
                idx -= 1;
            } else {
                mappedSPUNumbers.set(prods[idx].spuNumber, 1);
            }
        }
        return prods;
    }

    const markProductsInShops = () => {
        if (displayedProducts && tempShops) {
            let productsMap = new Map();
            for(let idx=0; idx<displayedProducts.length; idx++) {
                productsMap.set(displayedProducts[idx].spuNumber, { index: idx, inShops: [] });
            }
            tempShops.forEach(shop => {
                ShopProductData.queryShopProductsByShopName(shop.name)
                    .then(results => {
                        results?.shopProds?.forEach(shopProd => {
                            let prodInShop = productsMap.get(shopProd.productId);
                            if (prodInShop) {
                                prodInShop.inShops.push(shop.displayName);
                                productsMap.set(shopProd.productId, prodInShop);
                            }
                        });
                        productsMap.forEach((val, key) => {
                            if (val.inShops.length > 0) {
                                displayedProducts[val.index].inShops = val.inShops;
                            }
                        });
                        setProducts([...displayedProducts]);
                    })
            })
        }
    }

    const productSelected = (evt: any) => {
        if (!products) {
            return;
        }
        setIsOpen(true);
        Forms.showErrorMessage(false, 'shopErrorMessage');
        const index = evt.target.dataset.index;
        setSelectedProduct(products[index]);
        const price = parseFloat(products[index].dropshippingPrice.toString());
        setSellwichPrice(price);
        const sale = Numbers.roundToDecimals(price + (price * 0.12));
        setSalePrice(sale);
    }

    const shopIdChanged = (evt: any) => {
        setSelectedShopId(evt.detail.value);
        if (evt.detail.value !== '') {
            Forms.showErrorMessage(false, 'shopErrorMessage');
        }
    }

    const saveSelectedProduct = (evt: any) => {
        if (selectedShopId === '') {
            Forms.showErrorMessage(true, 'shopErrorMessage');
            return;
        }
        const shopProduct = new ShopProduct({ shopName: selectedShopId, inventoryProduct: selectedProduct });
        shopProduct.salePrice = salePrice;
        ShopProductData.createShopProduct(shopProduct)
            .then((resp) => {
                if (resp === null) {
                    toastMessage.showErrorMessage('Failed to add Product. Refresh and try again.');
                } else {
                    setIsOpen(false);
                    toastMessage.showSuccessMessage(`Product addedd to shop.`);
                    markProductsInShops();
                }
            })
    }

    const getMoreProducts = (evt: any) => {
        if (inSearchMode && searchKeyword) {
            searchByKeyword(evt);
        } else {
            getInventory(evt);
        }
    }

    const showCategories = (evt: any) => {
        // @ts-ignore
        catmenu.current?.open();
    }

    const categoryClicked = (evt: any) => {
        const categoryName = evt.target.dataset.name;
        const subs = JSON.parse(evt.target.dataset.subs);
        const index = evt.target.dataset.index;
        if (subs.length === 0) {
            InventoryProductData.queryInventoryProductByCategory(categoryName)
                .then(prods => {
                    if (prods && prods.length > 0) {
                        updateProducts(prods, false);
                        // @ts-ignore
                        catmenu.current?.close();
                    }
                })
        } else {
            nestedCatLevels.push(parseInt(index));
            setBackVisible(true);
            setDisplayCategories(subs);
        }
    }

    const getCategoriesContent = (cats: Array<InventoryCategoryRepresentation>) => {
        return (
            <IonList>
                {cats.map((cat, index) => {
                    return (
                        <IonItem onClick={categoryClicked} data-index={index} data-name={cat.name} data-subs={JSON.stringify(cat.subs)} key={index} button={true} detail={(cat.subs.length > 0)}>{cat.name}</IonItem>
                    )
                })}
            </IonList>
        )
    }

    const goBackToParentCategory = (evt: any) => {
        if (nestedCatLevels.length === 0) {
            return;
        }
        else if (nestedCatLevels.length === 1) {
            setBackVisible(false);
            nestedCatLevels.splice(-1);
            setDisplayCategories(categories);
        } else {
            let idx = 0;
            let index = nestedCatLevels[idx];
            let subs = categories[index].subs;
            while (idx < nestedCatLevels.length - 2) {
                idx += 1
                index = nestedCatLevels[idx];
                subs = subs[index].subs;
            }
            nestedCatLevels.splice(-1);
            if (nestedCatLevels.length > 0) {
                setBackVisible(true);
            } else {
                setBackVisible(false);
            }
            setDisplayCategories(subs);
        }
    }

    const searchByKeyword = (evt: any) => {
        if (searchKeyword) {
            setSearching(true);
            if (inSearchMode) {
                searchStart += 100;
            }
            InventoryProductData.searchInventoryProducts(searchKeyword, searchStart)
                .then(prods => {
                    if (prods) {
                        if (inSearchMode) {
                            updateProducts(prods, true);
                        } else {
                            updateProducts(prods, false);
                        }
                        setSearching(false);
                        inSearchMode = true;
                        if (evt && typeof evt.target.complete === 'function') {
                            evt.target.complete();
                        }
                    }
                });
        }
    }

    const searchKeywordUpdated = (evt: any) => {
        searchStart = 0;
        inSearchMode = false;
        setSearchKeyword(evt.target.value);
    }

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <IonSplitPane contentId='main'>
            <DashboardMenu />
            <IonPage id='main'>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonMenuButton />
                        </IonButtons>
                        <div id='inventory-search-container' className='ion-justify-content-center ion-text-center'>
                            <IonGrid>
                                <IonRow>
                                    <IonCol id='search-bar-col'>
                                        <IonItem>
                                            <IonInput debounce={1500} onIonChange={searchKeywordUpdated} value={searchKeyword} placeholder='search by keyword'></IonInput>
                                            {searching &&
                                                <IonSpinner name='circular' id='search-spinner'></IonSpinner>
                                            }
                                            {!searching &&
                                                <IonButton size='small' id='search-button' onClick={searchByKeyword} fill='outline' color='medium'>Search</IonButton>
                                            }
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem lines='none' color='light'>
                                            <IonButton color='medium' slot='end' onClick={showCategories} fill='outline' id='search-categories'><IonIcon icon={filterOutline}></IonIcon> Categories</IonButton>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonMenu ref={catmenu} menuId='catmenu' type='overlay' side='end' contentId='inventory-products-container'>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>
                                    Categories
                                </IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className='ion-padding'>
                            {isBackVisible &&
                                <IonButton onClick={goBackToParentCategory} fill='clear'><IonIcon icon={arrowBackOutline}></IonIcon>Back</IonButton>
                            }
                            {getCategoriesContent(displayCategories)}
                        </IonContent>
                    </IonMenu>
                    <div id='inventory-products-container'>
                        {products.length > 0 && desktopMediaQuery.matches &&
                            <IonGrid className='show-on-desktop'>
                                <IonRow className='ion-justify-content-center ion-text-center'>
                                    <IonCol><b></b></IonCol>
                                    <IonCol><b>Name</b></IonCol>
                                    <IonCol><b>Product #</b></IonCol>
                                    <IonCol><b>Inventory</b></IonCol>
                                    <IonCol><b>Sellwich price</b></IonCol>
                                    <IonCol><b>Shipping cost</b></IonCol>
                                    <IonCol><b></b></IonCol>
                                </IonRow>
                                {products.map((prod, index) => {
                                    return (
                                        <IonRow key={index} className='ion-justify-content-center ion-text-center my-shop-row'>
                                            <IonCol className='ion-padding'><a href={'/product/' + prod.itemNumber + '/' + prod.spuNumber}><IonImg className='product-img' src={prod.productImages[0]}></IonImg></a></IonCol>
                                            <IonCol><a href={'/product/' + prod.itemNumber + '/' + prod.spuNumber} target='_blank' className='product-header-title'>{Strings.shortenStringDisplay(prod.productName, 60)}</a></IonCol>
                                            <IonCol><IonText>{prod.spuNumber}</IonText></IonCol>
                                            <IonCol><IonText>{prod.inventoryQuantity}</IonText></IonCol>
                                            <IonCol><IonText>${prod.dropshippingPrice}</IonText></IonCol>
                                            <IonCol><IonText>${prod.shippingEstimateCost}</IonText></IonCol>
                                            <IonCol>
                                                {!prod.inShops &&
                                                    < IonButton color='primary' onClick={productSelected} id={prod.spuNumber} data-index={index}>Add to your shop</IonButton>
                                                }
                                                {prod.inShops &&
                                                    < IonButton color='medium' fill='outline' onClick={productSelected} id={prod.spuNumber} data-index={index}>Add to another shop</IonButton>
                                                }
                                                {prod.inShops &&
                                                    <div>
                                                        <IonText>Product in shop(s):</IonText>
                                                        <IonList>
                                                            {
                                                                prod.inShops.map((inShop) => {
                                                                    return (
                                                                        <IonItem lines='none'>{inShop}</IonItem>
                                                                    )
                                                                })
                                                            }
                                                        </IonList>
                                                    </div>
                                                }
                                            </IonCol>
                                        </IonRow>)
                                })}
                            </IonGrid>
                        }
                        {products.length > 0 && mobileMediaQuery.matches &&
                            <IonList className='show-on-mobile'>
                                {products.map((prod, index) => {
                                    return (
                                        <IonCard key={index}>
                                            <IonCardHeader>
                                                <a className='product-header-title'><h4>{Strings.shortenStringDisplay(prod.productName, 60)}</h4></a>
                                            </IonCardHeader>
                                            <a href={'/product/' + prod.itemNumber + '/' + prod.spuNumber} target='_blank'><img className='product-img-mobile' src={prod.productImages[0]} alt={prod.productName} loading="lazy"></img></a>
                                            <IonCardContent>
                                                <IonCardSubtitle>Sellwich price:</IonCardSubtitle>
                                                <h1>${prod.dropshippingPrice}</h1>
                                            </IonCardContent>
                                            <IonItem>
                                                {!prod.inShops &&
                                                    <IonButton size='default' slot='end' color='primary' onClick={productSelected} id={prod.spuNumber} data-index={index}>Add to your shop</IonButton>
                                                }
                                                {prod.inShops &&
                                                    <IonButton slot='end' fill='outline' color='medium' onClick={productSelected} id={prod.spuNumber} data-index={index}>Add to another shop</IonButton>
                                                }
                                                {prod.inShops &&
                                                    <div>
                                                        <IonText className='product-in-shops-text'>Product in shop(s):</IonText>
                                                        <IonList>
                                                            {
                                                                prod.inShops.map((inShop) => {
                                                                    return (
                                                                        <IonItem lines='none' className='in-shop-name'>- {inShop}</IonItem>
                                                                    )
                                                                })
                                                            }
                                                        </IonList>
                                                    </div>
                                                }
                                            </IonItem>
                                        </IonCard>
                                    )
                                })}
                            </IonList>
                        }
                        {isOpen &&
                            <>
                                {/** @ts-ignore */}
                                <AddProductToShopModal isOpen={isOpen} toggleIsOpen={() => setIsOpen(!isOpen)} selectedProduct={selectedProduct} shops={shops} markProductsInShops={markProductsInShops} />
                            </>
                        }
                        <IonInfiniteScroll onIonInfinite={getMoreProducts}>
                            <IonInfiniteScrollContent></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                </IonContent>
            </IonPage>
        </IonSplitPane>
    );
};

export default InventoryProducts;

const FetchData = {

    post: async (url: string, data = {}) => {
        let response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(data),
        });
        return response?.json();
    },
    get: async (url: string) => {
        let response = await fetch(url, {
            method: 'GET',
            mode: 'no-cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.json();
    },
    test: async () => {
        let response = await fetch('https://uq5laedg6obhkcfbp4lm5fh73y0ydwly.lambda-url.us-east-2.on.aws/').then(rst => {
            // console.log('Got test result: ', rst);
        }).catch(err => {
            console.warn('Failed to get test result: ', err);
        });
        // console.log('Response in test: ', response);
    }
}

export default FetchData;
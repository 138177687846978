import { Strings } from "../utils/Strings";
import Address from "./Address";
import { CartItem } from "./ShoppingCart";

export class Order {

    constructor(order=null) {
        if (order) {
            // @ts-ignore
            this.orderNumber = order.orderNumber;
            // @ts-ignore
            this.shopName = order.shopName;
            // @ts-ignore
            this.shopDisplayName = order.shopDisplayName;
            // @ts-ignore
            this.shopperEmail = order.shopperEmail;
            // @ts-ignore
            this.shopperName = order.shopperName;
            // @ts-ignore
            this.orderTotal = order.orderTotal;
            // @ts-ignore
            this.items = JSON.parse(order.items.toString());
            // @ts-ignore
            this.shippingAddress = JSON.parse(order.shippingAddress.toString());
            // @ts-ignore
            this.trackingNumbers = JSON.parse(order.trackingNumbers);
            // @ts-ignore
            this.referralCode = order.referralCode;
            // @ts-ignore
            this.stripeCustomerId = order.stripeCustomerId;
            // @ts-ignore
            this.lastestPaymentTime = order.lastestPaymentTime;
            // @ts-ignore
            this.paymentFailedTime = order.paymentFailedTime;
            // @ts-ignore
            this.paymentDisputeTime = order.paymentDisputeTime;
            // @ts-ignore
            this.timestamp = order.timestamp;
            // @ts-ignore
            this.dobaOrderPlacedTime = order.dobaOrderPlacedTime;
        }
    }
    orderNumber = Strings.genCode(12);
    shopName!: string;
    shopDisplayName!: string;
    shopperEmail!: string;
    shopperName!: string;
    orderTotal!: number;
    items!: Array<CartItem>;
    shippingAddress!: Address;
    trackingNumbers!: Array<TrackingNumber>;
    referralCode!: string;
    stripeCustomerId!: string;
    lastestPaymentTime!: number;
    paymentFailedTime!: number;
    paymentDisputeTime!: number;
    timestamp = new Date().getTime();
    dobaOrderPlacedTime!: number;
}

export interface TrackingNumber {
    number: string;
    carrier: string;
    trackingUrl: string;
}
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Scripts } from '../utils/Scripts';

import './Policy.css';

const TermsOfUse: React.FC = () => {

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" aria-disabled>Privacy Policy</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className='policy-container ion-padding ion-justify-content-center'>
                    <h1 className='ion-text-center'>Privacy Policy</h1>
                    <div className='ion-padding'>
                        <p>Sellwich is compliant with The General Data Protection Regulation (GDPR) (EU) 2016/679.</p>
                        <p>It means that we are open about our methods of tracking and use of the visitors’ personal data, and you can freely check what exactly we’re doing with it.</p>
                        <p>By using any of our services, you are agreeing to the terms of this Privacy Policy and <a href='/terms-of-use'>Terms of Use</a>.</p>
                        <p>Below, you can read more about our reasons and ways to collect and use your personal details – they are different for our website visitors, company clients, and affiliates.</p>
                    </div>
                    <div className='ion-padding'>
                        <h2>I am a Sellwich.com visitor</h2>
                        <p>Sellwich.com visitors are Internet users who browse any pages on Sellwich.com website and take any actions apart from buying the Sellwich products or ordering Sellwich services.</p>
                        <h4>What personal details we collect from the Sellwich website visitors</h4>
                        <ul>
                            <li>Your email address (when you login with Facebook, in case you voluntarily leave it while downloading informational guides, or subscribing for mass emails, or leaving a comment in the blog section, or sending a query email to the support team)</li>
                            <li>The data about the browser and device you use to navigate the website</li>
                            <li>The way you navigate the website</li>
                        </ul>
                        <h4>Why we collect these details from the Sellwich website visitors</h4>
                        <p>The data is collected for the following purposes:</p>
                        <ul>
                            <li>Email addresses are used to communicate important account information, to give you the access to our educational materials, and to inform you about our deals and special offers</li>
                            <li>The data about your browser and device is used to personalise the website visual appearance, and to let you use the website resources to the full extent</li>
                            <li>The way you navigate the store is analysed in order to let us research the ways to improve the website interface for a greater users’ satisfaction</li>
                        </ul>
                        <h4>What personal details of the Sellwich website visitors we share with third parties</h4>
                        <p>We don't share personal information with third-parties unless in cases where a user may explicitly requests it.</p>
                    </div>
                    <div className='ion-padding'>
                        <h2>I am a Sellwich company client</h2>
                        <p>A Sellwich company client is an individual who has purchased at least one product or ordered at least one Sellwich business service.</p>
                        <h4>What personal details we collect from the Sellwich clients</h4>
                        <ul>
                            <li>Your first name and last name</li>
                            <li>Your email address and/or other contact details</li>
                            <li>The contents of your order(s) (the exact set of Sellwich services ever ordered by you)</li>
                            <li>Your payment details</li>
                            <li>The data about the browser and device you use to navigate the website</li>
                            <li>The way you navigate the store</li>
                        </ul>
                        <h4>Why we collect these details from the Sellwich clients</h4>
                        <p>The data is collected for the following purposes:</p>
                        <ul>
                            <li>Your first name and last name are used to verify your identity when you access the products developed for you by Sellwich team</li>
                            <li>Email addresses and/or other details are used to establish and maintain contact with you throughout the whole process of rendering the ordered service and providing the post-purchase lifelong support</li>
                        </ul>
                        <p>The data about the contents of your order is used to render you the service(s) you have purchased, and to keep track of them in order to be able to answer any of your questions that may arise during the post-purchase lifelong support</p>
                        <ul>
                            <li>Your payment details are used to perform fraud screening and process the orders</li>
                            <li>The data about your browser and device is used to personalise the website visual appearance, and to let you use the website resources to the full extent</li>
                            <li>The way you navigate the store is analysed in order to let us research the ways to improve the website interface for a greater users’ satisfaction</li>
                        </ul>
                        <h4>What personal details of the Sellwich clients we share with third parties</h4>
                        <p>The only information we share is payment information:</p>
                        <ul>
                            <li>Stripe (online payment processing service) uses your credit card details in order to charge you for the order and transfer the money to Sellwich company.</li>
                        </ul>
                    </div>
                    <div className='ion-padding'>
                        <h2>I am a Sellwich affiliate</h2>
                        <p>A Sellwich affiliate is an individual that has agreed to the terms of Sellwich Affiliate program and registered as an affiliate in order to promote Sellwich services by referring clients to the company.</p>
                        <h4>What personal details we collect from the Sellwich affiliates</h4>
                        <ul>
                            <li>Your first name and last name</li>
                            <li>Your email address</li>
                            <li>Your PayPal account details</li>
                            <li>The data about the browser and device you use to navigate the website</li>
                            <li>The way you navigate the store</li>
                        </ul>
                        <h4>Why we collect these details from the Sellwich clients</h4>
                        <p>The data is collected for the following purposes:</p>
                        <ul>
                            <li>Your first name and last name are used to verify your identity when you access your personal affiliate account</li>
                            <li>Email addresses are used to establish and maintain contact with you throughout the whole time of you being an Sellwich affiliate</li>
                            <li>Your PayPal details are used to send you affiliate commission payments</li>
                            <li>The data about your browser and device is used to personalise the website visual appearance, and to let you use the website resources to the full extent</li>
                            <li>The way you navigate the store is analysed in order to let us research the ways to improve the website interface for a greater users’ satisfaction.</li>
                        </ul>
                    </div>
                    <div className='ion-padding'>
                        <h2>How we protect your personal information</h2>
                        <ul>
                            <li>Your personal information is only used by a limited number of Sellwich company employees who are eligible to access such data, and are obliged to keep the personal details and financial information strictly confidential.</li>
                            <li>We store personal information on secure servers that are managed by us and our service providers. Personal information that we store is subject to security and access controls, such as username and password authentication.</li>
                        </ul>
                    </div>
                    <div className='ion-padding'>
                        <h2>How we use cookies</h2>
                        <p>Cookies are small files that are transferred to your computer through your Web browser. With the help of the cookies, Sellwich site remembers you and recognises your last activity on the website in order to improve its own performance.</p>
                        <p>We use cookies for the following purposes:</p>
                        <ul>
                            <li>To analyse your browser (this way, the website adapts to your device specifics)</li>
                            <li>To remember the contents of your order (this way, we understand your preferences and address your requests more efficiently)</li>
                            <li>To get generalized data on the Sellwich website traffic and site interaction (this way, we understand what better website experiences and additional tools we can introduce further)</li>
                        </ul>
                        <p>If you keep browsing our website after reading this Privacy Policy, you give us the consent to use your personal details for the purposes explained above.</p>
                        <p>If you don’t agree to these terms, please leave the website.</p>
                        <p>You can email us at hi@sellwich.com and ask:</p>
                        <ul>
                            <li>To receive the copy of your personal details we have collected</li>
                            <li>To delete your personal details from our system</li>
                            <li>To withdraw your consent (if you previously agreed to provide us with the data, but then changed your mind)</li>
                        </ul>
                        <p>Thank you</p>
                        <p><i>This document was last updated in January, 2023</i></p>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default TermsOfUse;

import { IonButton, IonItem, IonList } from "@ionic/react";
import { useEffect } from "react";
import { EventData } from "../data/EventData";
import { EventType } from "../models/Event";

interface DirectSalesProps {
    setTitle: Function;
}

const DirectSales: React.FC<DirectSalesProps> = ({ setTitle }) => {

    useEffect(() => {
        EventData.logEvent(`DirectSales page visited.`, EventType.DIRECT_SALES_PAGE_VISIT, 'DirectSalesComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    setTitle('Your sales income');

    return (
        <div className="ion-padding">
            <IonList>
                <IonItem>
                    No sales yet!
                </IonItem>
            </IonList>
        </div>
    );
};

export default DirectSales;

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { Scripts } from '../utils/Scripts';

import './Policy.css';

const TermsOfUse: React.FC = () => {

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" aria-disabled>Terms of Use</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className='policy-container ion-padding ion-justify-content-center'>
                    <h1 className='ion-text-center'>Terms of Use</h1>
                    <div className='ion-padding'>
                        <p>Last updated: January, 2023</p>
                        <p>Please read these Terms of Use (“Terms”) carefully before using the https://sellwich.com website (the “Service”).</p>
                        <p>Your access to and use of the Service and Software is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service and Software.</p>
                        <p>By accessing or using the Service and Software you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
                    </div>
                    <div className='ion-padding'>

                        <h2>SUBSCRIPTION PLANS</h2>

                        <p>Sellwich may offer new subscription plans and may change them and their features from time to time. If you sign up for a subscription plan, you agree to the terms, conditions and limitations associated with them that are published on Sellwich.com.</p>

                        <h2>Sellwich Monthly Subscription</h2>

                        <h4>Description of the Service</h4>
                        <p>Sellwich monthly subscription provides access to the Sellwich account where you can manage your subscription and monitor your orders, and manage products listed in your store. The shipping of Sellwich products is only done within the USA.</p>
                        <p>You store has a free Sellwich.com subdomain and you can purchase a custom domain to use instead of the free subdomain</p>
                        <p>Sellwich provide free hosting of your store.</p>
                        <p><b>Fee:</b> $24 per month</p>
                        <p><b>Free trial:</b> 14 days (since the date of purchase)</p>

                        <h4>Signing Up</h4>
                        <p>In order to subscribe you need to provide your email and credit card details on the checkout page of Sellwich.com.</p>
                        <p>By providing these details and signing up, you agree to the present terms, confirm that you are aware that the Sellwich subscription is a recurrent payment that will continue automatically and you authorize Sellwich monthly subscription fee to be collected after the end of the trial from the card that you provided when signing up unless you notify us before the charge that you would like to cancel the subscription or you cancel the subscription in your Sellwich account.</p>
                        <p>After subscribing to Sellwich you receive a confirmation email. The login page for your Sellwich account is at https://www.sellwich.com/login</p>

                        <h4>Trial</h4>
                        <p>The free trial starts on the day when you sign up and receive access to your Sellwich account.</p>

                        <h4>Renewal of the Subscription</h4>
                        <p>After the end of the free trial period, the subscription monthly payment is charged automatically without additional notification or authorization from the card that you provided when subscribing. You take the responsibility to have sufficient funds on the card by the payment due date to renew the subscription.</p>
                        <p>In case the subscription has not been paid on a due date, it becomes expired and render the subscriber's store inaccessible to the public until the subscription is renewed.</p>

                        <h4>Cancellation</h4>
                        <p>You can cancel your subscription plan at any time. To cancel the Sellwich monthly subscription, you can either click the “Cancel” link at https://sellwich.com/shop/dashboard?settings=me or contact Sellwich customer support at hi@sellwich.com with the cancellation request. If you cancel the subscription through Sellwich customer support, please make sure you have received the confirmation of the cancellation from a Sellwich support representative.</p>
                        <p>In case of a cancellation, you automatically forfeit any profits made during the period. Note that you cannot cancel current subscription after withdrawing profits that occured during current period. If you cancel after a withdral, your cancellation will take effect the following period and you cannot claim a refund for current period.</p>

                        <h4>Refunds</h4>
                        <p>Refunds for Sellwich monthly subscriptions are only done for the current subscription period. We do not offer refunds for previous monthly payments. And in case there was a withdraw of profits realized in current period, you can not claim a refund in that period.</p>

                        <h4>Termination of Service</h4>
                        <p>In case the subscription has not been paid on a due date, it becomes inactive immediately. After 30 days, Sellwich deactivates its free hosting and deletes the Free Sellwich subdomain.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Links to other websites and services</h2>
                        <p>Our Service may contain links to third-party websites or services that are not owned or controlled by Sellwich.</p>
                        <p>The Sellwich company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or services. You further acknowledge and agree that Sellwich company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
                        <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Re-use</h2>
                        <p>Sellwich allows the subscribers to use Sellwich products, images, videos, promo materials, product descriptions, and free marketing materials on their and third-party websites at their discretion.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Restrictions</h2>
                        <p>Prohibition on Reverse Engineering, Decompilation, and Disassembly. You may not reverse engineer, decompile, or disassemble the Software provided as part of Sellwich subscription or its services in any way without the written permission of Sellwich company.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2></h2>
                        <p></p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Selling Sellwich products on other platforms</h2>
                        <p>Unless otherwise specified, our retailers are permitted to sell Sellwich products on third-party marketplaces such as:</p>
                        <ul>
                            <li>Facebook</li>
                            <li>Instagram</li>
                            <li>TikTok</li>
                            <li>WhatsApp</li>
                            <li>Pinterest</li>
                            <li>Reddit</li>
                            <li>Amazon</li>
                            <li>Etsy</li>
                            <li>eBay</li>
                            <li>Walmart</li>
                            <li>Wish</li>
                            <li>Groupon</li>
                            <li>Faire</li>
                        </ul>
                    </div>

                    <div className='ion-padding'>
                        <h2>Termination</h2>
                        <p>Without prejudice to any other rights, the Sellwich company may terminate these Terms if you fail to comply with the terms and conditions of these Terms. In such an event, you must destroy all copies of the Software.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Copyright</h2>
                        <p>The Software provided as part of the Sellwich subscription is owned by the Sellwich company, and is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. The Software is licensed, not sold, to You for use solely subject to the terms and conditions of these Terms.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Limited warranty</h2>
                        <p>The software is provided “as is” without warranty of any kind, either express or implied, including, but not limited to warranties of merchantability or fitness for a particular purpose. in no event will the author or authors be liable to you for any damages, including incidental or consequential damages, arising out of the use of the software, even if advised of the possibility of such damages. you acknowledge that you have read this license, understand it and agree to be bound by its terms as the complete and exclusive statement of the agreement between us, superseding any proposal or prior agreement, oral or written, and any other communications between us relating to the subject matter of this license.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Limitation of Liability</h2>
                        <p>In no event shall Sellwich or its suppliers be liable to you for any consequential, special, incidental, or indirect damages of any kind arising out of the delivery, performance, or use of the software, even if Sellwich has been advised of the possibility of such damages. all legal issue should be judged by the court indicated by Sellwich.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Governing Law</h2>
                        <p>These Terms shall be governed and construed in accordance with the laws of the United States of America, without regard to its conflict of law provisions.</p>
                        <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Changes</h2>
                        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                        <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new terms, please stop using the Service.</p>
                    </div>

                    <div className='ion-padding'>
                        <h2>Contact Us</h2>
                        <p>If you have any questions about these Terms, please contact us hi@sellwich.com.</p>
                    </div>

                </div>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default TermsOfUse;

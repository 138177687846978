import { IonButtons, IonSplitPane, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonButton, useIonRouter, IonSpinner } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import DashboardMenu from './../components/DashboardMenu';
import MySellwich from '../components/MySellwich';
import Help from './../components/Help';
import Wallet from '../components/Wallet';
import LearningCenter from './../components/LearningCenter';
import Settings from './../components/Settings';
import DirectSales from './../components/DirectSales';
import Referrals from './../components/Referrals';

import { Shop } from '../models/Shop';
import { ShopData } from '../data/ShopData';
import { LocalStorageData } from '../data/LocalStorageData';
import { Constants } from '../utils/Constants';
import { ShopProductData } from '../data/ShopProductData';

import './ShopDashboard.css';
import { useEffect, useState } from 'react';
import { User } from '../models/User';
import { UserData } from '../data/UserData';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const ShopDashboard: React.FC = () => {

    const router = useIonRouter();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(location.search);
    let shopId = params.get('shop');
    const wallet = params.get('wallet');
    const help = params.get('help');
    const learn = params.get('learn');
    const sales = params.get('sales');
    const referrals = params.get('referrals');
    const settings = params.get('settings');
    const newUser = params.get('new');
    // @ts-ignore
    const sub: string = params.get('sub') ? params.get('sub') : '';
    // @ts-ignore
    const redirectStatus: string = params.get('redirect_status');
    // TODO if redirectStatus != 'succeeded' display an error message

    const [title, setTitle] = useState('Dashboard');
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(false);

    const createShop = (shop: Shop, shopToCloneId: string) => {
        ShopData.createShop(shop)
            .then((resp) => {
                if (resp === null) {
                    router.push('/shop/create');
                } else {
                    LocalStorageData.getLoggedInUserId()
                        .then(userId => {
                            LocalStorageData.getValue(Constants.LOCAL_STORAGE_KEYS.AD_ID)
                                .then(adId => {
                                    if (adId) {
                                        EventData.logEvent(`New shop created from adId: ${adId} by userId: ${userId}.`, EventType.NEW_SHOP_CREATED_FROM_AD, 'ShopDashboardPage');
                                    }
                                });
                        });
                    if (shopToCloneId === '') {
                        LocalStorageData.getLoggedInUserId()
                            .then(userId => {
                                EventData.logEvent(`UserId: ${userId} created blank shop.`, EventType.CREATED_BLANK_SHOP, 'ShopDashboardPage');
                            });
                        router.push(`/product/search`);
                    } else {
                        setLoading(true);
                        ShopProductData.cloneShopProducts(shopToCloneId, shop.name)
                            .then(resp => {
                                setLoading(false);
                                LocalStorageData.getLoggedInUserId()
                                    .then(userId => {
                                        EventData.logEvent(`Userid: ${userId} picked a built shop while creating shop. Shop to clone ID: ${shopToCloneId}`, EventType.PICKED_BUILT_SHOP_CREATING_SHOP, 'ShopDashboardPage');
                                    });
                                router.push(`/shop/edit/${shop.name}`);
                            });
                    }
                }
            });
    }

    const getUser = (userId: string) => {
        UserData.getUserById(userId)
            .then(user => {
                if (user) {
                    setUser(user);
                }
            })
    }
    const logTrialStart = (userId: string) => {
        LocalStorageData.getValue(Constants.LOCAL_STORAGE_KEYS.AD_ID)
            .then(adId => {
                if (adId) {
                    EventData.logEvent(`Add conversion. AdId: ${adId}. UserId: ${userId}`, EventType.AD_CONVERSION, 'ShopDashboardPage');
                }
            });
        EventData.logEvent(`Trial started. UserId: ${userId}.`, EventType.TRIAL_STARTED, 'ShopDashboardPage');
        // @ts-ignore
        if (window.gtag) {
            // @ts-ignore
            window.gtag('event', 'conversion', { 'send_to': 'AW-984240673/4YI5CLmeqJEYEKGkqdUD' });
        }
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'StartTrial', { value: '2.00', currency: 'USD', predicted_ltv: '4.00' });
        }
    }

    useEffect(() => {
        if (newUser) {
            const shopName = params.get('nm');
            const shopToCloneId = params.get('cl') ? params.get('cl') : '';
            if (shopName) {
                LocalStorageData.getLoggedInUserId()
                    .then((userId) => {
                        if (userId === null) {
                            // This shouldn't happen
                            EventData.logEvent(`An unexpected error happened. The user should be logged in, and userId in localStorage, at this point`, EventType.ERROR, 'ShopDashboardPage', 1);
                            router.push('/login?d=/shop/create');
                        } else {
                            getUser(userId);
                            if (shopToCloneId === '') {
                                const shop = new Shop(shopName, userId, Constants.TEMPLATES.DEFAULT.NAME, Constants.TEMPLATES.DEFAULT.PARAMS);
                                createShop(shop, shopToCloneId);
                            } else {
                                // @ts-ignore
                                ShopData.getShopByName(shopToCloneId)
                                    .then((shopResp) => {
                                        const newShop = new Shop(shopName, userId, shopResp?.templateName, shopResp?.templateParameters);
                                        // @ts-ignore
                                        createShop(newShop, shopToCloneId);
                                    })
                            }
                        }
                    })
            } else {
                router.push('/login?d=/shop/create');
            }
        } else {
            LocalStorageData.getLoggedInUserId()
                .then((userId) => {
                    if (userId === null) {
                        // This shouldn't happen
                        router.push('/login?d=/shop/create');
                    } else {
                        getUser(userId);
                        // Check if the user already has a shop
                        LocalStorageData.getUserShops()
                            .then(shopz => {
                                if (!shopz) {
                                    ShopData.queryShopsByUserId(userId)
                                        .then(shops => {
                                            if (shops && shops.length > 0) {
                                                LocalStorageData.setUserShops(shops);
                                            } else {
                                                router.push('/shop/create');
                                            }
                                        })
                                }
                            });
                    }
                });
        }
        EventData.logEvent(`Shop dashboard page visited.`, EventType.DASHBOARD_PAGE_VISIT, 'ShopDashboardPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'CompleteRegistration');
        }
        if (sub && redirectStatus === 'succeeded') {
            if (user) {
                logTrialStart(user.id);
            } else {
                LocalStorageData.getLoggedInUserId()
                    .then(userId => {
                        logTrialStart(userId ? userId : '');
                    });
            }
        }
    }, []);
    Scripts.loadLiveChat();
    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();

    const getStartTrialButton = () => {
        if (user && !user.isInTrial && !user.lastestPaymentTime) {
            return (<>
                <IonButton routerLink='/shop/benefits' color='warning' fill='solid' size='large'>
                    Start free trial
                </IonButton>
            </>);
        } else {
            return null;
        }
    }

    return (
        <IonSplitPane contentId='main'>
            <DashboardMenu />
            <IonPage id='main'>
                <IonContent fullscreen>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="start">
                                <IonMenuButton />
                            </IonButtons>
                            <IonTitle>{!shopId && title}</IonTitle>
                            <IonButtons slot='end'>
                                {(search === '' || (sub && redirectStatus === 'succeeded')) && getStartTrialButton()}
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    {(search === '' || (sub && redirectStatus === 'succeeded')) &&
                        <MySellwich setTitle={setTitle} sub={sub} />
                    }
                    {wallet &&
                        <Wallet setTitle={setTitle} />
                    }
                    {sales &&
                        <DirectSales setTitle={setTitle} />
                    }
                    {referrals &&
                        <Referrals setTitle={setTitle} />
                    }
                    {learn &&
                        <LearningCenter setTitle={setTitle} />
                    }
                    {help &&
                        <Help setTitle={setTitle} />
                    }
                    {settings &&
                        <Settings setTitle={setTitle} />
                    }
                    { loading &&
                        <div className='container'><IonSpinner name='circular'></IonSpinner></div>
                    }

                    <div id="fb-root"></div>
                    <div id="fb-customer-chat" className="fb-customerchat"></div>
                </IonContent>
            </IonPage>
        </IonSplitPane>
    );
};

export default ShopDashboard;

import { API, graphqlOperation } from 'aws-amplify';
import { listSellwichEvents } from './../graphql/queries';
import { createSellwichEvent } from './../graphql/mutations';
import { Event } from '../models/Event';
import { Strings } from '../utils/Strings';
import { LocalStorageData } from './LocalStorageData';
import { env } from 'process';

export const EventData = {

    createEvent: async (event: Event) => {
        try {
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichEvent,
                    { input: event }));
            // @ts-ignore
            return created['data']['createSellwichEvent'];
        } catch (err) {
            console.error(`Error creating event: `, event);
            console.error('Error: ', err);
        }
    },

    listEvents: async () => {
        try {
            const events = await API.graphql(graphqlOperation(listSellwichEvents, { input: { limit: 20 } }));
            // @ts-ignore
            return events['data']['listSellwichEvents']['items'];
        } catch (err) {
            console.error(`Error listing events. \n`, err);
            return null;
        }
    },

    logEvent: async (message: string, type: string, source: string, importance=3) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(`In development do not log the event. `, process.env.NODE_ENV);
            return;
        }
        const evt = new Event();
        evt.message = message;
        evt.type = type;
        evt.source = source;
        evt.importance = importance;
        return LocalStorageData.getSessionId()
            .then(sessionId => {
                if (sessionId) {
                    evt.sessionId = sessionId;
                    evt.timestamp = new Date().getTime();
                    return LocalStorageData.getLoggedInUserId()
                        .then(userId => {
                            if (userId) {
                                evt.userId = userId;
                            } else {
                                evt.userId = sessionId;
                            }
                            return EventData.createEvent(evt);
                        });
                } else {
                    console.error(`No sessionId returned. This should not happen.`, sessionId);
                    return null;
                }
            });
    }
}
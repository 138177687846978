import { Storage } from '@ionic/storage';
import { EventType } from '../models/Event';
import { InventoryProduct } from '../models/InventoryProduct';
import { Shop } from '../models/Shop';
import { CartItem, ShoppingCart } from '../models/ShoppingCart';
import { Constants } from '../utils/Constants';
import { Strings } from '../utils/Strings';
import { EventData } from './EventData';

const store = new Storage();
store.create()
        .then(() => { return; })
        .catch ((err: any) => {
            EventData.logEvent(`Failed to create local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
        })

export const LocalStorageData = {

    setLoggedInUserId: async (userId: string) => {
        return store.set(Constants.LOCAL_STORAGE_KEYS.USER_ID, userId)
                .then(() => {
                    return userId;
                }).catch((err: any) => {
                    EventData.logEvent(`Error storing userId: ${userId} in local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                    return null;
                });
    },

    getLoggedInUserId: async () => {

        return store.get(Constants.LOCAL_STORAGE_KEYS.USER_ID)
                .then((userId: string) => {
                    // TODO remove the following hard-coded line, it's just for testing..
                    //return '10159217388521313';
                    return userId;
                }).catch((err: any) => {
                    EventData.logEvent(`Error getting userId from local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                    return null;
                });
    },

    removeUserId: async () => {
        return store.remove(Constants.LOCAL_STORAGE_KEYS.USER_ID)
            .then((userId: string) => {
                return userId;
            }).catch((err: any) => {
                EventData.logEvent(`Error removing userId from local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    setShopIdToClone: async (shopId: string) => {

        return store.set(Constants.LOCAL_STORAGE_KEYS.SHOP_ID_TO_CLONE, shopId)
            .then(() => {
                return shopId;
            }).catch((err: any) => {
                EventData.logEvent(`Error storing shopId: ${shopId} in local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    getShopIdToClone: async () => {

        return store.get(Constants.LOCAL_STORAGE_KEYS.SHOP_ID_TO_CLONE)
            .then((shopId: string) => {
                return shopId;
            }).catch((err: any) => {
                EventData.logEvent(`Error getting shopId from local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    getShoppingCart: async () => {
        return store.get(Constants.LOCAL_STORAGE_KEYS.SHOPPING_CART)
            .then(cart => {
                if (!cart) {
                    const theCart = new ShoppingCart();
                    return LocalStorageData.setShoppingCart(theCart);
                } else {
                    const theCart = new ShoppingCart(JSON.parse(cart));
                    return theCart;
                }
            }).catch(err => {
                EventData.logEvent(`Error geting the cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    addProductToCart: async (product: InventoryProduct, quantity: number, salePrice: number, shippingCost: number, sellwichPrice: number) => {
        return LocalStorageData.getShoppingCart()
            .then(cart => {
                if (cart) {
                    cart.addItem(product, quantity, salePrice, shippingCost, sellwichPrice);
                    //console.log(`Cart items stringified is: `, JSON.stringify(Object.fromEntries(cart.items)));
                    return LocalStorageData.setShoppingCart(cart);
                }
            }).catch(err => {
                EventData.logEvent(`Failed to add product to cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                throw (`Failed to add product to cart;`);
            });
    },

    removeItemFromCart: async (itemNumber: string) => {
        return LocalStorageData.getShoppingCart()
            .then(cart => {
                if (cart) {
                    cart.removeItem(itemNumber);
                    return LocalStorageData.setShoppingCart(cart);
                }
            }).catch(err => {
                EventData.logEvent(`Failed to remove product from cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                throw (`Failed to remove product from cart;`);
            });
    },

    updateItemQuantityInCart: async (itemNumber: string, quantity: number) => {
        return LocalStorageData.getShoppingCart()
            .then(cart => {
                if (cart) {
                    cart.updateQuantity(itemNumber, quantity);
                    return LocalStorageData.setShoppingCart(cart);
                }
            }).catch(err => {
                EventData.logEvent(`Failed to remove product from cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                throw (`Failed to remove product from cart;`);
            });
    },

    setShoppingCart: async (cart: ShoppingCart) => {
        const stringifiedCartItems = JSON.stringify(Object.fromEntries(cart.items));
        return store.set(Constants.LOCAL_STORAGE_KEYS.SHOPPING_CART, stringifiedCartItems)
            .then(resp => {
                return cart;
            }).catch(err => {
                EventData.logEvent(`Failed to save to the cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            })
    },

    clearShoppingCart: async () => {
        return store.remove(Constants.LOCAL_STORAGE_KEYS.SHOPPING_CART)
            .then((cart: string) => {
                return cart;
            }).catch((err: any) => {
                EventData.logEvent(`Error clearing the shopping cart from local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    setUserShops: async (shops: Array<Shop>) => {
        return store.set(Constants.LOCAL_STORAGE_KEYS.USER_SHOPS, JSON.stringify(shops))
            .then(() => {
                return shops;
            }).catch((err: any) => {
                EventData.logEvent(`Error storing ${shops.length} shops in local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    getUserShops: async () => {
        return store.get(Constants.LOCAL_STORAGE_KEYS.USER_SHOPS)
                .then(shopsStringified => {
                    if (shopsStringified) {
                        const shops = JSON.parse(shopsStringified);
                        return shops;
                    } else {
                        return null;
                    }
                }).catch(err => {
                    EventData.logEvent(`Failed to get shops from local Storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                    return null;
                })
    },

    clearUserShops: async () => {
        return store.remove(Constants.LOCAL_STORAGE_KEYS.USER_SHOPS)
            .then((shopsString: string) => {
                return shopsString;
            }).catch((err: any) => {
                EventData.logEvent(`Error clearing the user shops from local storage. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    getSessionId: async () => {
        return store.get(Constants.LOCAL_STORAGE_KEYS.SESSION_ID)
                .then(sessionId => {
                    if (sessionId) {
                        return sessionId;
                    } else {
                        const sessionId = Strings.genCode(12);
                        store.set(Constants.LOCAL_STORAGE_KEYS.SESSION_ID, sessionId);
                        return sessionId;
                    }
                }).catch(err => {
                    EventData.logEvent(`Error getting sessionId. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                    return '0';
                });
    },

    setReferralCode: async (ref: string) => {
        return store.set(Constants.LOCAL_STORAGE_KEYS.REFERRAL_CODE, ref)
                .then(resp => {
                    return ref;
                }).catch(err => {
                    EventData.logEvent(`Error setting referralCode. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                    return null;
                });
    },

    getReferralCode: async () => {
        return store.get(Constants.LOCAL_STORAGE_KEYS.REFERRAL_CODE)
            .then(referralCode => {
                return referralCode;
            }).catch(err => {
                EventData.logEvent(`Error getting referralCode. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    setValue: async (key: string, value: string) => {
        return store.set(key, value)
            .then(resp => {
                return resp;
            }).catch(err => {
                EventData.logEvent(`Error setting key: ${key} to value: ${value}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    },

    getValue: async (key: string) => {
        return store.get(key)
            .then(val => {
                return val;
            }).catch(err => {
                EventData.logEvent(`Error getting ${key}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'LocalStorageData', 1);
                return null;
            });
    }
 }
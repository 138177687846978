import { IonButton, IonIcon, IonInput, IonLabel, IonList, IonItem } from '@ionic/react';
import { useState } from 'react';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { ShopData } from '../data/ShopData';
import { Forms } from '../utils/Forms';
import { Strings } from '../utils/Strings';

import './ShopName.css';

interface ShopNameProps {
    updateName: Function,
    updateExists: Function
}

const ShopName: React.FC<ShopNameProps> = ({ updateName, updateExists }) => {

    const [name, setName] = useState('');
    const [subDomain, setSubDomain] = useState('');
    const [exists, setExists] = useState(false);

    const nameUpdated = (e: any) => {
        setName(e.target.value);
        setSubDomain(Strings.normalizeShopName(e.target.value));
        checkIfSubDomainExists(Strings.normalizeShopName(e.target.value));
        updateName(e.target.value);
    }

    const checkIfSubDomainExists = async (subDomain: string) => {
        // Minimun length for a shop name is 4
        if (subDomain.length <= 3) {
            setExists(true);
            return true;
        } else {
            return ShopData.getShopByName(subDomain)
                .then((resp) => {
                    if (resp === null) {
                        setExists(false);
                        updateExists(false);
                        Forms.showErrorMessage(false, 'takenErrorMessage');
                        return false;
                    } else {
                        setExists(true);
                        updateExists(true);
                        Forms.showErrorMessage(true, 'takenErrorMessage');
                        return true;
                    }
                });
        }
    }

    return (
        <div id="shop-name-container">
            <form>
                <IonList>
                    <IonItem>
                        <IonLabel position='stacked'>
                            Shop name *
                            <span className='error-message' id='nameErrorMessage'>(A name is required)</span>
                            <span className='error-message' id='takenErrorMessage'>(Taken)</span>:
                        </IonLabel>
                        <IonInput value={name} onIonChange={nameUpdated} placeholder='Name your shop' type='text'></IonInput>
                    </IonItem>
                    { subDomain &&
                        <IonItem>
                            <IonLabel position='stacked'>
                                Website:
                            </IonLabel>
                            <p id='domain'>www.<span id='sub-domain'>{subDomain}</span>.sellwich.com</p>
                            { !exists &&
                                <IonIcon slot='end' icon={checkmarkCircleOutline} color='success'></IonIcon>
                            }
                            { exists &&
                                <IonIcon slot='end' icon={closeCircleOutline} color='danger'></IonIcon>
                            }
                        </IonItem>
                    }
                </IonList>

            </form>
        </div>
    );
};

export default ShopName;

import { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonModal, IonPage, IonRow, IonToolbar, useIonRouter } from '@ionic/react';
import { useAuthContext } from '../utils/AuthContext';
import { barChartOutline, rocketOutline, cashOutline, playCircleOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { Footer } from '../components/Footer';
import { Header } from './../components/Header';
import BuiltShops from './../components/BuiltShops';
import { SellwichVsShopify } from '../components/SellwichVsShopify';
import { LocalStorageData } from '../data/LocalStorageData';
import { Scripts } from '../utils/Scripts';

import './Home.css';
import { Constants } from '../utils/Constants';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const Home: React.FC = () => {

    const { referralCode } = useParams<{ referralCode: string; }>();
    const { adId } = useParams<{ adId: string; }>();
    if (referralCode) {
        LocalStorageData.setReferralCode(referralCode);
    }
    if (adId) {
        LocalStorageData.setValue(Constants.LOCAL_STORAGE_KEYS.AD_ID, adId);
    }
    const router = useIonRouter();
    // @ts-ignore
    const { loggedIn, userId } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const [showTestimonies, setShowTestimonies] = useState(false);

    useEffect(() => {
        EventData.logEvent(`Home page visited.`, EventType.HOME_PAGE_VISIT, 'HomePage');
        if (adId) {
            EventData.logEvent(`Ad Page visit, adId: ${adId}.`, EventType.AD_PAGE_VISIT, 'HomePage');
        }
        setShowTestimonies(true);
    }, []);
    Scripts.loadLiveChat();
    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();

    const getStarted = () => {
        // TODO log the click
        //console.log('Getting started, loggedIn: ', loggedIn, userId); // Should log into the DB here
        if (loggedIn) {
            // TODO show benefit after the user creates the shop and is ready to start selling
            // This scenario should never happen because we would not show the get started button to logged in user
            //router.push('/shop/benefits');
            router.push('/shop/create');
        } else {
            //router.push('/login?d=/shop/benefits');
            router.push('/login?d=/shop/create');
        }
    }

    const pickedShop = (evt: any) => {
        const shopToCloneId = evt.target.dataset.shopId;
        LocalStorageData.setShopIdToClone(shopToCloneId);
        EventData.logEvent(`Picked a built shop on home page.`, EventType.PICKED_SHOP_ON_HOME_PAGE, 'HomePage');
        router.push('/shop/create');
    }

    const playVideoClicked = (evt: any) => {
        setIsOpen(true);
        EventData.logEvent('User clicked on play video button.', EventType.PROMO_VIDEO_VIEW, 'HomePage');
    }

    return (
        <IonPage>

            <IonContent fullscreen>
                <Header />
                <div id='home-container'>
                    <div id='home-top-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <div id='home-top-pitch'>Make money selling viral products on social</div>
                                    <div id='home-top-sub-pitch'>In 5 minutes, create your online shop and select best-selling products to sell across social media.
                                        Set your own prices to profit and sell through Instagram, TikTok, Facebook Shops etc.
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        { !loggedIn &&
                                            <>
                                            <IonButton id='home-main-get-started' href='/shop/create' color="warning" size='large'>Get started for FREE</IonButton>
                                            <IonButton onClick={playVideoClicked} fill='clear' color='primary'> <IonIcon icon={playCircleOutline}></IonIcon> Watch Video</IonButton>
                                            </>
                                        }
                                        { loggedIn &&
                                            <>
                                            <IonButton id='home-main-get-started' href='/shop/dashboard' color="primary" size='large'>Go To Dashboard</IonButton>
                                            <IonButton onClick={playVideoClicked} fill='clear' color='primary'> <IonIcon icon={playCircleOutline}></IonIcon> Watch Video</IonButton>
                                            </>
                                        }
                                    </div>
                                    <IonRow id='sub-pitch-row'>
                                        <IonCol>
                                            <IonIcon icon={rocketOutline} color='success' size='large'></IonIcon>
                                            <h6>100% hassle-free start</h6>
                                            <div className='sub-pitch'>
                                                We handle the hard parts, sourcing best-selling products, payments, order fulfilment, returns etc.
                                            </div>
                                        </IonCol>
                                        <IonCol>
                                            <IonIcon icon={cashOutline} color='success' size='large'></IonIcon>
                                            <h6>No experience needed</h6>
                                            <div className='sub-pitch'>
                                                We teach you how to promote your shop on social media; profits roll in on autopilot
                                            </div>
                                        </IonCol>
                                        <IonCol>
                                            <IonIcon icon={barChartOutline} color='success' size='large'></IonIcon>
                                            <h6>Financial independence</h6>
                                            <div className='sub-pitch'>
                                                You get an additional source of income and a brilliant opportunity to change your life for the better
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                                <IonCol>
                                    <img className='home-img' alt='Sell viral products on social' src='https://s3.amazonaws.com/sellwich.com/img/home_top_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div id='featured-section'>
                        <img src='https://s3.amazonaws.com/sellwich.com/img/featured.png' alt='featured in media'></img>
                    </div>

                    <div className='home-2nd-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='show-on-desktop'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on Facebook' src='https://s3.amazonaws.com/sellwich.com/img/home_fb_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                                <IonCol>
                                    <div id='sell-on-fb-right-div'>
                                        <div id='home-2nd-pitch'>Sell on Facebook</div>
                                        <div id='home-fb-sub-pitch'>Easily bring your shop of the best-selling products to Facebook shops and start social selling to billions of shoppers</div>
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        {!loggedIn &&
                                            <IonButton href='/shop/create' id='home-fb-btn' mode='md' color='light' size='large'>Start social selling</IonButton>
                                        }
                                    </div>
                                </IonCol>
                                <IonCol className='show-on-mobile'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on Facebook' src='https://s3.amazonaws.com/sellwich.com/img/home_fb_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div className='home-2nd-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <div id='sell-on-insta-left-div'>
                                        <div id='home-2nd-pitch'>Sell on Instagram</div>
                                        <div id='home-insta-sub-pitch'>Tag products in your Instagram posts and make it easy for shoppers to discover and buy them</div>
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        {!loggedIn &&
                                            <IonButton href='/shop/create' mode='md' color='light' size='large'>Check it out</IonButton>
                                        }
                                    </div>
                                </IonCol>
                                <IonCol>
                                    <img className='home-img' alt='Sell on Instagram' src='https://s3.amazonaws.com/sellwich.com/img/home_insta_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div className='home-2nd-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='show-on-desktop'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on TikTok' src='https://s3.amazonaws.com/sellwich.com/img/home_tiktok_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                                <IonCol>
                                    <div id='sell-on-fb-right-div'>
                                        <div id='home-2nd-pitch'>Sell on TikTok</div>
                                        <div id='home-fb-sub-pitch'>Go viral with your shop of top-selling products. TikTop shops allow you to feature the products in viral videos</div>
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        {!loggedIn &&
                                            <IonButton href='/shop/create' id='home-fb-btn' mode='md' color='light' size='large'>Get started</IonButton>
                                        }
                                    </div>
                                </IonCol>
                                <IonCol className='show-on-mobile'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on TikTok' src='https://s3.amazonaws.com/sellwich.com/img/home_tiktok_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div className='home-2nd-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <div id='sell-on-insta-left-div'>
                                        <div id='home-2nd-pitch'>Sell on Pinterest</div>
                                        <div id='home-insta-sub-pitch'>Select inspirational products to pin and get your shop discovered on Pinterest</div>
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        {!loggedIn &&
                                            <IonButton href='/shop/create' mode='md' color='light' size='large'>Start pin'ing</IonButton>
                                        }
                                    </div>
                                </IonCol>
                                <IonCol>
                                    <img className='home-img' alt='Sell on Pinterest' src='https://s3.amazonaws.com/sellwich.com/img/home_pinterest_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div className='home-2nd-section'>
                        <IonGrid>
                            <IonRow>
                                <IonCol className='show-on-desktop'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on SnapChat' src='https://s3.amazonaws.com/sellwich.com/img/home_snap_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                                <IonCol>
                                    <div id='sell-on-fb-right-div'>
                                        <div id='home-2nd-pitch'>Sell on Snapchat</div>
                                        <div id='home-fb-sub-pitch'>Create a shop with products targeting Snapchat's young shoppers and reach them with a snap ad campaign</div>
                                    </div>
                                    <div className='ion-justify-content-center ion-text-center'>
                                        {!loggedIn &&
                                            <IonButton href='/shop/create' id='home-fb-btn' mode='md' color='light' size='large'>Snap it</IonButton>
                                        }
                                    </div>
                                </IonCol>
                                <IonCol className='show-on-mobile'>
                                    <img id='sell-on-fb-img' className='home-img' alt='Sell on SnapChat' src='https://s3.amazonaws.com/sellwich.com/img/home_snap_sell_img_min.webp' loading="lazy"></img>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>

                    <div id='built-store-container'>
                        <BuiltShops pickedShop={pickedShop}/>
                    </div>

                    { showTestimonies &&
                        <div id='testimony-section'>
                            <h2 id='testimony-title'>Testimonies</h2>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        { /* @ts-ignore */}
                                        <iframe data-src="https://player.vimeo.com/video/654903621?h=568be6914c&amp;transparent=0&amp;color=FF8B25" width="390" height="390" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="loaded" src="https://player.vimeo.com/video/654903621?h=568be6914c&amp;transparent=0&amp;color=FF8B25" data-was-processed="true" data-ready="true" loading="lazy"></iframe>
                                        <div className='testimony-text'>
                                            <h4>
                                                “It's not just about the money for me. It feels like I bought a lifestyle.”
                                            </h4>
                                        </div>
                                    </IonCol>
                                    <IonCol>
                                        { /* @ts-ignore */}
                                        <iframe data-src="https://player.vimeo.com/video/660348628?h=83bead5f33&amp;transparent=0&amp;color=FF8B25" width="390" height="390" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="loaded" src="https://player.vimeo.com/video/660348628?h=83bead5f33&amp;transparent=0&amp;color=FF8B25" data-was-processed="true" data-ready="true" loading="lazy"></iframe>
                                        <div className='testimony-text'>
                                            <h4>
                                                “I don't have to go out and work for “the man”. I have much more freedom.”
                                            </h4>
                                        </div>
                                    </IonCol>
                                    <IonCol>
                                        { /* @ts-ignore */}
                                        <iframe data-src="https://player.vimeo.com/video/688361328?h=4019342335&amp;transparent=0&amp;color=FF8B25" width="390" height="390" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="loaded" src="https://player.vimeo.com/video/688361328?h=4019342335&amp;transparent=0&amp;color=FF8B25" data-was-processed="true" data-ready="true" loading="lazy"></iframe>
                                        <div className='testimony-text'>
                                            <h4>
                                                “I thought it was such an innovative idea because it was just so easy.”
                                            </h4>
                                        </div>
                                    </IonCol>
                                </IonRow>
                                {/*
                                <IonRow>
                                    <IonCol>
                                        { // @ts-ignore }
                                        <iframe data-src="https://player.vimeo.com/video/658933239?h=2cbd6d910e&amp;transparent=0&amp;color=FF8B25" width="390" height="390" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="loaded" src="https://player.vimeo.com/video/658933239?h=2cbd6d910e&amp;transparent=0&amp;color=FF8B25" data-was-processed="true" data-ready="true" loading="lazy"></iframe>
                                        <div className='testimony-text'>
                                            <h4>
                                                “It's really, really exciting and interesting, and it keeps me going on a daily basis.”
                                            </h4>
                                        </div>
                                    </IonCol>
                                    <IonCol>
                                        { // @ts-ignore }
                                        <iframe data-src="https://player.vimeo.com/video/688362409?h=a72fb07d9d&amp;transparent=0&amp;color=FF8B25" width="390" height="390" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" className="loaded" src="https://player.vimeo.com/video/688362409?h=a72fb07d9d&amp;transparent=0&amp;color=FF8B25" data-was-processed="true" data-ready="true" loading="lazy"></iframe>
                                        <div className='testimony-text'>
                                            <h4>
                                                “Great support. What’s your rate, from 1 to 10? I’d rate 11.”
                                            </h4>
                                        </div>
                                    </IonCol>
                                </IonRow>
                                */}
                            </IonGrid>
                        </div>
                    }

                    <div>
                        <SellwichVsShopify />
                    </div>
                    <div id="fb-root"></div>
                    <div id="fb-customer-chat" className="fb-customerchat"></div>

                </div>
                <IonModal isOpen={isOpen} onWillDismiss={() => setIsOpen(false)}>
                    <IonContent>
                        <div className="intrinsic-container intrinsic-container-16x9">
                            <iframe id="player"
                                src={`https://www.youtube.com/embed/${Constants.YOUTUBE_PROMO_VIDEO_ID}?autoplay=1&origin=https://www.sellwich.com`}
                                allowFullScreen></iframe>
                        </div>
                    </IonContent>
                    <IonFooter>
                        <IonToolbar className='ion-padding'>
                            <IonButton slot='end' fill='outline' color='primary' onClick={() => setIsOpen(false)}>Dismiss</IonButton>
                        </IonToolbar>
                    </IonFooter>
                </IonModal>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Home;

import {
    IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonInfiniteScroll,
    IonInfiniteScrollContent, } from '@ionic/react';
import { useEffect, useState } from 'react';

import { Shop } from '../models/Shop';
import { ShopProduct } from './../models/ShopProduct';
import { ProductCard } from '../components/ProductCard';
import { timerOutline, cardOutline, chatbubbleOutline  } from 'ionicons/icons'
import { ShopFooter } from '../components/ShopFooter';
import ShopMetaContent1 from '../components/ShopMetaContent1';

import './Default.css';
import { ShopData } from '../data/ShopData';

interface DefaultShopTemplateProps {
    shop: Shop;
    shopProducts: Array<ShopProduct>;
    addToCart: Function;
    getMoreShopProducts: (evt: any) => void;
}

const Default: React.FC<DefaultShopTemplateProps> = ({ shop, shopProducts, addToCart, getMoreShopProducts }) => {

    const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
    //const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    // @ts-ignore
    const numberOfModelPerRow = shop.templateParameters['numberOfModelPerRow'];

    const getHeaderImageStyle = () => {
        if (desktopMediaQuery.matches) {
            return getHeaderImageStyleDesktop();
        } else {
            return getHeaderImageStyleMobile();
        }
    }

    const getHeaderTextStyle = () => {
        if (desktopMediaQuery.matches) {
            return getHeaderTextStyleDesktop();
        } else {
            return getHeaderTextStyleMobile();
        }
    }

    const getHeaderImageStyleDesktop = () => {
        return {
            // @ts-ignore
            'backgroundImage': shop.templateParameters['topHeaderImageDesktop']
        }
    }

    const getHeaderTextStyleDesktop = () => {
        return {
            // @ts-ignore
            'color': shop.templateParameters['displayNameColorDesktop'],
            // @ts-ignore
            'fontFamily': shop.templateParameters['displayNameFontFamily'],
            // @ts-ignore
            'fontSize': shop.templateParameters['fontSizeDesktop'],
        }
    }

    const getHeaderImageStyleMobile = () => {
        return {
            // @ts-ignore
            'backgroundImage': shop.templateParameters['topHeaderImageMobile'],
        }
    }

    const getHeaderTextStyleMobile = () => {
        return {
            // @ts-ignore
            'color': shop.templateParameters['displayNameColorMobile'],
            // @ts-ignore
            'fontFamily': shop.templateParameters['displayNameFontFamily'],
            // @ts-ignore
            'fontSize': shop.templateParameters['fontSizeMobile'],
        }
    }

    const getFooterDisplayNameStyles = () => {
        return {
            // @ts-ignore
            'color': shop.templateParameters['fontColorFooter'],
            // @ts-ignore
            'fontFamily': shop.templateParameters['fontFamilyFooter'],
            // @ts-ignore
            'fontSize': shop.templateParameters['fontSizeFooter'],
        }
    }

    const getRow = (prods: Array<ShopProduct>, rowIdx: any) => {
        return (
            <IonRow key={rowIdx} itemScope itemType='https://schema.org/ItemList'>
                {prods.map((prod, idx) => {
                    return (
                        <IonCol itemProp='itemListElement' itemScope itemType='https://schema.org/Product' key={idx}>
                            <ProductCard itemNumber={prod.defaultProductVariant.itemNumber} productName={prod.defaultProductVariant.productName} shopName={shop.name} productImage={prod.defaultProductVariant.productImages[0]} spuNumber={prod.spuNumber} salePrice={prod.salePrice} addToCart={addToCart} />
                        </IonCol>
                    )
                })}
            </IonRow>)
    }

    const getProductRows = (products: Array<ShopProduct>) => {
        const rows: any = [];
        // @ts-ignore
        for (let i = 0; i < products?.length; i++) {
            if (i % numberOfModelPerRow == 0) {
                if (products) {
                    let row = getRow(products.slice(i, i + numberOfModelPerRow), i.toString());
                    rows.push(row);
                }
            }
        }
        return rows;
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className='top-header-image' style={getHeaderImageStyle()}>
                    <h1 className='top-header-text' style={getHeaderTextStyle()}>{shop.displayName}</h1>
                </div>
                {shopProducts &&
                    <IonGrid>
                        {getProductRows(shopProducts)}
                        <ShopMetaContent1 />
                    </IonGrid>
                }
                <IonInfiniteScroll onIonInfinite={getMoreShopProducts}>
                    <IonInfiniteScrollContent></IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <ShopFooter shopDisplayName={shop.displayName} displayNameStyles={getFooterDisplayNameStyles()} />
            </IonContent>
        </IonPage>
    );
};

export default Default;

import { API, graphqlOperation } from 'aws-amplify';
import { listSellwichInventoryCategories } from './../graphql/queries';

import { InventoryCategory, InventoryCategoryRepresentation } from '../models/InventoryCategory';
import { EventData } from './EventData';
import { EventType } from '../models/Event';

let cats: Array<InventoryCategory>;

const getSubCategories = (categ: string) => {
    const theSubs: Array<InventoryCategoryRepresentation> = []
    const mappedSubs = new Map();
    cats?.forEach(cat => {
        if (cat.parentCategory.toUpperCase() === categ.toUpperCase() && !mappedSubs.has(cat.name.toUpperCase())) {
            const subCategories = JSON.parse(cat.subCategories.toString());
            const subs = subCategories.map((sub: any) => {
                return { name: sub.toUpperCase(), subs: [] };
            });
            theSubs.push({ name: cat.name.toUpperCase(), subs: subs });
            mappedSubs.set(cat.name.toUpperCase(), 1);
        }
    });
    return theSubs;
}

const getSubsRecursively = (subs: Array<InventoryCategoryRepresentation>) => {
    if (subs === null || subs.length === 0) {
        return [];
    } else {
        const mappedSubs = new Map();
        for (let j = 0; j < subs.length; j++) {
            if (!mappedSubs.has(subs[j].name.toUpperCase())) {
                subs[j].subs = getSubCategories(subs[j].name);
                getSubsRecursively(subs[j].subs);
                mappedSubs.set(subs[j].name.toUpperCase(), 1);
            }
        }
    }
}

const recurseCategories = (catModel: Array<InventoryCategoryRepresentation>) => {
    for (let i=0; i<catModel.length; i++) {
        getSubsRecursively(catModel[i].subs.sort((a, b) => a.name.localeCompare(b.name)));
    }
    return catModel;
}

export const InventoryCategoryData = {

    listCategories: async (limit=1000) => {
        try {
            const categories = await API.graphql(graphqlOperation(listSellwichInventoryCategories, { limit: limit } ));
            // @ts-ignore
            return categories['data']['listSellwichInventoryCategories']['items'] as Array<InventoryCategory>;
        } catch (err) {
            EventData.logEvent(`Error listing categories. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'InventoryCategoryData', 1);
            return null;
        }
    },

    getTransformedCategories: async () => {
        // @ts-ignore
        cats = await InventoryCategoryData.listCategories();
        if (cats === null) {
            return null;
        }
        let catModel = new Array<InventoryCategoryRepresentation>();
        let mappedSubs = new Map();
        // add top level parent categories
        cats?.forEach(cat => {
            if (cat.parentCategory === 'NONE' && !mappedSubs.has(cat.name.toUpperCase()) && cat.name.toUpperCase() !== 'CATEGORY') {
                const subCategories = JSON.parse(cat.subCategories.toString());
                const subs = new Array<InventoryCategoryRepresentation>();
                let addedSubMap = new Map();
                subCategories.forEach((sub: any) => {
                    if (!addedSubMap.has(sub.toUpperCase())) {
                        subs.push({ name: sub.toUpperCase(), subs: [] });
                        addedSubMap.set(sub.toUpperCase(), 1);
                    }
                });
                catModel.push({ name: cat.name.toUpperCase(), subs: subs });
                mappedSubs.set(cat.name.toUpperCase(), 1);
            }
        });
        catModel.sort((a, b) => a.name.localeCompare(b.name));
        // @ts-ignore
        return recurseCategories(catModel);
    },
}
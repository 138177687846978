import React from 'react';
import { IonRow, IonGrid, IonAccordionGroup, IonAccordion, IonItem, IonLabel, IonImg } from '@ionic/react';
import { SellwichVsShopify } from './SellwichVsShopify';

import './FAQ.css';

const FAQComponent: React.FC = () => {
    return (
        <div className='faq-section'>
            <IonGrid className='faq-section'>
                <IonRow class="ion-justify-content-center ion-text-center">
                    <h1>Frequently Asked Questions</h1>
                </IonRow>
            </IonGrid>
            <IonAccordionGroup className='faq-section'>
                <IonAccordion value='0'>
                    <IonItem slot='header'>
                        <IonLabel>How much money can I make with Sellwich?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Success depends on how much effort you put into it. Some people are making thousands of dollars every month and some people give up in their first few months.
                    </div>
                </IonAccordion>
                <IonAccordion value='1'>
                    <IonItem slot='header'>
                        <IonLabel>What does it take to succeed with Sellwich?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        To succeed with Sellwich, you need to harness the art of digital marketing. The formula comes down to 2 ingredients: great product selection and marketing.
                    </div>
                </IonAccordion>
                <IonAccordion value='2'>
                    <IonItem slot='header'>
                        <IonLabel>Is Sellwich right for me?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        If you want to make money and have a few hours every week to dedicate to it, then Sellwich is the most straightforward way to earn money online. If you don't have some time to put into it, then it is not for you.
                    </div>
                </IonAccordion>
                <IonAccordion value='8'>
                    <IonItem slot='header'>
                        <IonLabel>How Sellwich referral program work?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <p>When you sign up, we assign you a unique <a href='/shop/dashboard?referrals=referral'>referral code</a> that you can use to invite people to sellwich.</p>
                        <p>And you earn <strong>$30</strong> for each individual that subscribes; 10$ a month on their 3 first months of subscription.</p>
                    </div>
                </IonAccordion>
                <IonAccordion value='3'>
                    <IonItem slot='header'>
                        <IonLabel> How are taxes handled?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Sellwich takes care of the taxes for products checked out on your custom domain shop; in case the checkout happens on a social platform like Facebook, you are responsible for taking care of the taxes.
                    </div>
                </IonAccordion>
                <IonAccordion value='9'>
                    <IonItem slot='header'>
                        <IonLabel>How is Sellwich different from Shopify?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        <SellwichVsShopify />
                    </div>
                </IonAccordion>
                <IonAccordion value='4'>
                    <IonItem slot='header'>
                        <IonLabel>Can I have a custom domain?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Yes, you can have your own custom domain instead of a Sellwich subdomain.
                    </div>
                </IonAccordion>
                <IonAccordion value='5'>
                    <IonItem slot='header'>
                        <IonLabel>How to install Sellwich App?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        On your phone go to <a href='https://www.sellwich.com'>Sellwich.com</a> and click on the install prompt at the bottom of the page.
                        <IonImg id='install-app-img' src='https://s3.amazonaws.com/sellwich.com/img/install-sellwich.jpeg' />
                    </div>
                </IonAccordion>
                <IonAccordion value='6'>
                    <IonItem slot='header'>
                        <IonLabel>Where do you ship?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        Currently, we only ship to US addresses.
                    </div>
                </IonAccordion>
                <IonAccordion value='7'>
                    <IonItem slot='header'>
                        <IonLabel>Do I have to be in USA to be a Sellwich entrepreneur?</IonLabel>
                    </IonItem>
                    <div className='ion-padding' slot='content'>
                        No. You don't have to be in the USA to be a Sellwich entrepreneur. However, we only ship products in USA; to buy products shoppers need a US address.
                    </div>
                </IonAccordion>
            </IonAccordionGroup>
        </div>
    );
};

export default FAQComponent;

import React from 'react';
import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonImg } from '@ionic/react';
import { Strings } from '../utils/Strings';

import './ProductCard.css';

interface ProductCardProps {
    productName: string;
    shopName: string;
    productImage: string;
    itemNumber: string;
    spuNumber: string;
    salePrice: number;
    addToCart: Function;
}

export const ProductCard: React.FC<ProductCardProps> = ({ productName, shopName, productImage, itemNumber, spuNumber, salePrice, addToCart }) => {

    const addToProductToCart = (evt: any) => {
        addToCart(evt.target.id);
    }

    return (
        <IonCard className='default-product-card'>
            <a href={'/' + shopName + '/' + itemNumber + '/' + spuNumber}>
                <IonImg className='shop-product-img' itemProp='image' src={productImage} />
            </a>
            <IonCardContent>
                <h1>${salePrice}</h1>
                <h4 className='product-title shop-product-name'>
                    <a id='product-title-link' href={'/' + shopName + '/' + itemNumber + '/' + spuNumber}>
                        {productName}
                    </a>
                </h4>
                <IonItem lines='none'>
                    <IonButton id={spuNumber} data-itemNumber={itemNumber} onClick={addToProductToCart} size='default' slot='end' color='warning'>Add to cart</IonButton>
                </IonItem>
            </IonCardContent>
        </IonCard>
    );
}
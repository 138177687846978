import { IonButton, IonContent, IonHeader, IonPage, IonToolbar, useIonRouter } from '@ionic/react';
import ShopName from '../components/ShopName';
import ChooseBuiltStore from '../components/ChooseBuiltStore';
import { useEffect, useState } from 'react';
import { Forms } from '../utils/Forms';

import './CreateShop.css'
import { LocalStorageData } from '../data/LocalStorageData';
import { Shop } from '../models/Shop';
import { ShopData } from '../data/ShopData';
import { ShopProductData } from '../data/ShopProductData';
import { Constants } from '../utils/Constants';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const CreateShop: React.FC = () => {

    const router = useIonRouter();
    const [goToTemplates, setGoToTemplate] = useState(false);
    const [shopDisplayName, setShopDisplayName] = useState('');
    const [exists, setExists] = useState(false);
    const [title, setTitle] = useState('Create your Online Shop');
    const [shopIdToClone, setShopIdToClone] = useState<string>();

    useEffect(() => {
        LocalStorageData.getShopIdToClone()
            .then(cloneId => {
                if (cloneId) {
                    setShopIdToClone(cloneId);
                }
            });
        EventData.logEvent(`CreateShop page visited.`, EventType.CREATE_SHOP_PAGE_VISIT, 'CreateShopPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
        }, []);
    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();

    const updateName = (name: string) => {
        setShopDisplayName(name);
        validateForm(name);
    }

    const updateExists = (ext: boolean) => {
        setExists(ext);
    }

    const validateForm = (name: string) => {
        let isValid = false;
        if (name === '') {
            Forms.showErrorMessage(true, 'nameErrorMessage');
            isValid = false;
        } else {
            Forms.showErrorMessage(false, 'nameErrorMessage');
            isValid = true;
        }
        return isValid;
    }

    const next = () => {
        if (validateForm(shopDisplayName) && !exists) {
            EventData.logEvent(`User named shop: ${shopDisplayName} and clicked next.`, EventType.SHOP_NAMED, 'CreateShopPage');
            if (shopIdToClone) {
                goToDashboard(shopIdToClone);
            } else {
                setGoToTemplate(true);
            }
        }
    }

    const createShop = (shop: Shop, shopToCloneId: string) => {
        ShopData.createShop(shop)
            .then((resp) => {
                if (resp === null) {
                    EventData.logEvent('Failed to create shop.', EventType.ERROR, 'CreateShopPage', 1);
                    window.location.reload();
                } else {
                    LocalStorageData.getLoggedInUserId()
                        .then(userId => {
                            LocalStorageData.getValue(Constants.LOCAL_STORAGE_KEYS.AD_ID)
                                .then(adId => {
                                    if (adId) {
                                        EventData.logEvent(`New shop created from adId: ${adId} by userId: ${userId}.`, EventType.NEW_SHOP_CREATED_FROM_AD, 'ShopDashboardPage');
                                    }
                                });
                        });
                    if (shopToCloneId === '') {
                        EventData.logEvent(`User created blank shop.`, EventType.CREATED_BLANK_SHOP, 'CreateShopPage');
                        router.push(`/product/search`);
                    } else {
                        EventData.logEvent(`User picked a built shop while creating shop. Shop to clone ID: ${shopToCloneId}`, EventType.PICKED_BUILT_SHOP_CREATING_SHOP, 'CreateShopPage');
                        ShopProductData.cloneShopProducts(shopToCloneId, shop.name)
                            .then(resp => {
                                router.push(`/shop/edit/${shop.name}`);
                            });

                    }
                }
            });
    }

    const goToDashboard = (shopToCloneId: string) => {
        EventData.logEvent(`Go to dashboard while creating shop. ShopDisplayName: ${shopDisplayName}, ShopToCloneId: ${shopToCloneId}.`, EventType.GO_TO_DASHBOARD_CREATING_SHOP, 'CreateShopPage');
        LocalStorageData.getLoggedInUserId()
            .then((userId) => {
                if (userId === null) {
                    EventData.logEvent(`Redirecting user to login to create shop. ShopDisplayName: ${shopDisplayName}, ShopToCloneId: ${shopToCloneId}.`, EventType.CREATE_SHOP_REDIRECT, 'CreateShopPage');
                    const redirect = `/login?d=/shop/dashboard?new=1&nm=${shopDisplayName}&cl=${shopToCloneId}`;
                    router.push(redirect);
                } else {
                    if (shopToCloneId === '') {
                        const shop = new Shop(shopDisplayName, userId, Constants.TEMPLATES.DEFAULT.NAME, Constants.TEMPLATES.DEFAULT.PARAMS);
                        createShop(shop, shopToCloneId);
                    } else {
                        ShopData.getShopByName(shopToCloneId)
                            .then((shopResp) => {
                                if (shopResp) {
                                    const newShop = new Shop(shopDisplayName, userId, shopResp?.templateName, shopResp?.templateParameters);
                                    createShop(newShop, shopToCloneId);
                                } else {
                                    EventData.logEvent('Clone does not exist. This should not happen unless there is an invalid built-shop id on ChooseBuiltShop page.', EventType.ERROR, 'CreateShopPage', 1);
                                }
                            })
                    }
                }
            });
    }

    return (
        <IonPage>
            <IonContent class='ion-justify-content-center ion-text-center' fullscreen>
                <IonHeader>
                    <IonToolbar color='primary'>
                        <h4>{title}</h4>
                    </IonToolbar>
                </IonHeader>
                <div className="create-shop-container ion-justify-content-center ion-text-center">
                    { !goToTemplates &&
                        <div className='section'>
                            <ShopName updateName={updateName} updateExists={updateExists} />
                        </div>
                    }
                    { goToTemplates &&
                        <div className='section'>
                            <ChooseBuiltStore goToDashboard={goToDashboard} />
                        </div>
                    }
                    { !goToTemplates &&
                        <IonButton onClick={next} size='default' color='warning'>Next</IonButton>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CreateShop;

import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonPopover, IonRow, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { shareSocial, informationCircleOutline } from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { EventData } from "../data/EventData";
import { LocalStorageData } from "../data/LocalStorageData";
import { UserData } from "../data/UserData";
import { EventType } from "../models/Event";
import { User } from "../models/User";

import './Referrals.css';
import { Constants } from "../utils/Constants";

interface ReferralsProps {
    setTitle: Function;
}

const Referrals: React.FC<ReferralsProps> = ({ setTitle }) => {

    setTitle('Your referrals income');

    const [user, setUser] = useState<User>();
    const [canShare, setCanShare] = useState(false);
    const [pendingRefIncome, setPendingRefIncome] = useState(0);
    const [realizedRefIncome, setRealizedRefIncome] = useState(0);

    useEffect(() => {
        LocalStorageData.getLoggedInUserId()
            .then(userId => {
                if (userId) {
                    UserData.getUserById(userId)
                        .then(usr => {
                            if (usr) {
                                setUser(usr);
                                UserData.getReferredUsers(usr.affiliateCode)
                                    .then(referredUsers => {
                                        if (referredUsers) {
                                            let pending = 0;
                                            let realized = 0;
                                            const subscriptionCommission = Constants.MONTHLY_SUBSCRIPTION_PRICE * Constants.MONTHLY_SUBSCRIPTION_COMMISSION_RATE;
                                            const academyCommission = Constants.ACADEMY_PRICE * Constants.ACADEMY_COMMISSION_RATE;
                                            for (let idx = 0; idx < referredUsers.length; idx++) {
                                                if (referredUsers[idx].lastestPaymentTime) {
                                                    // TODO should see how many times the user has paid and calculate accurate realized amount
                                                    realized += subscriptionCommission;
                                                } else {
                                                    pending += subscriptionCommission * Constants.MONTHLY_SUBSCRIPTION_COMMISSION_MONTHS;
                                                }
                                                if (referredUsers[idx].hasSignedUpForAcademy == 1) {
                                                    realized += academyCommission;
                                                } else {
                                                    pending += academyCommission;
                                                }
                                            }
                                            setPendingRefIncome(pending);
                                            setRealizedRefIncome(realized);
                                        }
                                    });
                            }
                        });
                }
            });
        Share.canShare().then(can => {
            if (can.value === true) {
                setCanShare(can.value);
            }
        }).catch(err => {
            setCanShare(false);
            EventData.logEvent(`Error checking canShare. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ReferralsComponent');
        });
        EventData.logEvent(`Referral page visited.`, EventType.REFERRAL_PAGE_VISIT, 'ReferralsComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    const share = (evt: any) => {
        Share.share({
            title: `Sellwich: a Way to Financial Freedom`,
            text: `Try Sellwich.com and start making passive income today.`,
            url: `https://sellwich.com/ref/${user?.affiliateCode}`,
            dialogTitle: `Sellwich.com: a tried and true way to make passive income online.`,
        }).then(resp => {
            // console.log('Shared successfully: ', resp);
        }).catch(err => {
            EventData.logEvent(`Error sharing refferal link. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ReferralsComponent', 1);
        });
    }

    return (
        <div className="ion-padding">
            { user && <>
                <div className="affiliate-div">Your affiliate code: <strong>{user.affiliateCode}</strong></div>
                <div className="affiliate-div">Your affiliate link: <strong>https://www.sellwich.com/ref/{user.affiliateCode}</strong>
                    { canShare && <IonButton onClick={share} fill='outline' size="small"><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>}</div>
            </>}
            <IonGrid className="referral-grid">
                <IonRow>
                    <IonCol className='ion-justify-content-center ion-text-center'>
                        Pending referral income <IonIcon id='pending-info' icon={informationCircleOutline}></IonIcon>
                        <IonPopover trigger="pending-info" triggerAction="hover">
                            <IonContent>
                                Pending referral income is the sum of commissions from all users that you have referred that haven't paid for either the monthly subscription or the Sellwich Academy Course.
                            </IonContent>
                        </IonPopover>
                    </IonCol>
                    <IonCol className='ion-justify-content-center ion-text-center'>
                        Realized referral income <IonIcon id='realized-info' icon={informationCircleOutline}></IonIcon>
                        <IonPopover trigger="realized-info" triggerAction="hover">
                            <IonContent>
                                Realized referral income is the commission amount ready to be withdrawn.
                            </IonContent>
                        </IonPopover>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='ion-justify-content-center ion-text-center'><IonText className="referral-number">${pendingRefIncome}</IonText></IonCol>
                    <IonCol className='ion-justify-content-center ion-text-center'><IonText color='primary' className="referral-number">${realizedRefIncome}</IonText></IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default Referrals;

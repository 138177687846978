import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';

import { timerOutline, cardOutline, chatbubbleOutline } from 'ionicons/icons'


import './ShopMetaContent1.css';

const ShopMetaContent1: React.FC = () => {

    return (
        <IonGrid>
            <IonRow className='ion-text-center meta1-row'>
                <IonCol className='met1-col'>
                    <IonIcon icon={timerOutline} size='large' color='primary'></IonIcon>
                    <h3>Fast 2-5 days delivery</h3>
                    <h5>Orders are shipped within 1-2 days</h5>
                </IonCol>
                <IonCol className='met1-col'>
                    <IonIcon icon={cardOutline} size='large' color='primary'></IonIcon>
                    <h3>100% secure payment</h3>
                    <h5>We offer several 100% secure payment methods</h5>
                </IonCol>
                <IonCol className='met1-col'>
                    <IonIcon icon={chatbubbleOutline} size='large' color='primary'></IonIcon>
                    <h3>24/7 Customer Service</h3>
                    <h5>Talk to us anytime</h5>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default ShopMetaContent1;

import {
    IonContent, IonList, IonItem, IonSelect, IonSelectOption, IonLabel, IonNote, IonInput, IonText,
    IonModal, IonHeader, IonToolbar, IonFooter, IonButton, IonThumbnail
} from '@ionic/react';
import { useState } from 'react';
import { EventData } from '../data/EventData';
import { LocalStorageData } from '../data/LocalStorageData';
import { EventType } from '../models/Event';
import { InventoryProduct } from '../models/InventoryProduct';
import { Forms } from '../utils/Forms';

interface ProductVariantsProps {
    productVariants: Array<InventoryProduct>;
    onProductAddedToCart: Function;
    toggleIsOpen: Function;
    isOpen: boolean;
    setCart: Function;
    salePrice: number;
    shippingCost: number;
    sellwichPrice: number;
}
const valueSeparator = '@';

export const ProductVariants: React.FC<ProductVariantsProps> = ({ productVariants, onProductAddedToCart, isOpen, toggleIsOpen, setCart, salePrice, shippingCost, sellwichPrice }) => {

    const [product, setProduct] = useState<InventoryProduct>(productVariants[0]);
    const [theme1Variants, setTheme1Variants] = useState<Array<InventoryProduct>>(productVariants);
    const [theme2Variants, setTheme2Variants] = useState<Array<InventoryProduct>>(productVariants);
    const [theme1Value, setTheme1Value] = useState();
    const [theme2Value, setTheme2Value] = useState();
    const [quantity, setQuantity] = useState(1);
    const [niOpen, setNiOpen] = useState(isOpen);

    const updateVariantsBySelectedValue = (theme: number, value = '') => {
        if (theme === 1) {
            setTheme1Variants(productVariants?.filter(v => v.variationValue2 === value));
        } else if (theme === 2) {
            const tempProds = productVariants?.filter(v => v.variationValue1 === value);
            setTheme2Variants(tempProds);
        }
    }

    const variantChanged = (evt: any) => {
        const values = evt.target.value.split(valueSeparator);
        const value = values[0];
        const itemNumber = values[1];
        const theme = parseInt(values[2]);
        console.log(`Value: ${value}, itemNumber: ${itemNumber}, theme: ${theme}`);
        var selectedVariant = productVariants?.find(x => x.itemNumber === itemNumber);
        console.log(`selectedVariant is: ${selectedVariant}`);
        if (selectedVariant) {
            if (theme === 1) {
                setTheme1Value(value);
                updateVariantsBySelectedValue(2, value);
                Forms.showErrorMessage(false, 'theme1ErrorMessage');
            } else if (theme === 2) {
                setTheme2Value(value);
                updateVariantsBySelectedValue(1, value);
                Forms.showErrorMessage(false, 'theme2ErrorMessage');
            }
            setProduct(selectedVariant);
        }
    }

    const getVariantSelect = (theme: number, variants: any) => {
        const mapped = new Map();
        if (theme === 1) {
            return <IonSelect onIonChange={variantChanged} id='theme1'>
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue1)) {
                        mapped.set(variant.variationValue1, 1);
                        return (
                            <IonSelectOption onClick={variantChanged} value={variant.variationValue1 + valueSeparator + variant.itemNumber + valueSeparator + '1'} data-itemNumber={variant.itemNumber} data-theme='1' key={index}>{variant.variationValue1}</IonSelectOption>
                        );
                    } else {
                        return null;
                    }
                })}
            </IonSelect>;
        }
        else if (theme === 2) {
            return <IonSelect onIonChange={variantChanged} id='theme2'>
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue2)) {
                        mapped.set(variant.variationValue2, 1);
                        return (
                            <IonSelectOption value={variant.variationValue2 + valueSeparator + variant.itemNumber + valueSeparator + '2'} data-itemNumbe={variant.itemNumber} data-theme='2' key={index}>{variant.variationValue2}</IonSelectOption>
                        );
                    }
                })}
            </IonSelect>;
        }
    }

    const addToCart = (evt: any) => {
        let isValid = true;
        let errorMessage = '';
        console.log(`Theme1 is: ${theme1Value}`);
        console.log(`Theme2 is: ${theme2Value}`);
        if (product?.variationTheme1 && product.variationValue1 != theme1Value) {
            errorMessage += `Select ${product.variationTheme1}`;
            isValid = false;
            Forms.showErrorMessage(true, 'theme1ErrorMessage');
        }
        if (product?.variationTheme2 && product.variationValue2 != theme2Value) {
            if (errorMessage) {
                errorMessage += ` and ${product.variationTheme2}`;
            } else {
                errorMessage = `Select ${product.variationTheme2}`;
            }
            Forms.showErrorMessage(true, 'theme2ErrorMessage');
            isValid = false;
        }
        if (isValid) {
            if (product) {
                LocalStorageData.addProductToCart(product, quantity, salePrice, shippingCost, sellwichPrice)
                    .then(resp => {
                        // @ts-ignore
                        setCart(resp);
                        onProductAddedToCart(product);
                        cancelClicked();
                    }).catch(err => {
                        EventData.logEvent(`Failed to add product to the shopping cart. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ProductVariants', 1);
                    });
            } else {
                throw (`The product is not and it shoul be.`)
            }
        }
    }

    const onQuantityChanged = (evt: any) => {
        const value = parseInt(evt.target.value);
        if (value) {
            setQuantity(value);
        }
    }

    const cancelClicked = () => {
        setNiOpen(false);
        setTimeout(() => {
            toggleIsOpen();
        }, 500);
    }

    return (
        <IonModal isOpen={niOpen} onWillDismiss={cancelClicked}>
            <IonContent className='ion-padding'>
                <IonHeader>
                    <IonToolbar className='ion-text-center'>
                        <h5>Add to cart</h5>
                    </IonToolbar>
                </IonHeader>
                <div className='product-variants'>
                    <IonList>
                        <IonItem lines='none' className='model-item ion-text-center'>
                            <IonThumbnail id='product-thumbnail'>
                                <img src={product.productImages[0]}></img>
                            </IonThumbnail>
                            <IonText>
                                {product.productName}
                            </IonText>
                            {/** <IonIcon icon={checkmarkDoneOutline} size='large' color='success' slot='end'></IonIcon> */}
                        </IonItem>
                        {product.variationTheme1 &&
                            <>
                                <IonItem>
                                    <IonLabel>{product.variationTheme1}:</IonLabel>
                                    {getVariantSelect(1, theme1Variants)}
                                </IonItem>
                                <IonItem id='theme1ErrorMessage' className='error-message' lines='none'>
                                    <IonNote>{'Select ' + product.variationTheme1}</IonNote>
                                </IonItem>
                            </>
                        }
                        {product.variationTheme2 &&
                            <>
                                <IonItem>
                                    <IonLabel>{product.variationTheme2}:</IonLabel>
                                    {getVariantSelect(2, theme2Variants)}
                                </IonItem>
                                <IonItem id='theme2ErrorMessage' className='error-message' lines='none'>
                                    <IonNote>{'Select ' + product.variationTheme2}</IonNote>
                                </IonItem>
                            </>
                        }
                        <IonItem>
                            <IonLabel>Quantity:</IonLabel>
                            <IonInput name='quantity' onIonChange={onQuantityChanged} type='number' value={quantity}></IonInput>
                        </IonItem>
                    </IonList>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar className='ion-padding' id='model-footer-toolbar'>
                    <IonButton fill='outline' color='medium' onClick={cancelClicked}>Cancel</IonButton>
                    <IonButton onClick={addToCart} slot='end' fill='solid' color='primary'>Add to cart</IonButton>
                </IonToolbar>
            </IonFooter>
        </IonModal>
    );
};

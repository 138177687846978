export class Event {
    sessionId!: string;
    userId!: string;
    timestamp!: number;
    message!: string;
    type!: string;
    source!: string;
    importance!: number; // 1 being uttermost importance or critical and 3 being 'no worries'
}

export enum EventType {
    ERROR = 'ERROR',
    INFO = 'INFO',
    WARNING = 'WARNING',
    PROMO_VIDEO_VIEW = 'PROMO_VIDEO_VIEW',
    LEARN_PAGE_VISIT = 'LEARN_PAGE_VISIT',
    PAYMENT_PAGE_VISIT = 'PAYMENT_PAGE_VISIT',
    BENEFIT_PAGE_VISIT = 'BENEFITS_PAGE_VISIT',
    HOME_PAGE_VISIT = 'HOME_PAGE_VISIT',
    PICKED_SHOP_ON_HOME_PAGE = 'PICKED_SHOP_ON_HOME_PAGE',
    PICKED_BUILT_SHOP_CREATING_SHOP = 'PICKED_BUILT_SHOP_CREATING_SHOP',
    CREATED_BLANK_SHOP = 'CREATED_BLANK_SHOP',
    CREATE_SHOP_PAGE_VISIT = 'CREATE_SHOP_PAGE_VISIT',
    SEARCH_PRODUCTS_PAGE_VISIT = 'SEARCH_PRODUCTS_PAGE_VISIT',
    DASHBOARD_PAGE_VISIT = 'DASHBOARD_PAGE_VISIT',
    WALLET_PAGE_VISIT = 'WALLET_PAGE_VISIT',
    MY_SELLWICH_PAGE_VISIT = 'MY_SELLWICH_PAGE_VISIT',
    DIRECT_SALES_PAGE_VISIT = 'DIRECT_SALES_PAGE_VISIT',
    REFERRAL_PAGE_VISIT = 'REFERRAL_PAGE_VISIT',
    LEARNING_CENTER_PAGE_VISIT = 'LEARNING_CENTER_PAGE_VISIT',
    SETTINGS_PAGE_VISIT = 'SETTINGS_PAGE_VISIT',
    HELP_PAGE_VISIT = 'HELP_PAGE_VISIT',
    REFERRED_USER_CREATED = 'REFERRED_USER_CREATED',
    NON_REFERRED_USER_CREATED = 'NON_REFERRED_USER_CREATED',
    AD_PAGE_VISIT = 'AD_PAGE_VISIT',
    TRIAL_STARTED = 'TRIAL_STARTED',
    AD_CONVERSION = 'AD_CONVERSION',
    NEW_SHOP_CREATED_FROM_AD = 'NEW_SHOP_CREATED_FROM_AD',
    FAQ_PAGE_VISIT = 'FAQ_PAGE_VISIT',
    LOGIN_PAGE_VISIT = 'LOGIN_PAGE_VISIT',
    LOGOUT_PAGE_VISIT = 'LOGOUT_PAGE_VISIT',
    SHOP_NAMED = 'SHOP_NAMED',
    CREATE_SHOP_REDIRECT = 'CREATE_SHOP_REDIRECT',
    GO_TO_DASHBOARD_CREATING_SHOP = 'GO_TO_DASHBOARD_CREATING_SHOP',
    MYSHOP_PAGE_VISIT = 'MySHOP_PAGE_VISIT',
    SHOP_PRODUCT_DETAIL_PAGE_VISIT = 'SHOP_PRODUCT_DETAIL_PAGE_VISIT',
}
import { API, graphqlOperation } from 'aws-amplify';
import { listSellwichShopProducts, querySellwichShopProductsByShopNameIndex, getSellwichShopProduct } from './../graphql/queries';
import { createSellwichShopProduct, deleteSellwichShopProduct, updateSellwichShopProduct } from './../graphql/mutations';
import { ShopProduct } from '../models/ShopProduct';
import { EventData } from './EventData';
import { EventType } from '../models/Event';
//import { Amplify, Logger, AWSCloudWatchProvider } from 'aws-amplify';

// TODO
/*const logger = new Logger('Sellwich-Client-Logs');
Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());*/

export const ShopProductData = {

    getShopProduct: async (itemNumber: string, shopName: string) => {
        try {
            const shopProd = await API.graphql(graphqlOperation(getSellwichShopProduct, { productId: itemNumber, shopName: shopName }));
            // @ts-ignore
            return shopProd['data']['getSellwichShopProduct'] as ShopProduct;
        } catch (err) {
            EventData.logEvent(`Error get shop product, itemNumber ${itemNumber}, shopName: ${shopName}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopProductData', 1);
            return null;
        }
    },

    queryShopProductsByShopName: async (shopName: string, nexToken='') => {
        try {
            // TODO remove this, just testing whether logger works as expected
            //logger.log(`Getting shop products for ${shopName}`);
            const shopProductsData = await API.graphql(graphqlOperation(querySellwichShopProductsByShopNameIndex,
                { shopName: shopName, after: nexToken }));
            // @ts-ignore
            const items = shopProductsData['data']['querySellwichShopProductsByShopNameIndex']['items'];
            // @ts-ignore
            const nextToken = shopProductsData['data']['querySellwichShopProductsByShopNameIndex']['nextToken'];
            const shopProds = new Array<ShopProduct>();
            items.forEach((item: any) => {
                shopProds.push(new ShopProduct({ shopProductData: item }));
            });
            return {shopProds: shopProds, nextToken: nextToken};
        } catch (err) {
            EventData.logEvent(`Error getting shop. Product name: ${shopName}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ShopProductData', 1);
            return null;
        }
    },

    createShopProduct: async (shopProd: ShopProduct) => {

        try {

            const $input = {
                shopName: shopProd.shopName,
                productId: shopProd.spuNumber,
                spuNumber: shopProd.spuNumber,
                salePrice: shopProd.salePrice,
                sellwichPrice: shopProd.sellwichPrice,
                defaultProductVariant: JSON.stringify(shopProd.defaultProductVariant),
                customDescription: shopProd.customDescription,
                customImages: JSON.stringify(shopProd.customImages),
                customVideos: JSON.stringify(shopProd.customVideos)
            }
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichShopProduct,
                    { input: $input }));
            // @ts-ignore
            return created['data']['createSellwichShopProduct'];
        } catch (err) {
            //logger.error(`Error creating shopProduct with spuNumber: ${shopProd.spuNumber}, err: ${err}`);
            return null;
        }
    },

    removeShopProduct: async (shopName: string, prodId: string) => {

        try {
            const deleted = await API.graphql(graphqlOperation(deleteSellwichShopProduct, { input: { productId: prodId, shopName: shopName } }));
            // @ts-ignore
            return deleted['data']['deleteSellwichShopProduct'];
        } catch (err: any) {
            //logger.error(`Failed to delete productId: ${prodId} from ${shopName} shop. Err: ${err}`);
            return null;
        }
    },

    updateProduct: async (productId: string, shopName: string, attributesToUpdate: any) => {
        try {
            const updateObject = { ...{ productId: productId, shopName: shopName } , ...attributesToUpdate };
            const updated = await API.graphql(graphqlOperation(updateSellwichShopProduct, { input: updateObject }));
            // @ts-ignore
            const updateResult = updated['data']['updateSellwichShopProduct'];
            return updateResult;
        } catch (err: any) {
            //logger.error(`Failed to update productId: ${productId} in ${shopName} shop. Err: ${err}`);
            return null;
        }

    },

    listShopProducts: async () => {

        try {

            const shopProductsData = await API.graphql(graphqlOperation(listSellwichShopProducts, { input: { limit: 40 } }));
            // @ts-ignore
            const items = shopProductsData['data']['listSellwichShopProducts']['items'];
            const shopProds = new Array<ShopProduct>();
            items.forEach((item: any) => {

                shopProds.push(new ShopProduct({ shopProductData: item }));
            });
            return shopProds;
        } catch (err) {

            //logger.error(`Error listing shop products: ${err}`);
            return null;
        }
    },

    cloneShopProducts: async (cloneFromName: string, cloneToName: string) => {
        return ShopProductData.queryShopProductsByShopName(cloneFromName)
            .then((results) => {
                if (results?.shopProds === null) {

                    //logger.error(`aint got no ShopProduct to clone. cloneFromShop: ${cloneFromName}, cloneToShop: ${cloneToName}`);
                    return null;
                } else {
                    return results?.shopProds.forEach(prod => {
                        prod.shopName = cloneToName;
                        ShopProductData.createShopProduct(prod);
                    });
                }
            })

    }
}
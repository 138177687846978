import { IonButton, IonInput, IonList, IonItem, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";
import { EventData } from "../data/EventData";
import { LocalStorageData } from '../data/LocalStorageData';
import { EventType } from "../models/Event";
import { Shop } from '../models/Shop';
import { Constants } from "../utils/Constants";
import { ShopData } from './../data/ShopData';

interface SettingsProps {
    setTitle: Function;
}

const Settings: React.FC<SettingsProps> = ({ setTitle }) => {

    setTitle('Settings');

    const [profitPercentage, setProfitPercentage] = useState(12);
    const [shops, setShops] = useState<Array<Shop>>();

    useEffect(() => {
        LocalStorageData.getLoggedInUserId()
            .then((userId) => {
                if (userId) {
                    // @ts-ignore
                    ShopData.queryShopsByUserId(userId)
                        .then((shops) => {
                            if (shops) {
                                setShops(shops);
                            }
                        });
                }
            });
        EventData.logEvent(`Settings page visit.`, EventType.SETTINGS_PAGE_VISIT, 'SettingsComponent');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    return (
        <div>
            <IonList>
                <IonItem>
                    <IonLabel>Default profit %:</IonLabel>
                    <IonInput type="number" value={profitPercentage}></IonInput>
                </IonItem>
                { shops &&
                    <IonItem>
                        <IonLabel position='stacked'>Your website:</IonLabel> <b>{'www.sellwich.com/' + shops[0].name}</b>
                    </IonItem>
                }
                {shops &&
                    <IonItem>
                        <IonLabel position='stacked'>Facebook data Feed URL:</IonLabel> { Constants.FACEBOOK_PRODUCT_DATA_FEED_URL + shops[0].name}
                    </IonItem>
                }
            </IonList>
        </div>
    );
};

export default Settings;

import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent,
    IonHeader, IonPage, IonText, IonTitle, IonToolbar, IonList, IonItem, IonThumbnail, IonSelect, IonSelectOption, IonLabel, IonIcon, useIonRouter, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useParams } from 'react-router';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { InventoryProduct } from '../models/InventoryProduct';
import { InventoryProductData } from '../data/InventoryProductData';
import { ShopData } from '../data/ShopData';
import { Strings } from '../utils/Strings';
import { shareSocial } from 'ionicons/icons';
import { Share } from '@capacitor/share';

import './ProductDetail.css';
import { useEffect, useState } from 'react';
import { LocalStorageData } from '../data/LocalStorageData';
import { Shop } from '../models/Shop';
import { ShopProductData } from '../data/ShopProductData';
import { AddProductToShopModal } from '../components/AddProductToShopModal';
import { Scripts } from '../utils/Scripts';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

let shops: Array<Shop>;
let globalProduct: InventoryProduct;

export const ProductDetail: React.FC = () => {

    const desktopMediaQuery = window.matchMedia('(min-width: 768px)');
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');

    const router = useIonRouter();
    const { itemNumber } = useParams<{ itemNumber: string; }>();
    const { spuNumber } = useParams<{ spuNumber: string; }>();
    // @ts-ignore
    const [product, setProduct] = useState<InventoryProduct>();
    const [productVariants, setProductVariants] = useState<Array<InventoryProduct>>();
    const [theme1Variants, setTheme1Variants] = useState<Array<InventoryProduct>>();
    const [theme2Variants, setTheme2Variants] = useState<Array<InventoryProduct>>();
    const [images, setImages] = useState<Array<string>>();
    const [displayedImage, setDisplayedImage] = useState('https://arthurmillerfoundation.org/wp-content/uploads/2018/06/default-placeholder.png');
    const [canShare, setCanShare] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {

        Share.canShare().then(can => {
            if (can.value === true) {
                setCanShare(can.value);
            }
        }).catch (err => {
            setCanShare(false);
            EventData.logEvent(`Error checking canShare. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ProductDetailPage');
        });

        InventoryProductData.getInventoryProduct(itemNumber, spuNumber)
            .then(prod => {
                if (prod) {
                    setProduct(prod);
                    setDisplayedImage(prod.productImages[0]);
                    setImages(prod.productImages);
                    globalProduct = prod;
                    markProductsInShops();
                } else {
                    // TODO show an error message
                    EventData.logEvent(`Error happened getting the product.`, EventType.ERROR, 'ProductDetailPage', 1);
                }
            });

        InventoryProductData.queryInventoryProductBySpuNumber(spuNumber)
            .then((prods) => {
                if (prods) {
                    setTheme1Variants(prods);
                    setTheme2Variants(prods);
                    setProductVariants(prods);
                }
            });

        LocalStorageData.getLoggedInUserId()
            .then(userId => {
                if (userId === null) {
                    // redirect to login, prolly the user aint logged in
                    router.push(`/login?d=/product/${product?.itemNumber}/${product?.spuNumber}`)
                } else {
                    ShopData.queryShopsByUserId(userId)
                        .then(shopz => {
                            if (shopz === null) {
                                EventData.logEvent(`Failed to get shops.`, EventType.ERROR, 'ProductDetailPage', 1);
                            } else {
                                shops = shopz;
                                markProductsInShops();
                            }
                        })
                }
            });

    }, []);

    const updateVariantsBySelectedValue = (theme: number, value='') => {
        if (theme === 1) {
            setTheme1Variants(productVariants?.filter(v => v.variationValue2 === value));
        } else if (theme === 2) {
            const tempProds = productVariants?.filter(v => v.variationValue1 === value);
            setTheme2Variants(tempProds);
        }
    }

    const productSelected = (evt: any) => {
        setIsOpen(true);
    }

    const imageClicked = (evt: any) => {
        if (evt.target.src) {
            setDisplayedImage(evt.target.src);
        }
    }

    const variantChanged = (evt: any) => {
        const values = evt.target.value.split('-');
        const value = values[0];
        const itemNumber = values[1];
        const theme = parseInt(values[2]);
        var selectedVariant = productVariants?.find(x => x.itemNumber === itemNumber);
        if (selectedVariant) {
            if (theme === 1) {
                updateVariantsBySelectedValue(2, value);
            } else if (theme === 2) {
                updateVariantsBySelectedValue(1, value);
            }
            setProduct(selectedVariant);
            setImages(selectedVariant.productImages);
            setDisplayedImage(selectedVariant.productImages[0]);
        }
    }

    const getVariantSelect = (theme: number, variants: any) => {
        const mapped = new Map();
        if (theme === 1) {
            return <IonSelect onIonChange={variantChanged} >
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue1)) {
                        mapped.set(variant.variationValue1, 1);
                        return (
                            <IonSelectOption onClick={variantChanged} value={variant.variationValue1 + '-' + variant.itemNumber + '-' + '1'} data-itemNumber={variant.itemNumber} data-theme='1' key={index}>{variant.variationValue1}</IonSelectOption>
                        );
                    } else {
                        return null;
                    }
                })}
            </IonSelect>;
        }
        else if (theme === 2) {
            return <IonSelect onIonChange={variantChanged}>
                {variants?.map((variant: any, index: any) => {
                    if (!mapped.has(variant.variationValue2)) {
                        mapped.set(variant.variationValue2, 1);
                        return (
                            <IonSelectOption value={variant.variationValue2 + '-' + variant.itemNumber + '-' + '2'} data-itemNumbe={variant.itemNumber} data-theme='2' key={index}>{variant.variationValue2}</IonSelectOption>
                        );
                    }
                })}
            </IonSelect>;
        }
    }

    const share = () => {

        Share.share({
            title: `Check out this product`,
            text: `${product?.productName}`,
            url: `https://sellwich.com/product/${product?.itemNumber}/${product?.spuNumber}`,
            dialogTitle: `Check out this product`,
        }).then(resp => {
                // console.log('Shared successfully: ', resp);
            }).catch(err => {
                EventData.logEvent(`Error sharing product. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'ProductDetailPage', 1);
            });
    }

    const markProductsInShops = () => {
        if (globalProduct && shops) {
            let productsMap = new Map();
            productsMap.set(globalProduct.spuNumber, { inShops: [] });
            shops.forEach(shop => {
                ShopProductData.queryShopProductsByShopName(shop.name)
                    .then(resulsts => {
                        resulsts?.shopProds?.forEach(shopProd => {
                            let prodInShop = productsMap.get(shopProd.spuNumber);
                            if (prodInShop) {
                                prodInShop.inShops.push(shop.displayName);
                                productsMap.set(shopProd.spuNumber, prodInShop);
                            }
                        });
                        productsMap.forEach((val, key) => {
                            if (val.inShops.length > 0) {
                                globalProduct.inShops = val.inShops;
                                const p = new InventoryProduct();
                                Object.assign(p, globalProduct);
                                setProduct(p);
                            }
                        });
                    })
            });
        }
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" aria-disabled>Product Details</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div id='product-detail-container' className='ion-justify-content-center'>

                    {product && mobileMediaQuery.matches &&
                        <IonCard>
                            <IonCardHeader>
                                <h6>{Strings.shortenStringDisplay(product.productName, 60)}</h6>
                            </IonCardHeader>
                            <img className='product-img-mobile' src={displayedImage} alt={product.productName}></img>
                            <div className='product-thumbnails'>
                                { images && images.map((img, idx) => {
                                    return (
                                        <IonThumbnail key={idx}>
                                            <img src={img} onClick={imageClicked} alt={product.productName + ' image'}></img>
                                        </IonThumbnail>
                                    );
                                })}
                            </div>
                            <IonCardContent>
                                <h5>Sellwich price:</h5>
                                <h1>${product.dropshippingPrice}</h1>
                            </IonCardContent>
                            {!product.inShops &&
                                <IonButton size='default' expand='block' color='primary' onClick={productSelected} id={product.spuNumber} >Add to your shop</IonButton>
                            }
                            { canShare &&
                                <IonButton onClick={share} expand='block' fill='outline' color='primary' id='share-btn'><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>
                            }
                            <IonItem className='product-sub-item'>Shipping cost: ${product.shippingEstimateCost}</IonItem>
                            <IonItem className='product-sub-item'>Inventory quantity: {product.inventoryQuantity}</IonItem>
                            <IonItem className='product-sub-item'>Product #: {product.spuNumber}</IonItem>
                            <IonItem lines='none'>
                                {product.inShops &&
                                    <IonButton slot='end' fill='outline' color='primary' onClick={productSelected} id={product.spuNumber}>Add to another shop</IonButton>
                                }
                                {product.inShops &&
                                    <div>
                                        <IonText className='product-in-shops-text'>Product in shop(s):</IonText>
                                        <IonList>
                                            {
                                                product.inShops.map((inShop: any) => {
                                                    return (
                                                        <IonItem lines='none' className='in-shop-name'>- {inShop}</IonItem>
                                                    )
                                                })
                                            }
                                        </IonList>
                                    </div>
                                }
                            </IonItem>
                            { (product.variationTheme1 || product.variationTheme2) &&
                                <div className='product-variants'>
                                    <IonList>
                                        { product.variationTheme1 &&
                                            <IonItem>
                                                <IonLabel>{product.variationTheme1}:</IonLabel>
                                                {getVariantSelect(1, theme1Variants)}
                                            </IonItem>
                                        }
                                        {product.variationTheme2 &&
                                            <IonItem>
                                                <IonLabel>{product.variationTheme2}:</IonLabel>
                                                {getVariantSelect(2, theme2Variants)}
                                            </IonItem>
                                        }
                                    </IonList>
                                </div>
                            }
                            <h5 className='ion-padding'>Details:</h5>
                            <div dangerouslySetInnerHTML={{ __html: product.descriptionHTML}} className='ion-padding'></div>
                        </IonCard>
                    }

                    {product && desktopMediaQuery.matches &&
                        <IonGrid className='desktop-grid'>
                            <IonRow>
                                <IonCol id='title-img-col'>
                                    <h1>{Strings.shortenStringDisplay(product.productName, 90)}</h1>
                                    <div id='img-div ion-justify-content-center ion-text-center'>
                                        <img className='product-img-desktop' src={displayedImage} alt={product.productName}></img>
                                    </div>
                                    <div className='product-thumbnails'>
                                        {images && images.map((img, idx) => {
                                            return (
                                                <IonThumbnail key={idx}>
                                                    <img src={img} onClick={imageClicked} alt={product.productName + ' image'}></img>
                                                </IonThumbnail>
                                            );
                                        })}
                                    </div>

                                </IonCol>
                                <IonCol id='more-details-col'>
                                    <h5>Sellwich price:</h5>
                                    <h1>${product.dropshippingPrice}</h1>
                                    <IonItem className='product-sub-item'>Shipping cost: ${product.shippingEstimateCost}</IonItem>
                                    <IonItem className='product-sub-item'>Inventory quantity: {product.inventoryQuantity}</IonItem>
                                    <IonItem className='product-sub-item'>Product #: {product.spuNumber}</IonItem>
                                    <div id='add-to-shop-div'>
                                        {!product.inShops &&
                                            <IonButton expand='block' size='large' color='primary' onClick={productSelected} id={product.spuNumber} >Add to your shop</IonButton>
                                        }
                                    </div>
                                    {canShare &&
                                        <IonButton onClick={share} expand='block' fill='outline' color='primary' id='share-btn'><IonIcon icon={shareSocial}></IonIcon>Share</IonButton>
                                    }
                                    <IonItem lines='none'>
                                        {product.inShops &&
                                            <IonButton slot='end' fill='outline' color='primary' onClick={productSelected} id={product.spuNumber}>Add to another shop</IonButton>
                                        }
                                        {product.inShops &&
                                            <div>
                                                <IonText className='product-in-shops-text'>Product in shop(s):</IonText>
                                                <IonList>
                                                    {
                                                        product.inShops.map((inShop: any) => {
                                                            return (
                                                                <IonItem lines='none' className='in-shop-name'>- {inShop}</IonItem>
                                                            )
                                                        })
                                                    }
                                                </IonList>
                                            </div>
                                        }
                                    </IonItem>
                                    {(product.variationTheme1 || product.variationTheme2) &&
                                        <div className='product-variants'>
                                            <IonList>
                                                {product.variationTheme1 &&
                                                    <IonItem>
                                                        <IonLabel>{product.variationTheme1}:</IonLabel>
                                                        {getVariantSelect(1, theme1Variants)}
                                                    </IonItem>
                                                }
                                                {product.variationTheme2 &&
                                                    <IonItem>
                                                        <IonLabel>{product.variationTheme2}:</IonLabel>
                                                        {getVariantSelect(2, theme2Variants)}
                                                    </IonItem>
                                                }
                                            </IonList>
                                        </div>
                                    }

                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <h5 className='ion-padding'>Details:</h5>
                                    <div dangerouslySetInnerHTML={{ __html: product.descriptionHTML }} className='ion-padding'></div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    }
                    { !product &&
                        <div className='container'>
                            <h1>No product found. Go to <a href='/shop/dashboard'>dashboard</a></h1>
                        </div>
                    }
                    {isOpen &&
                        <>
                            {/** @ts-ignore */}
                            <AddProductToShopModal isOpen={isOpen} toggleIsOpen={() => setIsOpen(!isOpen)} selectedProduct={product} shops={shops} markProductsInShops={markProductsInShops} />
                        </>
                    }
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

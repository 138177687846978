import { API, graphqlOperation } from 'aws-amplify';
import { getSellwichUser, listSellwichUsers, querySellwichUsersByReferralCodeIndex } from './../graphql/queries';
import { createSellwichUser, updateSellwichUser } from './../graphql/mutations';
import { User } from '../models/User';
import { Strings } from '../utils/Strings';
import { EventData } from './EventData';
import { EventType } from '../models/Event';
import { LocalStorageData } from './LocalStorageData';
import { Constants } from '../utils/Constants';

export const UserData = {

    getUserById: async (userId: string) => {
        try {
            const user = await API.graphql(graphqlOperation(getSellwichUser, { id: userId }));
            // @ts-ignore
            return user['data']['getSellwichUser'] as User;
        } catch (err) {
            EventData.logEvent(`Error getting userId: ${userId}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
            return null;
        }
    },

    updateUser: async (userId: string, attrs: object) => {
        try {
            const updated = await API.graphql(graphqlOperation(updateSellwichUser,
                { input: { id: userId, ...attrs } }));
            return updated;
        } catch (err: any) {
            EventData.logEvent(`Error updating user with attrs: ${JSON.stringify(attrs)}, userId: ${userId}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
        }
    },

    updateUserFbAccessToken: async (userId: string, accessToken: string, expiresIn: number, expiryTime: number) => {
        try {
            const updated = await API.graphql(graphqlOperation(updateSellwichUser,
                { input: { id: userId, facebookAccessToken: accessToken, facebookTokenExpiresIn: expiresIn, facebookTokenExpirationTime: expiryTime}}));
            return updated;
        } catch(err: any) {
            EventData.logEvent(`Error updating FB access token: ${accessToken}, userId: ${userId}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
        }
    },

    updateOrCreateUser: async (userId: string, accessToken: string, expiresIn: number, expiryTime: number, email: string, firstname: string, lastname: string, middlename: string, profilePicture: object) => {
        return UserData.getUserById(userId)
            .then(userData => {
                if (userData) {
                    return UserData.updateUserFbAccessToken(userId, accessToken, expiresIn, expiryTime)
                        .then(resp => {
                            return resp;
                        }).catch(err => {
                            EventData.logEvent(`Failed to update token for userId: ${userId}, token: ${accessToken}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
                            return null;
                        })
                } else {
                    const user = new User();
                    user.id = userId;
                    user.facebookAccessToken = accessToken;
                    user.facebookTokenExpiresIn = expiresIn;
                    user.facebookTokenExpirationTime = expiryTime;
                    user.email = email;
                    user.firstname = firstname;
                    user.lastname = lastname;
                    user.middlename = middlename;
                    // @ts-ignore
                    user.profilePicture = JSON.stringify(profilePicture);
                    // TODO should check if the code does not exist already
                    user.affiliateCode = Strings.genCode(8);
                    LocalStorageData.getReferralCode()
                        .then(refCode => {
                            if (refCode) {
                                user.referralCode = refCode.toUpperCase();
                            } else {
                                user.referralCode = Constants.DEFAULT_REFERRAL_CODE;
                            }
                            return UserData.createUser(user)
                                .then(resp => {
                                    if (refCode) {
                                        EventData.logEvent(`A user with referralCode ${refCode} created.`, EventType.REFERRED_USER_CREATED, 'UserData');
                                    } else {
                                        EventData.logEvent(`A new user with no referralCode created.`, EventType.NON_REFERRED_USER_CREATED, 'UserData');
                                    }
                                    return resp;
                                }).catch(err => {
                                    EventData.logEvent(`Failed to create token for userId: ${userId}, token: ${accessToken}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
                                    return null;
                                });
                        });
                }
            })
    },

    queryUserById: async (id: string) => {
        try {
            const user = await API.graphql(graphqlOperation(getSellwichUser, { input: { id: id } }));
            // @ts-ignore
            return user['data'];
        } catch (err) {
            EventData.logEvent(`Error get userId: ${id}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
            return null;
        }
    },

    createUser: async (user: User) => {
        try {
            const created = await API.graphql(
                graphqlOperation(
                    createSellwichUser,
                    { input: user }));
            // @ts-ignore
            return created['data']['createSellwichUser'];
        } catch (err) {
            EventData.logEvent(`Error creating user. UserId: ${user.id}, userEmail: ${user.email}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
            return null;
        }
    },

    listUsers: async () => {
        try {
            const users = await API.graphql(graphqlOperation(listSellwichUsers, { input: { limit: 20 } }));
            // @ts-ignore
            return users['data']['listSellwichUsers']['items'];
        } catch (err) {
            EventData.logEvent(`Error listing users. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
            return null;
        }
    },

    getReferredUsers: async (referralCode: string) => {
        try {
            const referredUsers = await API.graphql(graphqlOperation(querySellwichUsersByReferralCodeIndex, { referralCode: referralCode.toUpperCase() }));
            // @ts-ignore
            return referredUsers['data']['querySellwichUsersByReferralCodeIndex']['items'] as Array<User>;
        } catch (err) {
            EventData.logEvent(`Error querying users by referralCode: ${referralCode}. Error: ${JSON.stringify(err)}`, EventType.ERROR, 'UserData', 1);
            return null;
        }
    },
}
import { createContext, useContext, useState } from "react";
import { Facebook } from "./Facebook";

const AuthContext = createContext({});
const useAuthContext = () => useContext(AuthContext);

// @ts-ignore
const AuthContextProvider = ({ children }) => {

    const [userId, setUserId] = useState();
    const [loggedIn, setLoggedIn] = useState(false);

    const value = {
        userId: userId,
        loggedIn: loggedIn,
        logout: Facebook.logout
    }

    Facebook.isLoggedIn()
        .then((userId: any) => {
            setUserId(userId);
            if (userId) {
                setLoggedIn(true);
            } else {
                setLoggedIn(false);
            }
        });

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export { useAuthContext, AuthContextProvider };
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonList, IonPage, IonRow, IonTitle,
    IonToolbar, useIonRouter } from '@ionic/react';
import { useParams } from 'react-router';
import { checkmarkOutline } from 'ionicons/icons';
import { Scripts } from '../utils/Scripts';

import './Benefit.css';
import { useEffect } from 'react';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

// TODO should take the shop benefits in its own component so that I could have benefits for the masterClass etc.
const Benefit: React.FC = () => {

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();

    const router = useIonRouter();
    let { productType } = useParams<{ productType: string; }>();
    // console.log(`Product type is ${productType}`);

    useEffect(() => {
        EventData.logEvent(`Benefit page visit`, EventType.BENEFIT_PAGE_VISIT, 'BenefitPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, [])

    const next = () => {
        router.push('/pay/shop/sub');
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <h4>Sellwich</h4>
                    </IonToolbar>
                </IonHeader>
                <div className="container ion-justify-content-center ion-text-center">
                    <h1>Start making money with a FREE trial.</h1>
                    <IonList className='benefits-list' >
                        <IonItem><IonIcon slot='start' color='success' icon={checkmarkOutline}></IonIcon> Fully functional online store</IonItem>
                        <IonItem><IonIcon slot='start' color='success' icon={checkmarkOutline}></IonIcon> Thousands of viral products to sell on social</IonItem>
                        <IonItem><IonIcon slot='start' color='success' icon={checkmarkOutline}></IonIcon> No inventory to worry about</IonItem>
                        <IonItem><IonIcon slot='start' color='success' icon={checkmarkOutline}></IonIcon> Order fulfillment managed by Sellwich</IonItem>
                        <IonItem><IonIcon slot='start' color='success' icon={checkmarkOutline}></IonIcon> 1-3 days US shipping & returns</IonItem>
                    </IonList>
                    <IonGrid id='value-grid'>
                        <IonRow class='ion-justify-left'>
                            <IonCol><h3>Online store</h3></IonCol>
                            <IonCol><h5>US $0.00</h5></IonCol>
                        </IonRow>
                        <IonRow class='ion-justify-left'>
                            <IonCol class='ion-justify-left'><h3>Hosting</h3></IonCol>
                            <IonCol><h5>US $0.00</h5></IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <h3>Sellwich Pro</h3>
                                <p className='benefit-subscript'>Free for 14 days, $24/month after trial. Cancel anytime.</p>
                            </IonCol>
                            <IonCol><h5>US $0.00</h5></IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonButton onClick={next} color="warning" size='large'>Start trial</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Benefit;

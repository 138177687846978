import { IonCheckbox, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import StripePayment from './../components/StripePayment';
import { Footer } from '../components/Footer';

import './Payment.css';
import { Constants } from '../utils/Constants';
import { Order } from '../models/Order';
import { Scripts } from '../utils/Scripts';
import { useEffect } from 'react';
import { EventData } from '../data/EventData';
import { EventType } from '../models/Event';

const Payment: React.FC = () => {

    let { paymentType } = useParams<{ paymentType: string; }>();
    let { product } = useParams<{ product: string; }>();
    console.log(`Product is:  ${product} Product type is ${paymentType}`);

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    useEffect(() => {
        EventData.logEvent(`Payment page visit.`, EventType.PAYMENT_PAGE_VISIT, 'PaymentPage');
        // @ts-ignore
        if (window.fbq) {
            // @ts-ignore
            window.fbq('track', 'ViewContent');
        }
    }, []);

    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="ion-justify-content-center ion-text-center full-height">
                    <IonGrid className='full-height'>
                        <IonRow className='full-height'>
                            <IonCol id='payment-info-col' className='ion-justify-content-center ion-text-center'>
                                <div id='payment-container'>
                                    <span id='today-char'>Today's charge: <strong>$0</strong></span>
                                    <br />
                                    <span id='after-trial'>Free for 14 days, $24/month after trial. Cancel anytime.</span>
                                    <StripePayment amount={Constants.MONTHLY_SUBSCRIPTION_PRICE} paymentFrequency='monthly' email='' name='' order={new Order()} />
                                    <div>
                                        <img src='https://s3.amazonaws.com/sellwich.com/img/ssl-secure-payment.svg'></img>
                                    </div>
                                    <div id='policies-disclaimer' className='ion-padding ion-text-center'><IonCheckbox checked={true}></IonCheckbox> By paying for the subscription, you agree to Sellwich <a href='https://www.sellwich.com/terms-of-use' target='_blank'> Terms of Use </a> and <a href='https://www.sellwich.com/privacy-policy' target='_blank'>Privacy Policy</a>. I understand that this is a recurring subscription. I will be charged automatically on a recurring basis until I cancel. </div>
                                </div>
                            </IonCol>
                            <IonCol className='pay-welcome show-on-desktop'>
                                <div className='container'>
                                    <h4>Join our community of 12K entrepreneurs making money with</h4>
                                    <h1>Sellwich</h1>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
            <Footer />
        </IonPage>
    );
};

export default Payment;

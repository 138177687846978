import React from 'react';

import './HTMLTemplate.css';

interface HTMLTemplateProps {
    src: string
}

export const HTMLTemplate: React.FC<HTMLTemplateProps> = ({ src }) => {

    return (<>
        <div className="intrinsic-container intrinsic-container-16x9">
            <iframe src={src} allowFullScreen></iframe>
        </div>
    </>
    );
}
import { useLocation } from 'react-router-dom';
import { useIonRouter } from '@ionic/react';
import { useAuthContext } from './AuthContext';
import { useEffect, useState } from 'react';
import { LocalStorageData } from '../data/LocalStorageData';

// @ts-ignore
export const ProtectedRoute = ({ children }) => {

    const router = useIonRouter();
    const location = useLocation();
    const path = location.pathname;
    const search = location.search;

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        LocalStorageData.getLoggedInUserId()
            .then(userId => {
                if (userId) {
                    setIsLoggedIn(true);
                } else {
                    router.push(`/login?d=${path + search}`);
                }
            })
    }, []);

    if (!isLoggedIn) {
        return null
    } else {
        return children;
    }
};
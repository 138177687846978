export class User {

    id!: string;
    firstname!: string;
    lastname!: string;
    middlename!: string;
    email!: string;
    phoneNumber!: string;
    timestamp = new Date().getTime();
    referralCode!: string; // The affiliate code of the entity that did the referral
    affiliateCode!: string; // The code theuser uses to refer the program and earn referral fees
    shopIds!: Array<string>;
    whatsappNumber!: string;
    facebookAccessToken!: string;
    facebookUsername!: string;
    facebookTokenExpiresIn!: number;
    facebookTokenExpirationTime!: number;
    hasSetupShop!: number; // 1 being true and 0 being false
    hasPublishedToFacebook!: number; // 1 being true and 0 being false
    isInTrial!: number; // 1 being true and 0 being false
    trialStartTime!: number; // 1 being true and 0 being false
    hasSignedUpForAcademy!: number; // 1 being true and 0 being false
    academyStartTime!: number;
    hasGraduatedFromAcademy!: number; // 1 being true and 0 being false
    academyGraduationTime!: number;
    hasCancelledTrial!: number; // 1 being true and 0 being false
    cancelTime!: number;
    profilePicture!: object;
    stripeCustomerId!: string;
    stripeSubscriptionId!: string;
    lastestPaymentTime!: number;
    paymentFailedTime!: number;
    setupShopTime!: number;
}
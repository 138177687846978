import { Strings } from "../utils/Strings";

const enum ShopStatus {

    INIT = 'INIT',
    SELECTED_TEMPLATE = 'SELECTED_TEMPLATE',
    SELECTED_PRODUCTS = 'SELECTED_PRODUCTS',
    IN_TRIAL= 'IN_TRIAL',
    PAID = 'PAID',
    PUBLISHED_TO_FACEBOOK = 'PUBLISHED_TO_FACEBOOK',
    PUBLISHED_TO_INSTAGRAM = 'PUBLISHED_TO_INSTAGRAM',
    CANCELLED = 'CANCELLED',
    PAYMENT_FAILED = 'PAYMENT_FAILED'
 }

class Shop {

    constructor (displayName: string, userId: string, templateName: string='', templateParams?: Object) {

        this.displayName = displayName;
        this.name = Strings.normalizeShopName(displayName);
        this.subDomainName = Strings.normalizeShopName(displayName);
        this.status = ShopStatus.INIT;
        this.userId = userId;
        this.timestamp = new Date().getTime();
        if (templateName) {
            this.templateName = templateName;
            this.templateParameters = templateParams;
        }
    }
    name!: string;
    displayName!: string;
    userId!: string;
    timestamp = new Date().getTime();
    domainName!: string;
    subDomainName!: string;
    templateName!: string;
    templateParameters?: Object;
    status!: ShopStatus;
}

export { Shop, ShopStatus};
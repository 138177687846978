import React from 'react';
import { IonButton, IonToolbar, IonHeader } from '@ionic/react';
import { useAuthContext } from './../utils/AuthContext';

import './Header.css';

export  const Header: React.FC = () => {

    // @ts-ignore
    const { loggedIn, userId, logout } = useAuthContext();

    const logoutClicked = () => {
        logout();
        window.location.reload();
    }
    return (<>
        <IonHeader className='ion-no-border'>
            <IonToolbar>
                <a href='/'><img src='/assets/icon/icon.png' id='top-logo'></img></a>
                <IonButton href='/' disabled={false} id='sellwich-button' color='success' fill='clear' className='show-on-desktop'>SELLWICH</IonButton>
                <IonButton href='/learn' fill='clear' color='medium' slot='end' className='show-on-desktop'>Learn</IonButton>
                { !loggedIn &&
                    <IonButton href='/login?shop/dashboard' fill='clear' color='medium' slot='end'>Login</IonButton>
                }
                {!loggedIn &&
                    <IonButton href='/shop/create' color='warning' slot='end'>Get Started</IonButton>
                }
                { loggedIn &&
                    <IonButton onClick={logoutClicked} fill='outline' color='medium' slot='end'>Logout</IonButton>
                }
            </IonToolbar>
        </IonHeader>
    </>
    );
}
import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { HTMLTemplate } from '../components/HTMLTemplate';
import { Footer } from '../components/Footer';
import { Header } from './../components/Header'
import { Constants } from './../utils/Constants';
import { Scripts } from '../utils/Scripts';

export const FacebookInstagramTips: React.FC = () => {

    Scripts.loadGoogleAnalytics();
    Scripts.loadFacebookPixel();
    return (<>
        <IonPage>
            <IonContent>
                <Header />
                <HTMLTemplate src={Constants.GOOGLE_DOCS_URL.FACEBOOK_INSTAGRAM_TIPS} />
                <Footer />
            </IonContent>
        </IonPage>
    </>
    );
}
import { Constants } from './Constants';

const emailRegexFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const Strings = {

    normalizeShopName: (shopName: string) => {

        // TODO find all variations and fix them
        return shopName.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    },

    genCode: (length: number = Constants.AFFILIATE_CODE_LENGTH) => {

        let base = 'A1BC2DE3FG4HI5JK6LM7NP8QR9ST0UVWZ'
        let code = '';
        while (length > 0) {
            let index = Math.floor(Math.random() * Math.floor(base.length));;
            code += base[index];
            length -= 1;
        }

        return code;
    },

    shortenStringDisplay: (str: string, length: number) => {

        if (str.length <= length) {
            return str;
        } else {
            let index = length;
            let cutoff = str[index];
            let strLen = str.length;
            while (index < (strLen-1) && cutoff != ' ') {
                index += 1;
                cutoff = str[index];
                if (cutoff === ' ') {
                    break;
                }
            }
            return str.slice(0, index) + '...';
        }
    },

    validateEmail: (email: string) => {
        if (!email || !email.match(emailRegexFormat)) {
            return false;
        } else {
            return true;
        }
    }
}